import styles from './GeneralInformationForm.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection, change, initialize} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {inchesToFeet, inchesToCm} from '../../../../../util/unitConversion';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {
  handicapValue,
  averageScoreValue,
  genderSelectorValue,
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: () => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  labels: any;
  unitType: WebfitSupportedUnitsEnum;
  questions: any;
  initialValues: any;
  handicapValue: string;
  averageScoreValue: string;
  units: any;
  profileGender: string;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    unitType: unitTypeSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    questions: (state.nFlight.localizedJson!.profile as any).questions,
    initialValues: fullProfileSelector(state),
    handicapValue: handicapValue(state),
    averageScoreValue: averageScoreValue(state),
    units: state.nFlight.localizedJson!.units,
    profileGender: genderSelectorValue(state),
  };
};

let GeneralInformationForm: any = (
  props: IProps & IStateToProps & IDispatchProp
) => {
  const {
    dispatch,
    handleSubmit,
    handicapValue,
    averageScoreValue,
    isComplete,
    labels,
    unitType,
    onNextClick,
    questions,
    nextBtnText,
    units,
    initialValues,
  } = props;

  const initialState = !handicapValue && !!averageScoreValue;
  const [showAverageScore, setShowAverageScore] = React.useState(initialState);

  React.useEffect(() => {
    dispatch(initialize('profile', initialValues));
  }, [initialValues]);

  const {
    averageScore,
    dexterity,
    gender,
    height,
    handicap,
    handicapOrAverageScore,
  } = questions;

  const heightValueLabel =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? inchesToFeet
      : inches => `${inchesToCm(inches)} ${units.centimeters.abbrev}`;

  const onGenderChange = gender => {
    if (gender.toLowerCase() === 'female') {
      dispatch(
        change('profile', 'ironProfile.shaftMaterialPreference', 'graphite')
      );
    }
  };

  const avgScoreOnChange = (event, averageScore: number) => {
    const handicap = Math.floor((averageScore - 74) / 1.1);
    dispatch(change('profile', 'golferProfile.handicap', handicap));
  };

  const handicapOnChange = (event, handicap: number) => {
    const averageScore = Math.floor(74 + 1.1 * handicap);
    dispatch(change('profile', 'golferProfile.averageScore', averageScore));
  };

  const handicapValueLabel = (handicap: number) =>
    handicap < 0 ? `+${Math.abs(handicap)}` : handicap;

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="golferProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.profile} />
          <div className={layout.vr_4} />
          <TextRadio
            label={gender.label}
            name="gender"
            options={gender.options}
            onChange={onGenderChange}
          />
          <TextRadio
            label={dexterity.label}
            name="dexterity"
            options={dexterity.options}
          />
          <Range
            name="heightInInches"
            label={height.label}
            min={height.range[WebfitSupportedUnitsEnum.Imperial].min}
            max={height.range[WebfitSupportedUnitsEnum.Imperial].max}
            step={height.range[WebfitSupportedUnitsEnum.Imperial].step}
            getValueLabel={heightValueLabel}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={height.helpText}
            defaultHelpTextVisibility={height.defaultHelpTextVisibility}
            screenReaderMaximumLabel={height.screenReaderMaximumLabel[unitType]}
            screenReaderMinimumLabel={height.screenReaderMinimumLabel[unitType]}
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'feet and inches'
                : 'centimeters'
            }
          />
          <h3 className={styles.heading}>{handicapOrAverageScore.label}</h3>
          <div className={styles.vr_2} />
          <div className={styles.inputWrapper}>
            <button
              className={styles.input}
              type="button"
              onClick={() => setShowAverageScore(false)}
            >
              <div
                className={`${styles.label} ${styles.first} ${
                  !showAverageScore ? styles.selected : ''
                }`}
              >
                {handicapOrAverageScore.btn1}
              </div>
            </button>
            <button
              className={styles.input}
              type="button"
              onClick={() => setShowAverageScore(true)}
            >
              <div
                className={`${styles.label} ${styles.last} ${
                  showAverageScore ? styles.selected : ''
                }`}
              >
                {handicapOrAverageScore.btn2}
              </div>
            </button>
          </div>
          <div className={styles.vr_6} />
          {!showAverageScore && (
            <Range
              name="handicap"
              label={handicap.label}
              min={handicap.range.min}
              max={handicap.range.max}
              step={handicap.range.step}
              notRequired={false}
              hideLabel={true}
              getValueLabel={handicapValueLabel}
              onChange={handicapOnChange}
              screenReaderMaximumLabel={handicap.screenReaderMaximumLabel}
              screenReaderMinimumLabel={handicap.screenReaderMinimumLabel}
              screenReaderUnitLabel="handicap"
            />
          )}
          {showAverageScore && (
            <Range
              name="averageScore"
              label={averageScore.label}
              min={averageScore.range.min}
              max={averageScore.range.max}
              step={averageScore.range.step}
              notRequired={true}
              hideLabel={true}
              onChange={avgScoreOnChange}
              screenReaderMaximumLabel={averageScore.screenReaderMaximumLabel}
              screenReaderMinimumLabel={averageScore.screenReaderMinimumLabel}
              screenReaderUnitLabel="average score"
            />
          )}
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

GeneralInformationForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
  forceUnregisterOnUnmount: true,
})(GeneralInformationForm);

GeneralInformationForm = connect(mapStateToProps)(GeneralInformationForm);

export default GeneralInformationForm;
