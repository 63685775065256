import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import high from '../../../../../Assets/Illustrations/Trajectory_High@2x-100.jpg';
import low from '../../../../../Assets/Illustrations/Trajectory_Low@2x-100.jpg';
import medium from '../../../../../Assets/Illustrations/Trajectory_Medium@2x-100.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  high,
  low,
  medium,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  questions: any;
  labels: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  questions: (state.nFlight.localizedJson!.driver as any).questions,
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
});

let LoftAndTrajectoryForm: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
  } = props;
  const {currentLoft, currentTrajectory} = questions;

  const loftLabelFunction = loft => `${loft}°`;

  const currentTrajectoryOptions = currentTrajectory.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="driverProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.loft} />
          <div className={layout.vr_4} />
          <Range
            name="currentLoft"
            label={currentLoft.label}
            min={7}
            max={16}
            step={0.5}
            getValueLabel={loftLabelFunction}
            defaultValue={currentLoft.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={currentLoft.helpText}
            screenReaderMaximumLabel={currentLoft.screenReaderMaximumLabel}
            screenReaderMinimumLabel={currentLoft.screenReaderMinimumLabel}
            screenReaderUnitLabel="degrees"
          />
          <PictorialRadioInput
            label={currentTrajectory.label}
            name="currentTrajectory"
            options={currentTrajectoryOptions}
            view="stretched"
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

LoftAndTrajectoryForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(LoftAndTrajectoryForm);

LoftAndTrajectoryForm = connect(mapStateToProps)(LoftAndTrajectoryForm);

export default LoftAndTrajectoryForm;
