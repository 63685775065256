export enum WebfitSupportedLanguagesEnum {
  DE = 'de-DE',
  GB = 'en-GB',
  US = 'en-US',
  ES = 'es-ES',
  FR = 'fr-FR',
  JA = 'ja-JP',
  SE = 'se-SV',
}

export const WebfitSupportedLanguages = {
  'de-DE': WebfitSupportedLanguagesEnum.DE,
  de: WebfitSupportedLanguagesEnum.DE,
  'en-GB': WebfitSupportedLanguagesEnum.GB,
  gb: WebfitSupportedLanguagesEnum.GB,
  'en-US': WebfitSupportedLanguagesEnum.US,
  us: WebfitSupportedLanguagesEnum.US,
  'es-ES': WebfitSupportedLanguagesEnum.ES,
  es: WebfitSupportedLanguagesEnum.ES,
  'fr-FR': WebfitSupportedLanguagesEnum.FR,
  fr: WebfitSupportedLanguagesEnum.FR,
  'ja-JP': WebfitSupportedLanguagesEnum.JA,
  ja: WebfitSupportedLanguagesEnum.JA,
  'sv-SV': WebfitSupportedLanguagesEnum.SE,
  sv: WebfitSupportedLanguagesEnum.SE,
};
