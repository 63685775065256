/*
    // Returned Api Data Sample
    {
      drivers: [
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
      fairways: [
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
      hybrids: [
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
      irons: [
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
      wedges: []
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
      putters: [
        { ...primaryRecommendation },
        { ...atlernativeRecommednation },
      ],
    }
 */

type Recommendation = {[key: string]: any}[] | undefined;
type DataObject =
  | {
      [key: string]: any;
    }
  | undefined;

export default class RecommendationModel {
  public readonly drivers: Recommendation = [{}, {}];
  public readonly fairways: Recommendation = [{}, {}];
  public readonly hybrids: Recommendation = [{}, {}];
  public readonly crossovers: Recommendation = [{}, {}];
  public readonly irons: Recommendation = [{}, {}];
  public readonly wedges: Recommendation = [{}, {}];
  public readonly chipr: Recommendation = [{}, {}];
  public readonly putters: Recommendation = [];
  public setLayout: DataObject = {};

  constructor(recommendations: Partial<RecommendationModel>) {
    this.drivers = recommendations.drivers;
    this.fairways = recommendations.fairways;
    this.hybrids = recommendations.hybrids;
    this.crossovers = recommendations.crossovers;
    this.irons = recommendations.irons;
    this.wedges = recommendations.wedges;
    this.chipr = recommendations.chipr;
    this.putters = recommendations.putters;
    this.setLayout = recommendations.setLayout;
  }
}
