import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import soft from '../../../../../Assets/Illustrations/Bunkers_Soft.jpg';
import mediumSand from '../../../../../Assets/Illustrations/Bunkers_Medium.jpg';
import firm from '../../../../../Assets/Illustrations/Bunkers_Firm.jpg';
import deep from '../../../../../Assets/Illustrations/Divot_Deep@2x.jpg';
import medium from '../../../../../Assets/Illustrations/Divot_Medium@2x.jpg';
import shallow from '../../../../../Assets/Illustrations/Divot_Shallow@2x.jpg';
import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const bunkersImgMap = {
  soft,
  medium: mediumSand,
  firm,
};

const divotImgMap = {
  deep,
  medium,
  shallow,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.wedges as any).questions,
  units: state.nFlight.localizedJson!.units,
});

let Bunkers: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
    units,
  } = props;

  const {
    sandConditions,
    bunkerProficiency,
    chipShotProficiency,
    openFace,
  } = questions;

  const sandConditionsOptions = sandConditions.options.map(option => {
    return {
      ...option,
      imgSrc: bunkersImgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="wedgeProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.bunkers} />
          <div className={layout.vr_4} />
          <TextRadio
            label={openFace.label}
            name="openFace"
            defaultValue={openFace.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={openFace.helpText}
            options={openFace.options}
          />
          <PictorialRadioInput
            label={sandConditions.label}
            name="sandConditions"
            options={sandConditionsOptions}
            view="stretched"
          />
          <TextRadio
            label={bunkerProficiency.label}
            name="bunkerAssistance"
            options={bunkerProficiency.options}
          />
          <TextRadio
            label={chipShotProficiency.label}
            name="useChipR"
            options={chipShotProficiency.options}
          />
          <div className={layout.vr_4} />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

Bunkers = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(Bunkers);

Bunkers = connect(mapStateToProps)(Bunkers);

export default Bunkers;
