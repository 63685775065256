export const noConversion = (value: any) => value;

export const cmToInches = (cm: number): number => Math.floor(cm / 2.54);
export const cmToMeters = (cm: number): number => Math.floor(cm / 100);

export const inchesToCm = (inches: number): number =>
  Math.floor(inches / 0.3937);
export const inchesToFeet = (inches: number): string => {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}"`;
};

export const metersToYards = (meters: number): number =>
  Math.floor(meters * 1.09361);
export const metersToInches = (meters: number): number => meters * 39.3701;
export const yardsToMeters = (yards: number): number =>
  Math.floor(yards * 0.9144);
