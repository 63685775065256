import styles from './PictorialRadio.module.scss';
import sharedInputStyles from './FormInputs.module.scss';
import * as React from 'react';
import {Field} from 'redux-form';
import FieldErrorDisplay from './FieldErrorDisplay';
import FieldHelpDisplay from './FieldHelpDisplay';

interface IOption {
  altText?: string;
  imgSrc: string;
  text?: string;
  value: string;
  description?: string;
}

interface IProps {
  name: string;
  label: string;
  options: IOption[];
  view: 'scrunched' | 'tiled' | 'stretched';
  defaultValue?: string | number;
  defaultValueLabel?: string;
  defaultValueTooltipText?: string;
  hideBottomMargin?: boolean;
  defaultHelpTextVisibility?: boolean;
}

const required = value => (value ? undefined : 'Required');

export const PictorialRadioInput = (props: IProps): JSX.Element => {
  const {
    defaultValue,
    defaultValueLabel,
    defaultValueTooltipText,
    label,
    view,
    name,
    options,
    hideBottomMargin,
    defaultHelpTextVisibility,
  } = props;

  return (
    <>
      <div className="input form-group">
        <fieldset>
          <legend className={sharedInputStyles.label}>{label}</legend>
          <div className={styles.vr_1} />

          {/* This is an instance of the field only to render errors.
            This works best separate from other inputs to ensure it always
            displays if there is an error. */}
          <Field
            className={sharedInputStyles.vr_1}
            component={FieldErrorDisplay}
            label={label}
            name={name}
          />

          {(defaultValue || defaultValueTooltipText) && (
            <Field
              className={sharedInputStyles.vr_2}
              component={FieldHelpDisplay}
              defaultValue={defaultValue as any}
              defaultValueLabel={defaultValueLabel}
              defaultValueTooltipText={defaultValueTooltipText}
              name={name}
              id={`${name}-fieldHelpDisplay`}
              defaultHelpTextVisibility={defaultHelpTextVisibility}
            />
          )}

          <div className={styles.vr_2} />
          <div className={`${styles.inputWrapper} ${view ? styles[view] : ''}`}>
            {options.map(option => {
              const text = option.text;
              let labelClass = view ? styles[view] : '';
              const style =
                view === 'stretched'
                  ? {width: `calc(${Math.floor(100 / options.length)}% - 10px)`}
                  : {};

              return (
                <label key={option.value} className={labelClass} style={style}>
                  <Field
                    name={name}
                    component="input"
                    type="radio"
                    value={option.value}
                    validate={[required]}
                    className={styles.srOnly}
                  />
                  <div className={styles.inputDisplay} title={text}>
                    <img src={option.imgSrc} alt={option.altText} />
                    <p>{text}</p>
                  </div>
                </label>
              );
            })}
          </div>
          {!hideBottomMargin && <div className={sharedInputStyles.vr_6} />}
        </fieldset>
      </div>
    </>
  );
};

export default PictorialRadioInput;
