/*
    // Returned Api Data Sample
    {
      FullName: string
      FirstName: string
      LastName: string
      Email: string
    }
 */

export default class PingNationProfileModel {
  public readonly FullName: string;
  public readonly FirstName: string;
  public readonly LastName: string;
  public readonly Email: string;

  constructor(data) {
    this.FullName = data.FullName || '';
    this.FirstName = data.FirstName || '';
    this.LastName = data.LastName || '';
    this.Email = data.Email || '';
  }
}
