import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import HttpErrorResponseModel from './Models/HttpErrorResponseModel';

export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Options = 'OPTIONS',
  Head = 'HEAD',
  Patch = 'PATCH',
}

export default class HttpUtility {
  public async get(
    headers: RawAxiosRequestHeaders,
    endpoint: string,
    params?: any
  ): Promise<AxiosResponse<any> | HttpErrorResponseModel> {
    const config: AxiosRequestConfig | undefined = params
      ? {params}
      : undefined;

    return this._fetch(
      headers,
      {
        url: endpoint,
        method: RequestMethod.Get,
      },
      config
    );
  }

  public async post(
    headers: RawAxiosRequestHeaders,
    endpoint: string,
    data?: any
  ): Promise<AxiosResponse<any> | HttpErrorResponseModel> {
    const config: AxiosRequestConfig | undefined = data ? {data} : undefined;
    return this._fetch(
      headers,
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      config
    );
  }

  public async patch(
    headers: RawAxiosRequestHeaders,
    endpoint: string,
    data?: any
  ): Promise<AxiosResponse<any> | HttpErrorResponseModel> {
    const config: AxiosRequestConfig | undefined = data ? {data} : undefined;
    return this._fetch(
      headers,
      {
        url: endpoint,
        method: RequestMethod.Patch,
      },
      config
    );
  }

  private async _fetch(
    headers: RawAxiosRequestHeaders,
    restRequest: Partial<Request>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any> | HttpErrorResponseModel> {
    if (!Boolean(restRequest.url)) {
      console.error(
        `Received ${restRequest.url} which is invalid for a endpoint url`
      );
    }

    try {
      const axiosResponse: AxiosResponse = await axios({
        ...config,
        method: restRequest.method,
        url: restRequest.url,
        headers,
      });

      return {
        ...axiosResponse,
      };
    } catch (error) {
      return new HttpErrorResponseModel({
        status: error.response && error.response.status,
        responseBody: error.response,
      });
    }
  }
}
