/* istanbul ignore file */
import {applyMiddleware, createStore, Middleware} from 'redux';
import rootReducer from './rootReducer';
import IStore from './Models/IStore';
import thunk from 'redux-thunk';
import {composeWithDevTools} from '@redux-devtools/extension';

export default (): IStore => {
  const middleware: Middleware[] = [thunk];

  const store: any = createStore(
    rootReducer(),
    //{}, // initialState
    composeWithDevTools(applyMiddleware(...middleware))
  );

  return store;
};
