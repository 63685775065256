import {Analytics} from '@segment/analytics-next';
import {AnalyticsWindow} from './types';

declare let window: AnalyticsWindow;

export default class SegmentAnalytics {
  static customEvent(action: string) {
    if (window.analytics !== undefined) {
      window.analytics.track(action);
    }
  }

  static loggedIn(uniqueId: string, userEmail: string) {
    if (window.analytics !== undefined) {
      window.analytics.identify(uniqueId, {
        email: userEmail,
      });

      window.analytics.track('Logged In');
    }
  }

  static findARetailer(url: String) {
    if (window.analytics !== undefined) {
      window.analytics.track('Find a Retailer');
    }
  }

  static printRecommendations() {
    if (window.analytics !== undefined) {
      window.analytics.track('Print Recommendations');
    }
  }

  static innerSectionVisit(category: string, name: string) {
    if (window.analytics !== undefined) {
      /* Via Segment Docs:
      /   category: The category of the page. Useful for things like ecommerce where many pages often live under a larger category.
      /   name: The name of the page, for example Signup or Home.
      */
      window.analytics.page(category, name);
    }
  }
}
