import {get, values} from 'lodash';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  puttersGeneralInformationSelector,
  puttersGeneralInformationProgress,
  fullProfileSelector,
} from './sharedSelectors';

/* Data Model in Redux Store */
// putterProfile: {
// strokePath: "straight"
// feelPreference: "crouched"
// typicalMiss: "left"
// alignmentAids: "minimal"
// weight: "short"
// posture: "upright"
// gripMethod: "conventional"
// }

const strokePathForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.strokePath`);

const strokePathProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.strokePath`);

const strokePathSelector = createSelector(
  strokePathForm,
  strokePathProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const puttersAPIRequestPayloadSelector = createSelector(
  fullProfileSelector,
  fullProfileSelector => {
    const alignmentMap = {
      minimal: 'Traditional',
      standard: 'BallWidth',
      prominent: 'LongLine',
    };

    const {golferProfile, putterProfile} = fullProfileSelector;

    const {gender, heightInInches, wristToFloorInInches} = golferProfile;

    const {
      strokePath,
      feelPreference,
      typicalMiss,
      alignmentAids,
      posture,
    } = putterProfile;
    let {weight} = putterProfile;

    if (weight === 'both') {
      weight = 'either';
    }

    return {
      alignment: alignmentMap[alignmentAids],
      feel: feelPreference,
      gender,
      heightInInches,
      miss_tendency: typicalMiss,
      posture,
      stroke_type: strokePath,
      weight,
      wristToFloorInInches,
    };
  }
);

const typicalMissForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.typicalMiss`);

const typicalMissProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.typicalMiss`);

const typicalMissSelector = createSelector(
  typicalMissForm,
  typicalMissProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const weightForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.weight`);

const weightProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.weight`);

const weightSelector = createSelector(
  weightForm,
  weightProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const strokeSelector = createSelector(
  strokePathSelector,
  weightSelector,
  typicalMissSelector,
  (stroke, weakness, typicalMissSelector) =>
    !!stroke && !!weakness && !!typicalMissSelector
);

const feelPreferenceForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.feelPreference`);

const feelPreferenceProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.feelPreference`);

const feelPreferenceSelector = createSelector(
  feelPreferenceForm,
  feelPreferenceProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const alignmentAidsForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.alignmentAids`);

const alignmentAidsProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.alignmentAids`);

export const alignmentAidsSelector = createSelector(
  alignmentAidsForm,
  alignmentAidsProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const distanceImprovementForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.distanceImprovement`);

const distanceImprovementProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.distanceImprovement`);

const distanceImprovementSelector = createSelector(
  distanceImprovementForm,
  distanceImprovementProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const feelSelector = createSelector(
  feelPreferenceSelector,
  typicalMissSelector,
  (feel, miss) => !!feel && !!miss
);

const postureForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.posture`);

const postureProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.posture`);

export const postureSelector = createSelector(
  postureForm,
  postureProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const gripMethodForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.gripMethod`);

const gripMethodProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.gripMethod`);

export const gripMethodSelector = createSelector(
  gripMethodForm,
  gripMethodProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const modelPreferenceForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.putterProfile.modelPreference`);

const modelPreferenceProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.putterProfile.modelPreference`);

export const modelPreferenceSelector = createSelector(
  modelPreferenceForm,
  modelPreferenceProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const modelPreferenceValueSelector = createSelector(
  modelPreferenceForm,
  modelPreferenceProfile,
  /* NOTE: formValues must merge onto savedProfile,
     if its reversed, then updated form values will never get sent to API.
  */
  (formValue, profileValue) => formValue || profileValue
);

export const puttersCompleteSelector = createSelector(
  puttersGeneralInformationSelector,
  strokeSelector,
  feelSelector,
  postureSelector,
  modelPreferenceSelector,
  (general, stroke, feel, posture, modelPreference) =>
    general && stroke && feel && posture && modelPreference
);

const onlyPuttersProgressSelector = createSelector(
  strokePathSelector,
  weightSelector,
  feelPreferenceSelector,
  typicalMissSelector,
  alignmentAidsSelector,
  postureSelector,
  modelPreferenceSelector,
  (
    strokePathSelector,
    weightSelector,
    feelPreferenceSelector,
    typicalMissSelector,
    alignmentAidsSelector,
    postureSelector,
    modelPreferenceSelector
  ) =>
    [
      strokePathSelector,
      weightSelector,
      feelPreferenceSelector,
      typicalMissSelector,
      alignmentAidsSelector,
      postureSelector,
      modelPreferenceSelector,
    ].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);

export const puttersProgressSelector = createSelector(
  onlyPuttersProgressSelector,
  puttersGeneralInformationProgress,
  (onlyIronsProgressSelector, generalInformationProgress) => {
    return [onlyIronsProgressSelector, generalInformationProgress].reduce(
      (acc, selector) => {
        acc.questions += selector.questions;
        acc.complete += selector.complete;
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
