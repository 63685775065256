import styles from './DriverRecommendation.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import NflightActions from '../../../../Redux/NflightAction';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import RecommendationLongCards from '../../Shared/RecommendationLongCards';
import {metersToInches} from '../../../../../util/unitConversion';

interface IProps {
  isComplete?: boolean;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IState {}
interface IStateToProps {
  labels: any;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  recommendations: state.nFlight.localizedJson!.recommendations,
});

class DriverRecommendation extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public componentDidMount(): void {
    const {dispatch} = this.props;
    dispatch(NflightActions.isRecommendationPage(true));
  }

  public render(): JSX.Element {
    const {
      isComplete,
      nextBtnText,
      onPreviousClick,
      onNextClick,
      previousBtnText,
      labels,
      recommendations,
    } = this.props;

    const lengthTransformer = (lengthInMeters): string => {
      return `${metersToInches(lengthInMeters).toFixed(2)}"`;
    };

    const hoselTransformer = (hoselPosition: string): string => {
      if (hoselPosition === 'plusplus') {
        return recommendations.cardValues.hoselPosition.bigPlus;
      }
      if (hoselPosition === 'minusminus') {
        return recommendations.cardValues.hoselPosition.bigMinus;
      }
      return recommendations.cardValues.hoselPosition.neutral;
    };

    const weightTransformer = (weightPosition: string): string => {
      if (weightPosition === 'neutral') {
        return recommendations.cardValues.weightPosition.neutral;
      }
      if (weightPosition === 'fade') {
        return recommendations.cardValues.weightPosition.fade;
      }
      if (weightPosition === 'D') {
        return recommendations.cardValues.weightPosition.draw;
      }
      return '';
    };

    return (
      <div className={styles.wrapper}>
        <FormHeader
          isComplete={isComplete}
          header={labels.forms.driverResults}
        />
        <div className={layout.vr_4} />
        <RecommendationLongCards
          clubType="drivers"
          sections={{
            club: {
              data: [
                {labelPath: 'cardLabels.loft', dataPath: 'loft.name.full'},
                {
                  labelPath: 'cardLabels.weightPosition',
                  dataPath: 'weightPosition',
                  transformer: weightTransformer,
                },
                {
                  labelPath: 'cardLabels.hoselPosition',
                  dataPath: 'hoselPosition',
                  transformer: hoselTransformer,
                },
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: lengthTransformer,
                },
                {labelPath: 'cardLabels.headSize', dataPath: 'headSize'},
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {labelPath: 'cardLabels.swingWeight', dataPath: 'swingWeight'},
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {labelPath: 'cardLabels.material', dataPath: 'shaft.material'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <div className={layout.vr_4} />
        <StepNavigation
          nextBtnText={nextBtnText}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          formHandlesNext={false}
        />
      </div>
    );
  }
}

export {DriverRecommendation as Unconnected};
export default connect(mapStateToProps)(DriverRecommendation);
