import styles from './UnderConstructionPage.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import mrPing from '../../../../../Assets/Images/MrPing.png';

import FormHeader from '../FormHeader';
import StepNavigation from '../../Shared/StepNavigation';

import * as React from 'react';

interface IProps {
  nextBtnText: string;
  onNextClick: () => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IState {}

export default class UnderConstructionPage extends React.Component<
  IProps,
  IState
> {
  public render(): JSX.Element {
    const {
      nextBtnText,
      onNextClick,
      onPreviousClick,
      previousBtnText,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <h2 className="hdg hdg_3">Ground Under Repair</h2>
        <p>The putters recommendation section is under construction.</p>
        {/* <FormHeader
          isComplete={false}
          header=
        /> */}
        <div className={layout.vr_4} />
        <img
          src={mrPing}
          alt="Image of Mr. Ping, denoting that this section is under construction."
          style={{width: '40%'}}
        />
        <div className={layout.vr_6} />
        <StepNavigation
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          formHandlesNext={false}
        />
      </div>
    );
  }
}
