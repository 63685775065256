import styles from './FairwayRecommendation.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import NflightActions from '../../../../Redux/NflightAction';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import RecommendationLongCards from '../../Shared/RecommendationLongCards';

interface IProps {
  isComplete?: boolean;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IState {}
interface IStateToProps {
  labels: any;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  recommendations: state.nFlight.localizedJson!.recommendations,
});

class FairwayRecommendation extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public componentDidMount(): void {
    const {dispatch} = this.props;
    dispatch(NflightActions.isRecommendationPage(true));
  }

  public render(): JSX.Element {
    const {
      isComplete,
      nextBtnText,
      onPreviousClick,
      onNextClick,
      previousBtnText,
      labels,
      recommendations,
    } = this.props;

    const hoselTransformer = (hoselPosition: string): string => {
      if (hoselPosition === 'plusplus') {
        return recommendations.cardValues.hoselPosition.bigPlus;
      }
      if (hoselPosition === 'minusminus') {
        return recommendations.cardValues.hoselPosition.bigMinus;
      }
      return recommendations.cardValues.hoselPosition.neutral;
    };

    const clubLengthTransformer = (lengthDifferential): string => {
      if (lengthDifferential === 0) {
        return recommendations.cardValues.clubLength.zero;
      }
      const sign = lengthDifferential > 0 ? '+' : '';
      return `${sign}${lengthDifferential}"`;
    };

    const clubsTransformer = (clubs): string => {
      return clubs.map(club => club.name.abbreviated.toUpperCase()).join(', ');
    };

    return (
      <div className={styles.wrapper}>
        <FormHeader
          isComplete={isComplete}
          header={labels.forms.fairwayResults}
        />
        <div className={layout.vr_4} />
        <RecommendationLongCards
          clubType="fairways"
          sections={{
            club: {
              data: [
                {
                  labelPath: 'cardLabels.setMakeup',
                  dataPath: 'clubs',
                  transformer: clubsTransformer,
                },
                {
                  labelPath: 'cardLabels.hoselPosition',
                  dataPath: 'hoselPosition',
                  transformer: hoselTransformer,
                },
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: clubLengthTransformer,
                },
                {labelPath: 'cardLabels.swingWeight', dataPath: 'swingWeight'},
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {labelPath: 'cardLabels.material', dataPath: 'shaft.material'},
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <RecommendationLongCards
          clubType="hybrids"
          sections={{
            club: {
              data: [
                {
                  labelPath: 'cardLabels.setMakeup',
                  dataPath: 'clubs',
                  transformer: clubsTransformer,
                },
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: clubLengthTransformer,
                },
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {labelPath: 'cardLabels.material', dataPath: 'shaft.material'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <RecommendationLongCards
          clubType="crossovers"
          sections={{
            club: {
              data: [
                {
                  labelPath: 'cardLabels.setMakeup',
                  dataPath: 'clubs',
                  transformer: clubsTransformer,
                },
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: clubLengthTransformer,
                },
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {labelPath: 'cardLabels.material', dataPath: 'shaft.material'},
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <div className={layout.vr_4} />
        <StepNavigation
          nextBtnText={nextBtnText}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          formHandlesNext={false}
        />
      </div>
    );
  }
}

export {FairwayRecommendation as Unconnected};
export default connect(mapStateToProps)(FairwayRecommendation);
