import * as React from 'react';
import {IResponsiveIcons} from '../../../../SectionNavigator';

interface IProps {
  isComplete: boolean;
  isActive: boolean;
  icons: IResponsiveIcons;
}
interface IState {}

export default class ResponsiveIcon extends React.Component<IProps, IState> {
  public render(): JSX.Element {
    const {isComplete, isActive, icons} = this.props;

    const completeAndActive = isComplete && isActive;
    const completeAndInactive = isComplete && !isActive;
    const incompleteAndActive = !isComplete && isActive;
    const incompleteAndInactive = !isComplete && !isActive;

    return (
      <>
        <img
          style={{display: `${completeAndActive ? 'inline' : 'none'}`}}
          src={icons.completeAndActive}
        />
        <img
          style={{display: `${completeAndInactive ? 'inline' : 'none'}`}}
          src={icons.completeAndInactive}
        />
        <img
          style={{display: `${incompleteAndActive ? 'inline' : 'none'}`}}
          src={icons.incompleteAndActive}
        />
        <img
          style={{display: `${incompleteAndInactive ? 'inline' : 'none'}`}}
          src={icons.incompleteAndInactive}
        />
      </>
    );
  }
}
