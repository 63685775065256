import styles from './WristToFloor.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import wristToFloorImg from '../../../../../Assets/Illustrations/Lie_Angle@2x.png';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {inchesToCm} from '../../../../../util/unitConversion';
import {
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  unitType: WebfitSupportedUnitsEnum;
  initialValues: any;
  questions: any;
  labels: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    unitType: unitTypeSelector(state),
    questions: (state.nFlight.localizedJson!.wedges as any).questions,
    initialValues: fullProfileSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    units: state.nFlight.localizedJson!.units,
  };
};
let WristToFloor: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
    unitType,
    units,
  } = props;

  const {lieAngle} = questions;
  const inchesValueLabel =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? inches => `${inches}"`
      : inches => `${inchesToCm(inches)} ${units.centimeters.abbrev}`;

  return (
    <form
      className={styles.wrapper}
      onSubmit={handleSubmit(onNextClick) as any}
    >
      <FormSection name="golferProfile">
        <fieldset>
          <FormHeader
            isComplete={isComplete}
            header={labels.forms.lieAngleColorCode}
          />
          <div className={layout.vr_1} />
          <img
            className={styles.img}
            src={wristToFloorImg}
            alt={lieAngle.imageAltText.male}
          />
          <div className={layout.vr_4} />
          {/* Note: step of 0.5 can't be in JSON as JSON doesn't support real numbers */}
          <Range
            name="wristToFloorInInches"
            label={lieAngle.label}
            min={lieAngle.range.min}
            max={lieAngle.range.max}
            step={0.25}
            getValueLabel={inchesValueLabel}
            defaultValue={lieAngle.defaultValue.imperial}
            defaultValueLabel={labels.whyThis}
            defaultValueTooltipText={lieAngle.helpText}
            screenReaderMaximumLabel={
              lieAngle.screenReaderMaximumLabel[unitType]
            }
            screenReaderMinimumLabel={
              lieAngle.screenReaderMinimumLabel[unitType]
            }
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'inches'
                : 'centimeters'
            }
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

WristToFloor = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(WristToFloor);

WristToFloor = connect(mapStateToProps)(WristToFloor);

export default WristToFloor;
