import styles from './Header.module.scss';
import landingLogo from '../../../Assets/Logos/WebFit Logo Light.svg';
import landingLogoDark from '../../../Assets/Logos/WebFit Logo Dark.svg';
import leftArrowWhite from '../../../Assets/Icons/Arrow Left.svg';
import hamburger from '../../../Assets/Icons/Hamburger Icon.svg';
import caretDown from '../../../Assets/Icons/Caret Down.svg';
import caretUp from '../../../Assets/Icons/Caret Up.svg';
import close from '../../../Assets/Icons/Close.svg';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../SharedRedux/Models/IDispatchProp';
import {unitTypeSelector} from '../../Redux/Selectors/sharedSelectors';
import NFlightActions from '../../Redux/NflightAction';
import WebfitSupportedUnitsEnum from '../../Redux/Models/WebfitSupportedUnits';
import LocalizedJsonResponseModel from '../../Redux/Models/LocalizedJsonResponseModel';
import EnvironmentDataModel from '../../Redux/Models/EnvironmentDataModel';

interface IProps {
  toggleModal: () => void;
}

interface IStateToProps {
  localizedJson: LocalizedJsonResponseModel | null;
  pingNationProfile: any;
  unitType: WebfitSupportedUnitsEnum;
  environmentData: EnvironmentDataModel;
}
interface IState {
  showingMenu: boolean;
}

const mapStateToProps = (state: IStore): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  pingNationProfile: state.nFlight.pingNationProfile,
  unitType: unitTypeSelector(state),
  environmentData: state.nFlight.environmentData,
});

class Header extends React.Component<
  IStateToProps & IDispatchProp & IState & IProps
> {
  public state: IState = {
    showingMenu: false,
  };

  public toggleMenu = () => {
    this.state.showingMenu ? this.closeMenu() : this.openMenu();
  };

  public closeMenu = () => {
    document.removeEventListener('keydown', this.closeMenuViaEscPress, false);
    document.removeEventListener('click', this.closeMenuViaBodyClick, false);
    this.setState({showingMenu: false});
  };

  public openMenu = () => {
    document.addEventListener('keydown', this.closeMenuViaEscPress, false);
    document.addEventListener('click', this.closeMenuViaBodyClick, false);
    this.setState({showingMenu: true});
  };

  public closeMenuViaEscPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      this.setState({showingMenu: false});
    }
  };

  public closeMenuViaBodyClick = e => {
    const clickedInHeader = e.target && e.target.closest('#webfitHeader');
    const {showingMenu} = this.state;
    if (showingMenu && !clickedInHeader) {
      this.setState({showingMenu: false});
    }
  };

  public setUnitType = (e: React.SyntheticEvent) => {
    const unitType = (e.target as any).value;
    this.props.dispatch(NFlightActions.setUnitType(unitType));
  };

  public logOut = () => {
    this.setState({showingMenu: false});
    this.props.dispatch(NFlightActions.logout());
  };

  public getUnitTypeInputs = () => {
    const {unitType, localizedJson} = this.props;

    const units = (localizedJson as any).units;

    return (
      <form className={styles.form}>
        <fieldset>
          <legend className={styles.srOnly}>{units.choose}</legend>
          <div className={styles.vr_1}>
            <label className={styles.input}>
              <input
                name="unitType"
                onChange={this.setUnitType}
                type="radio"
                id="imperialRadio"
                value={WebfitSupportedUnitsEnum.Imperial}
                checked={unitType === WebfitSupportedUnitsEnum.Imperial}
              />
              {units.imperial}
            </label>
          </div>
          <label className={styles.input}>
            <input
              name="unitType"
              onChange={this.setUnitType}
              type="radio"
              id="metricRadio"
              value={WebfitSupportedUnitsEnum.Metric}
              checked={unitType === WebfitSupportedUnitsEnum.Metric}
            />
            {units.metric}
          </label>
        </fieldset>
      </form>
    );
  };

  public getResponsiveHeader = () => {
    const {localizedJson} = this.props;

    const labels = (localizedJson as any).misc.labels;

    return (
      <div className={styles.responsiveHeader}>
        <img className={styles.logo} src={landingLogo} alt="Ping Webfit" />
        <button onClick={this.toggleMenu}>
          <span className={styles.srOnly}>{labels.srViewMenu}</span>
          <img src={hamburger} />
        </button>
      </div>
    );
  };

  public getResponsiveMenu = () => {
    const {localizedJson, pingNationProfile} = this.props;

    const intro = (localizedJson as any).misc.intro;
    const labels = (localizedJson as any).misc.labels;

    return (
      <div className={styles.responsiveMenu}>
        <button className={styles.closeBtn} onClick={this.toggleMenu}>
          <span className={styles.srOnly}>{labels.srCloseMenu}</span>
          <img className={styles.icon} src={close} />
        </button>
        <div className={styles.responsiveMenuBody}>
          <div className={styles.vr_3}>
            <img className={styles.logo} src={landingLogoDark} />
          </div>
          <div className={styles.vr_5}>
            <p className={`${styles.bold} ${styles.vr_half}`}>
              {labels.loggedInAs}
            </p>
            <p>{pingNationProfile.Email}</p>
          </div>
          <div className={styles.vr_8}>{this.getUnitTypeInputs()}</div>
          <div className={styles.vr_8}>
            <button
              className={styles.menuBtn}
              onClick={() => {
                this.props.toggleModal();
                this.toggleMenu();
              }}
              type="button"
            >
              <span>{labels.refreshWebfit}</span>
            </button>
            <div className={styles.vr_1} />
            <button
              className={styles.menuBtn}
              onClick={() => this.logOut()}
              type="button"
            >
              <span>{labels.logOut}</span>
            </button>
          </div>
          <p>
            <a
              href="https://ping.com/terms-of-use"
              target="_blank"
              className={styles.menuLink}
            >
              <span>{intro.termsOfUseLinkLabel}</span>
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://ping.com/privacy"
              target="_blank"
              className={styles.menuLink}
            >
              <span>{intro.privacyPolicyLinkLabel}</span>
            </a>
          </p>
        </div>
      </div>
    );
  };

  public getDesktopHeader = () => {
    const {localizedJson, pingNationProfile, environmentData} = this.props;
    const {showingMenu} = this.state;

    const intro = (localizedJson as any).misc.intro;
    const labels = (localizedJson as any).misc.labels;

    const pingAccoutOverviewUrl = `${environmentData.pingUrl}/account/account-overview`;

    return (
      <div className={styles.desktopHeader}>
        <a className={styles.link} href={pingAccoutOverviewUrl}>
          <img className={styles.icon} src={leftArrowWhite} alt="" />
          <span>{intro.backToPingText || 'Back to Ping.com'}</span>
        </a>
        <h1>
          <img className={styles.logo} src={landingLogo} alt="" />
          <span className={styles.srOnly}>{intro.appName}</span>
        </h1>
        <button className={styles.btn} onClick={this.toggleMenu}>
          <span>
            {labels.greeting} {pingNationProfile.Email}
          </span>
          <span className={styles.srOnly}>{labels.srViewMen}</span>
          <img
            className={styles.icon}
            src={showingMenu ? caretUp : caretDown}
            alt=""
          />
        </button>
      </div>
    );
  };

  public getDesktopMenu = () => {
    const {localizedJson} = this.props;

    const intro = (localizedJson as any).misc.intro;
    const labels = (localizedJson as any).misc.labels;

    return (
      <div className={styles.desktopMenu}>
        {this.getUnitTypeInputs()}
        <hr />
        <button
          className={styles.menuBtn}
          onClick={() => {
            this.props.toggleModal();
            this.toggleMenu();
          }}
          type="button"
        >
          <span>{labels.refreshWebfit}</span>
        </button>
        <div className={styles.vr_1} />
        <button
          className={styles.menuBtn}
          onClick={() => this.logOut()}
          type="button"
        >
          <span>{labels.logOut}</span>
        </button>
        <hr />
        <a
          href="https://ping.com/terms-of-use"
          target="_blank"
          className={styles.menuLink}
        >
          <span>{intro.termsOfUseLinkLabel}</span>
        </a>
        <div className={styles.vr_1} />
        <a
          href="https://ping.com/privacy"
          target="_blank"
          className={styles.menuLink}
        >
          <span>{intro.privacyPolicyLinkLabel}</span>
        </a>
      </div>
    );
  };

  public render(): JSX.Element {
    const {showingMenu} = this.state;

    return (
      <div id="webfitHeader" className={styles.headerContainer}>
        {this.getResponsiveHeader()}
        {this.getDesktopHeader()}
        {showingMenu && (
          <div className={styles.menu}>
            {this.getResponsiveMenu()}
            {this.getDesktopMenu()}
          </div>
        )}
      </div>
    );
  }
}

export {Header as Unconnected};
export default connect(mapStateToProps)(Header);
