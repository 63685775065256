import * as React from 'react';
import {connect} from 'react-redux';
import findIndex from 'lodash.findindex';

import * as tabIcons from '../Assets/Icons/Tabs';

import preloadImages from './imagePreload';
import LegalDisclaimer from './Components/LegalDisclaimer';
import AuthB2C from './Components/Auth/AuthB2C';
import Header from './Components/Header';
import SectionProgress from './Components/SectionProgress';

import NflightActions from './Redux/NflightAction';
import PingNationProfileModel from './Redux/Models/PingNationProfileModel';
import SectionNavigator from '../SharedComponents/SectionNavigator';
import IStore from '../SharedRedux/Models/IStore';
import IDispatchProp from '../SharedRedux/Models/IDispatchProp';
import * as FormsAndRecs from './Components/FormsAndRecommendations';
import * as setLayoutSelectors from './Redux/Selectors/setLayoutSelectors';
import * as sharedSelectors from './Redux/Selectors/sharedSelectors';
import * as driverSelectors from './Redux/Selectors/driverSelectors';
import * as fairwaySelectors from './Redux/Selectors/fairwaySelectors';
import * as ironsSelectors from './Redux/Selectors/ironsSelectors';
import * as wedgesSelectors from './Redux/Selectors/wedgesSelectors';
import * as puttersSelectors from './Redux/Selectors/puttersSelectors';
import AuthB2CModel from './Redux/Models/AuthB2CModel';
import EnvironmentDataModel from './Redux/Models/EnvironmentDataModel';
import ProfileModel from './Redux/Models/ProfileModel';
import LoadingWheel from './Components/LoadingWheel';

interface IProps {}
interface IState {
  environmentData: EnvironmentDataModel;
}
interface IStateToProps {
  acceptedPrivacyPolicy: boolean;
  authB2C: AuthB2CModel;
  pingNationProfile: PingNationProfileModel;
  localizedJson: any;
  selectorState: any;
  environmentData: EnvironmentDataModel;
  profile: ProfileModel;
  isRequestingRecommendations: boolean;
  isRequestingAuth: boolean;
  isRecommendationPage: boolean;
  isRequestingProfileUpdate: boolean;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    acceptedPrivacyPolicy: state.nFlight.acceptedPrivacyPolicy,
    pingNationProfile: state.nFlight.pingNationProfile,
    authB2C: state.nFlight.authB2C,
    localizedJson: state.nFlight.localizedJson,
    selectorState: state,
    environmentData: state.nFlight.environmentData,
    profile: state.nFlight.profile,
    isRequestingRecommendations: state.nFlight.isRequestingRecommendations,
    isRequestingAuth: state.nFlight.isRequestingAuth,
    isRecommendationPage: state.nFlight.isRecommendationPage,
    isRequestingProfileUpdate: state.nFlight.isRequestingProfileUpdate,
  };
};

class NflightWeb extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public componentDidMount(): void {
    const {dispatch} = this.props;

    // loads data upon showing the <LegalDisclaimer />
    dispatch(NflightActions.loadEnvironment());
    dispatch(NflightActions.loadData());
    dispatch(NflightActions.loadCountries());

    // this will only happen if the reducer picks up an auth token.
    if (this.props.authB2C.authorizeToken) {
      // Fetch user data and recommendations
      dispatch(NflightActions.requestRecommendation());
    }

    // preload images last so they are less likely to block
    preloadImages();

    this.updateEnvironmentState();
  }

  public async updateEnvironmentState(): Promise<void> {
    const {environmentData} = this.props;
    this.setState(prevState => {
      return {
        ...prevState,
        environmentData,
      };
    });
  }

  public render(): JSX.Element {
    const {
      authB2C,
      acceptedPrivacyPolicy,
      localizedJson,
      isRequestingRecommendations,
      isRequestingAuth,
      selectorState,
      isRecommendationPage,
      isRequestingProfileUpdate,
    } = this.props;
    const showAuth = !authB2C.authorizeToken;

    if (!acceptedPrivacyPolicy) {
      return <LegalDisclaimer />;
    }

    const {labels} = localizedJson.misc;
    const sections = [
      {
        name: labels.sections.drivers,
        steps: [
          {
            name: labels.forms.profile,
            contents: FormsAndRecs.DriversGeneralInformationForm,
            isComplete: sharedSelectors.generalInformationSelector,
          },
          {
            name: labels.forms.setLayout,
            contents: FormsAndRecs.SetLayoutForm,
            isComplete: setLayoutSelectors.setLayoutSelector,
          },
          {
            name: labels.forms.shotShape,
            contents: FormsAndRecs.ShotShapeForm,
            isComplete: driverSelectors.shotShapeSelector,
          },
          {
            name: labels.forms.loftAndTraj,
            contents: FormsAndRecs.LoftAndTrajectoryForm,
            isComplete: driverSelectors.loftAndTrajectorySelector,
          },
          {
            name: labels.forms.shaft,
            contents: FormsAndRecs.DriverDistanceAndTransitionForm,
            isComplete: driverSelectors.driverDistanceAndTransitionSelector,
          },
          {
            name: labels.forms.grip,
            contents: FormsAndRecs.GripSizingForm,
            isComplete: sharedSelectors.gripSizeSelector,
          },
          {
            name: labels.forms.results,
            contents: FormsAndRecs.DriverRecommendation,
            isComplete: driverSelectors.driversCompleteSelector,
            nextLabel: labels.navigation.driversNext,
          },
        ],
        canRender: logicState => true,
        isComplete: driverSelectors.driversCompleteSelector,
        progressCalculator: driverSelectors.driversProgressSelector,
        defaultPreviousLabel: this.props.localizedJson.misc.labels
          .changeAnswers,
        defaultNextLabel: this.props.localizedJson.misc.labels.continueLabel,
        url: '#drivers',
        responsiveIcons: {
          completeAndActive: tabIcons.DriverCompleteActive,
          completeAndInactive: tabIcons.DriverComplete,
          incompleteAndActive: tabIcons.DriverActive,
          incompleteAndInactive: tabIcons.DriverDefault,
        },
        onNextClick: () => {
          this.props.dispatch(NflightActions.isRecommendationPage(false));
          this.props.dispatch(NflightActions.onProfileChange());
        },
      },
      {
        name: labels.sections.fhc,
        steps: [
          {
            name: labels.forms.profile,
            contents: FormsAndRecs.GeneralInformationForm,
            isComplete: sharedSelectors.generalInformationSelector,
          },
          {
            name: labels.forms.setLayout,
            contents: FormsAndRecs.SetLayoutForm,
            isComplete: setLayoutSelectors.setLayoutSelector,
          },
          {
            name: labels.forms.shotShape,
            contents: FormsAndRecs.ShotShapeForm,
            isComplete: driverSelectors.shotShapeSelector,
          },
          {
            name: labels.forms.desiredUseCase,
            contents: FormsAndRecs.DesiredUseCase,
            isComplete: fairwaySelectors.desiredFairwayUseCaseSelector,
          },
          {
            name: labels.forms.grip,
            contents: FormsAndRecs.GripSizingForm,
            isComplete: sharedSelectors.gripSizeSelector,
          },
          {
            name: labels.forms.results,
            contents: FormsAndRecs.FairwayRecommendation,
            isComplete: fairwaySelectors.fairwayRecommendationCompleteSelector,
            nextLabel: labels.navigation.fhcNext,
          },
        ],
        canRender: driverSelectors.driversCompleteSelector,
        isComplete: fairwaySelectors.fairwayRecommendationCompleteSelector,
        progressCalculator: fairwaySelectors.fairwayProgressSelector,
        defaultPreviousLabel: this.props.localizedJson.misc.labels
          .changeAnswers,
        defaultNextLabel: this.props.localizedJson.misc.labels.continueLabel,
        url: '#fairways-and-utility',
        responsiveIcons: {
          completeAndActive: tabIcons.FairwaysCompleteActive,
          completeAndInactive: tabIcons.FairwaysComplete,
          incompleteAndActive: tabIcons.FairwaysActive,
          incompleteAndInactive: tabIcons.FairwaysDefault,
        },
        onNextClick: () => {
          this.props.dispatch(NflightActions.isRecommendationPage(false));
          this.props.dispatch(NflightActions.onProfileChange());
        },
      },
      {
        name: labels.sections.irons,
        steps: [
          {
            name: labels.forms.profile,
            contents: FormsAndRecs.GeneralInformationForm,
            isComplete: sharedSelectors.generalInformationSelector,
          },
          {
            name: labels.forms.setLayout,
            contents: FormsAndRecs.SetLayoutForm,
            isComplete: setLayoutSelectors.setLayoutSelector,
          },
          {
            name: labels.forms.modelPreference,
            contents: FormsAndRecs.IronPreference,
            isComplete: ironsSelectors.ironPreferenceSelector,
          },
          {
            name: labels.forms.lieAngle,
            contents: FormsAndRecs.IronsWristToFloor,
            isComplete: sharedSelectors.wristToFloorInInchesSelector,
            nextLabel: labels.navigation.lieAngleNext,
          },
          {
            name: labels.forms.shaftPreference,
            contents: FormsAndRecs.IronShaft,
            isComplete: ironsSelectors.ironShaftFormCompleteSelector,
          },
          {
            name: labels.forms.grip,
            contents: FormsAndRecs.GripSizingForm,
            isComplete: sharedSelectors.gripSizeSelector,
          },
          {
            name: labels.forms.results,
            contents: FormsAndRecs.IronsRecommendation,
            isComplete: ironsSelectors.ironsRecommendationCompleteSelector,
            nextLabel: labels.navigation.ironsNext,
          },
        ],
        canRender: logicState => true,
        isComplete: ironsSelectors.ironsRecommendationCompleteSelector,
        progressCalculator: ironsSelectors.ironsProgressSelector,
        defaultPreviousLabel: this.props.localizedJson.misc.labels
          .changeAnswers,
        defaultNextLabel: this.props.localizedJson.misc.labels.continueLabel,
        url: '#irons',
        responsiveIcons: {
          completeAndActive: tabIcons.IronsCompleteActive,
          completeAndInactive: tabIcons.IronsComplete,
          incompleteAndActive: tabIcons.IronsActive,
          incompleteAndInactive: tabIcons.IronsDefault,
        },
        onNextClick: () => {
          this.props.dispatch(NflightActions.isRecommendationPage(false));
          this.props.dispatch(NflightActions.onProfileChange());
        },
      },
      {
        name: labels.sections.wedges,
        steps: [
          {
            name: labels.forms.profile,
            contents: FormsAndRecs.GeneralInformationForm,
            isComplete: sharedSelectors.generalInformationSelector,
          },
          {
            name: labels.forms.setLayout,
            contents: FormsAndRecs.SetLayoutForm,
            isComplete: setLayoutSelectors.setLayoutSelector,
          },
          {
            name: labels.forms.wedgeFitting,
            contents: FormsAndRecs.DivotDepth,
            isComplete: wedgesSelectors.fullSwingFormSelector,
          },
          {
            name: labels.forms.bunkers,
            contents: FormsAndRecs.Bunkers,
            isComplete: wedgesSelectors.bunkersSelector,
          },
          {
            name: labels.forms.lieAngle,
            contents: FormsAndRecs.WedgesWristToFloor,
            isComplete: sharedSelectors.wristToFloorInInchesSelector,
          },
          {
            name: labels.forms.shaft,
            contents: FormsAndRecs.WedgesShaft,
            isComplete: ironsSelectors.ironShaftFormCompleteSelector,
          },
          {
            name: labels.forms.grip,
            contents: FormsAndRecs.GripSizingForm,
            isComplete: sharedSelectors.gripSizeSelector,
          },
          {
            name: labels.forms.results,
            contents: FormsAndRecs.WedgesRecommendation,
            isComplete: wedgesSelectors.wedgesCompleteSelector,
            nextLabel: labels.navigation.wedgesNext,
          },
        ],
        canRender: logicState => true,
        isComplete: wedgesSelectors.wedgesCompleteSelector,
        progressCalculator: wedgesSelectors.wedgesProgressSelector,
        defaultPreviousLabel: this.props.localizedJson.misc.labels
          .changeAnswers,
        defaultNextLabel: this.props.localizedJson.misc.labels.continueLabel,
        url: '#wedges',
        responsiveIcons: {
          completeAndActive: tabIcons.WedgesCompleteActive,
          completeAndInactive: tabIcons.WedgesComplete,
          incompleteAndActive: tabIcons.WedgesActive,
          incompleteAndInactive: tabIcons.WedgesDefault,
        },
        onNextClick: () => {
          this.props.dispatch(NflightActions.isRecommendationPage(false));
          this.props.dispatch(NflightActions.onProfileChange());
        },
      },
      {
        name: labels.sections.putters,
        steps: [
          {
            name: labels.forms.profile,
            contents: FormsAndRecs.GeneralInformationForm,
            isComplete: sharedSelectors.generalInformationSelector,
          },
          {
            name: labels.forms.stroke,
            contents: FormsAndRecs.Stroke,
            isComplete: puttersSelectors.strokeSelector,
          },
          {
            name: labels.forms.feel,
            contents: FormsAndRecs.Feel,
            isComplete: puttersSelectors.feelSelector,
          },
          {
            name: labels.forms.style,
            contents: FormsAndRecs.Style,
            isComplete: puttersSelectors.alignmentAidsSelector,
          },
          {
            name: labels.forms.posture,
            contents: FormsAndRecs.Posture,
            isComplete: puttersSelectors.postureSelector,
          },
          {
            name: labels.forms.putterLength,
            contents: FormsAndRecs.PuttersWristToFloor,
            isComplete: sharedSelectors.wristToFloorInInchesSelector,
          },
          {
            name: labels.forms.modelPreference,
            contents: FormsAndRecs.ModelPreference,
            isComplete: puttersSelectors.modelPreferenceSelector,
          },
          {
            name: labels.forms.results,
            contents: FormsAndRecs.PuttersRecommendation,
            isComplete: puttersSelectors.puttersCompleteSelector,
          },
        ],
        canRender: logicState => true,
        isComplete: puttersSelectors.puttersCompleteSelector,
        progressCalculator: puttersSelectors.puttersProgressSelector,
        defaultPreviousLabel: localizedJson.misc.labels.changeAnswers,
        defaultNextLabel: localizedJson.misc.labels.continueLabel,
        url: '#putters',
        //showStepSide: false,
        responsiveIcons: {
          completeAndActive: tabIcons.PuttersCompleteActive,
          completeAndInactive: tabIcons.PuttersComplete,
          incompleteAndActive: tabIcons.PuttersActive,
          incompleteAndInactive: tabIcons.PuttersDefault,
        },
        onNextClick: () => {
          this.props.dispatch(NflightActions.isRecommendationPage(false));
          this.props.dispatch(NflightActions.onProfileChange());
        },
      },
    ];

    const hash = window.location.hash;
    const sectionToDisplay = findIndex(
      sections,
      section => section.url === hash
    );
    const initialSection = sectionToDisplay === -1 ? 0 : sectionToDisplay;

    return (
      <div id="main" aria-label="main content">
        <SectionNavigator
          Header={Header}
          Side={FormsAndRecs.MyBagRecommendation}
          StepSide={SectionProgress}
          initialSection={initialSection}
          initialStep={0}
          logicState={selectorState}
          sections={sections}
          scrollToTopOnNavigate={true}
          animateStepChange={false}
        />
        {showAuth && <AuthB2C />}
        {(showAuth ||
          isRequestingRecommendations ||
          isRequestingAuth ||
          (isRecommendationPage && isRequestingProfileUpdate)) && (
          <LoadingWheel />
        )}
      </div>
    );
  }
}

export {NflightWeb as Unconnected};
export default connect(mapStateToProps)(NflightWeb);
