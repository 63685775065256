import styles from './LegalDisclaimer.module.scss';

import landingLogo from '../../../Assets/Logos/WebFit Logo Light.svg';
import leftArrowWhite from '../../../Assets/Icons/Arrow Left.svg';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../SharedRedux/Models/IDispatchProp';
import NFlightActions from '../../Redux/NflightAction';
import LocalizedJsonResponseModel from '../../Redux/Models/LocalizedJsonResponseModel';
import SegmentAnalytics from '../../../util/segmentAnalytics';
import EnvironmentDataModel from '../../Redux/Models/EnvironmentDataModel';

interface IState {
  environmentData: EnvironmentDataModel;
}
interface IStateToProps {
  localizedJson: LocalizedJsonResponseModel | null;
  environmentData: EnvironmentDataModel;
}

const mapStateToProps = (state: IStore): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  environmentData: state.nFlight.environmentData,
});

class LegalDisclaimer extends React.Component<
  IStateToProps & IDispatchProp,
  IState
> {
  public acceptPrivacyPolicy = (): void => {
    this.props.dispatch(NFlightActions.acceptPrivacyPolicy());
    SegmentAnalytics.customEvent('Accept Terms Clicked');
  };

  public render(): JSX.Element | null {
    const {localizedJson, environmentData} = this.props;

    if (!localizedJson) {
      return null;
    }

    const intro = (localizedJson as any).misc.intro;
    const pingAccoutOverviewUrl = `${environmentData.pingUrl}/account/account-overview`;

    return (
      <div className={styles.wrapper}>
        <div>
          <a href={pingAccoutOverviewUrl} className={styles.link}>
            <img src={leftArrowWhite} alt="" />
            <span>{intro.backToPingText || 'Back to Ping.com'}</span>
          </a>
        </div>
        <div className={styles.modalContainer}>
          <div className={styles.modal}>
            <img
              className={styles.vr_2}
              src={landingLogo}
              alt={intro.logoAltText}
            />
            <p className={styles.txt1}>{intro.subheader}</p>
            <div className={styles.vr_2} />
            <p className={`${styles.txt1} ${styles.bold}`}>
              {intro.fittingHeadline}
            </p>
            <div className={styles.vr_2} />
            <p className={styles.txt1}>{intro.fittingCopy}</p>
            <div className={styles.vr_4} />
            <p className={styles.txt2}>
              {intro.agreeToTermsRequirement}
              <br />
              <a href="https://ping.com/terms-of-use" target="_blank">
                {intro.termsOfUseLinkLabel}
              </a>
              &nbsp;|&nbsp;
              <a href="https://ping.com/privacy" target="_blank">
                {intro.privacyPolicyLinkLabel}
              </a>
            </p>
            <div className={styles.vr_2} />
            <button
              className={`btn btn_primary ${styles.btnWidth}`}
              onClick={this.acceptPrivacyPolicy}
            >
              {intro.agreeAndContinueButtonLabel}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export {LegalDisclaimer as Unconnected};
export default connect(mapStateToProps)(LegalDisclaimer);
