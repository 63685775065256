import styles from './PuttersRecommendationCard.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import {modelPreferenceValueSelector} from '../../../../Redux/Selectors/puttersSelectors';

interface IProps {
  id?: string;
  forceUpdate?: boolean;
}
interface IState {
  prevPropsRecommendation: any;
  recommendationChanged: boolean;
}
interface IStateToProps {
  labels: any;
  localizedJson: any;
  recommendations: any;
  selectedPutter: string | undefined;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  localizedJson: state.nFlight.localizedJson,
  recommendations: state.nFlight.recommendations,
  selectedPutter: modelPreferenceValueSelector(state),
});

const strokeMap = {
  slightarc: 'Slight Arc',
  strongarc: 'Strong Arc',
};
export const strokeTypeTransformer = (stroke: string) =>
  strokeMap[stroke] || stroke;

class PuttersRecommendationCard extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public render(): JSX.Element | null {
    const {
      forceUpdate,
      localizedJson,
      recommendations,
      selectedPutter,
    } = this.props;

    const putterSuggestion =
      recommendations.putters &&
      recommendations.putters.filter(suggestion => {
        return suggestion.id === selectedPutter;
      });

    if (!putterSuggestion || putterSuggestion.length === 0) {
      return null;
    }

    const {recommendations: recommendationsJSON} = localizedJson;
    const {cardLabels} = recommendationsJSON;

    return (
      <div id={this.props.id} className={styles.recommendationCards}>
        {putterSuggestion.map((rec, index) => {
          const updatedClass = forceUpdate ? styles.updated : styles.notUpdated;
          const imgSrc =
            rec.pdfImage ||
            (putterSuggestion.model && putterSuggestion.model.pdfImage);
          const title = rec.title || (rec.model && rec.model.title);
          const cardStyle = styles.primary;
          return (
            <div
              key={rec.id}
              className={`${styles.recommendationCard} ${cardStyle} ${updatedClass}`}
            >
              <div className={styles.header}>
                {imgSrc && (
                  <div className={`${styles.picture} ${styles.vr_1}`}>
                    <img src={imgSrc} alt={title} />
                  </div>
                )}
                <div>
                  {title && (
                    <p className={styles.vr_1}>
                      {/* NOTE: The spaces between span tags are important here */}
                      <span className={styles.rec}>
                        <span className={styles.bold}>{cardLabels.top}</span>{' '}
                        <span className={styles.bold}>Putter</span>{' '}
                        <span>{cardLabels.recommendation}</span>
                      </span>
                    </p>
                  )}
                  {title && <p className={styles.textHeading}>{title}</p>}
                </div>
              </div>
              <div className={styles.table}>
                <div className={styles.tableEntry}>
                  <p className={styles.datum}>{cardLabels.length}:</p>
                  <p className={styles.value}>{rec.Length}</p>
                </div>
                <div className={styles.tableEntry}>
                  <p className={styles.datum}>{cardLabels.stroke}:</p>
                  <p className={styles.value}>
                    {strokeTypeTransformer(rec.strokeType[0])}
                  </p>
                </div>
                <div className={styles.tableEntry}>
                  <p className={styles.datum}>{cardLabels.headWeight}:</p>
                  <p className={styles.value}>{rec.headweight}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export {PuttersRecommendationCard as Unconnected};
export default connect(mapStateToProps)(PuttersRecommendationCard);
