import styles from './SetLayout.module.scss';
import driver from '../../../../../Assets/Icons/Driver.svg';
import fairway from '../../../../../Assets/Icons/Fairway.svg';
import iron from '../../../../../Assets/Icons/Irons.svg';
import putter from '../../../../../Assets/Icons/Putters.svg';
import wedge from '../../../../../Assets/Icons/Wedges.svg';
import chipr from '../../../../../Assets/Icons/Chipr.svg';
import hybrid from '../../../../../Assets/Icons/Hybrids.svg';
import crossover from '../../../../../Assets/Icons/Crossover.svg';
// import marker from '../../../../../Assets/Icons/Marker.svg';

import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-scroll';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import {modelPreferenceValueSelector} from '../../../../Redux/Selectors/puttersSelectors';

const imgMap = {
  driver,
  fairway,
  hybrid,
  crossover,
  iron,
  putter,
  wedge,
  chipr,
};

interface IProps {
  onClubClick: (rec: string) => void;
  containerId?: string;
}
interface IState {}
interface IStateToProps {
  localizedJson: any;
  recommendations: any;
  selectedPutter: string | undefined;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  recommendations: state.nFlight.recommendations,
  selectedPutter: modelPreferenceValueSelector(state),
});

class SetLayout extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public getRowName(keyName: string, labelName: string): JSX.Element {
    const {
      containerId,
      localizedJson,
      onClubClick,
      recommendations,
      selectedPutter,
    } = this.props;

    const {myBag} = localizedJson;
    const {setLayoutLabels} = myBag;
    let renderRowNameAsText;
    const recObject = recommendations[keyName];

    if (keyName === 'putters') {
      const putterSuggestion =
        recObject &&
        recObject.filter(suggestion => {
          return suggestion.id === selectedPutter;
        });
      renderRowNameAsText = putterSuggestion.length === 0;
    } else {
      const recIsNotArray = !Array.isArray(recObject);
      const recIsEmptyArray =
        Array.isArray(recObject) && recObject.length === 0;
      renderRowNameAsText = !recObject || recIsNotArray || recIsEmptyArray;
    }

    if (renderRowNameAsText) {
      return (
        <div className={styles.disabled}>
          <img src={imgMap[labelName]} alt="" />
          <span>{setLayoutLabels[labelName]}</span>
        </div>
      );
    }

    return (
      <Link
        onClick={() => onClubClick(keyName)}
        className={styles.link}
        containerId={containerId}
        to="allCards"
        smooth={true}
        offset={-115}
        duration={500}
      >
        <button className={styles.link}>
          <img src={imgMap[labelName]} alt="" />
          <span>{setLayoutLabels[labelName]}</span>
        </button>
      </Link>
    );
  }

  public render(): null | JSX.Element {
    const {localizedJson, recommendations, selectedPutter} = this.props;

    const {setLayout} = recommendations;

    if (!setLayout || !localizedJson) {
      return null;
    }

    const {
      crossovers,
      drivers,
      fairways,
      hybrids,
      irons,
      putters,
      wedges,
      chipr,
    } = setLayout;
    const hasPutterSuggestion = !!selectedPutter;

    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.labels}>
            <div className={styles.row}>
              {this.getRowName('drivers', 'driver')}
            </div>
            <div className={styles.row}>
              {this.getRowName('fairways', 'fairway')}
            </div>
            <div className={styles.row}>
              {this.getRowName('hybrids', 'hybrid')}
            </div>
            <div className={styles.row}>
              {this.getRowName('crossovers', 'crossover')}
            </div>
            <div className={styles.row}>{this.getRowName('irons', 'iron')}</div>
            <div className={styles.row}>
              {this.getRowName('wedges', 'wedge')}
            </div>
            <div className={styles.row}>
              {this.getRowName('chipr', 'chipr')}
            </div>
            <div className={styles.row}>
              {this.getRowName('putters', 'putter')}
            </div>
          </div>
          <div className={styles.clubs}>
            <div className={styles.row}>
              {drivers &&
                drivers.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {fairways &&
                fairways.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {hybrids &&
                hybrids.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {crossovers &&
                crossovers.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {irons &&
                irons.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {wedges &&
                wedges.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {chipr &&
                chipr.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            <div className={styles.row}>
              {putters &&
                putters.map(club => {
                  return (
                    club && (
                      <div className={styles.club} key={club}>
                        <div className={styles.fill}>
                          <span>{club}</span>
                        </div>
                      </div>
                    )
                  );
                })}
              {/* This handles the special case where a user has only gotten putter suggestions but not a set layout */}
              {!putters && hasPutterSuggestion && (
                <div className={styles.club}>
                  <div className={styles.fill}>
                    <span>P</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export {SetLayout as Unconnected};
export default connect(mapStateToProps)(SetLayout);
