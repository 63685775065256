import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import tee from '../../../../../Assets/Illustrations/FairwayUse_Combination@2x-100.jpg';
import distance from '../../../../../Assets/Illustrations/FairwayUse_Distance@2x-100.jpg';
//par3
import par3 from '../../../../../Assets/Illustrations/FairwayUse_Approach@2x-100.jpg';
import precision from '../../../../../Assets/Illustrations/FairwayUse_Precision@2x-100.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  fairways: any;
  labels: any;
}

const imgMap = {
  tee,
  distance,
  par3,
  precision,
};

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  fairways: state.nFlight.localizedJson!.fairways,
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
});

let DesiredUseCase: any = (props: IProps & IStateToProps) => {
  const {
    fairways,
    labels,
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    nextBtnText,
  } = props;

  const {desiredUseCase} = fairways.questions;

  const options = desiredUseCase.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="fairwayProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={fairways.label} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={desiredUseCase.label}
            name="desiredUseCase"
            options={options}
            view="tiled"
            defaultValue={desiredUseCase.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={desiredUseCase.helpText}
            defaultHelpTextVisibility={desiredUseCase.defaultHelpTextVisibility}
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

DesiredUseCase = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(DesiredUseCase);

DesiredUseCase = connect(mapStateToProps)(DesiredUseCase);

export default DesiredUseCase;
