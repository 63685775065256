import styles from './SectionProgress.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../SharedRedux/Models/IStore';
import LocalizedJsonResponseModel from '../../Redux/Models/LocalizedJsonResponseModel';

interface IProps {
  completionSelector?: any;
}
interface IStateToProps {
  localizedJson: LocalizedJsonResponseModel | null;
  status: {
    complete: number;
    questions: number;
  };
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  const status = ownProps.completionSelector(state);
  return {
    localizedJson: state.nFlight.localizedJson,
    status: status || {complete: 0, questions: 0},
  };
};

class SectionProgress extends React.Component<IProps & IStateToProps> {
  public render(): JSX.Element | null {
    const {status, localizedJson} = this.props;
    const labels = (localizedJson as any).misc.labels;

    if (status.questions === 0) {
      return null;
    }

    const percentage =
      ((status.complete as number) / (status.questions as number)) * 100;
    const displayPercentage = percentage.toFixed(0);
    const widthPercentage = `${displayPercentage}%`;

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <p className={styles.bold}>
            <span>
              {displayPercentage}
              {labels.percentageComplete}
            </span>
          </p>
          <p>
            {status.complete}/{status.questions} {labels.questions}
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.barWrapper}>
            <div className={styles.bar} style={{width: widthPercentage}} />
          </div>
        </div>
      </div>
    );
  }
}

export {SectionProgress as Unconnected};
export default connect(mapStateToProps)(SectionProgress);
