import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import crouched from '../../../../../Assets/Illustrations/Posture_Crouched.jpg';
import neutral from '../../../../../Assets/Illustrations/Posture_Neutral.jpg';
import upright from '../../../../../Assets/Illustrations/Posture_Upright.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  crouched,
  neutral,
  upright,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  unitType: any;
  initialValues: any;
  labels: any;
  questions: any;
  golferProfileQuestions: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  unitType: unitTypeSelector(state),
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.putter as any).questions,
  golferProfileQuestions: (state.nFlight.localizedJson!.profile as any)
    .questions,
  units: state.nFlight.localizedJson!.units,
});

let Posture: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
  } = props;

  const {posture} = questions;

  const postureOptions = posture.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="putterProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.posture} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={posture.label}
            name="posture"
            defaultValue={posture.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={posture.helpText}
            options={postureOptions}
            view="stretched"
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

Posture = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(Posture);

Posture = connect(mapStateToProps)(Posture);

export default Posture;
