import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import high from '../../../../../Assets/Illustrations/Trajectory_High@2x-100.jpg';
import low from '../../../../../Assets/Illustrations/Trajectory_Low@2x-100.jpg';
import medium from '../../../../../Assets/Illustrations/Trajectory_Medium@2x-100.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import Range from '../../../../../SharedComponents/Forms/Range';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import StepNavigation from '../StepNavigation';
import FormHeader from '../FormHeader';
import {yardsToMeters} from '../../../../../util/unitConversion';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  high,
  low,
  medium,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  unitType: WebfitSupportedUnitsEnum;
  labels: any;
  questions: any;
  initialValues: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    unitType: unitTypeSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    questions: (state.nFlight.localizedJson!.irons as any).questions,
    initialValues: fullProfileSelector(state),
    units: state.nFlight.localizedJson!.units,
  };
};

let SetLayoutForm: any = (props: IProps & IStateToProps & IDispatchProp) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    unitType,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
    units,
  } = props;

  const {carryDistance, sevenIronTrajectory} = questions;

  const heightLabelFunction =
    unitType === 'imperial'
      ? yds => `${yds}${units.yards.abbrev}`
      : yds => `${yardsToMeters(yds)}${units.meters.abbrev}`;

  const sevenIronCarryOptions = sevenIronTrajectory.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="golferProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.setLayout} />
          <div className={layout.vr_4} />
          <Range
            name="sevenIronCarryDistanceInYards"
            label={carryDistance.label}
            min={carryDistance.range[WebfitSupportedUnitsEnum.Imperial].min}
            max={carryDistance.range[WebfitSupportedUnitsEnum.Imperial].max}
            step={carryDistance.range[WebfitSupportedUnitsEnum.Imperial].step}
            getValueLabel={heightLabelFunction}
            defaultValue={
              carryDistance.defaultValue[WebfitSupportedUnitsEnum.Imperial]
            }
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={carryDistance.helpText}
            screenReaderMaximumLabel={
              carryDistance.screenReaderMaximumLabel[unitType]
            }
            screenReaderMinimumLabel={
              carryDistance.screenReaderMinimumLabel[unitType]
            }
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'yards'
                : 'meters'
            }
          />
          <PictorialRadioInput
            label={sevenIronTrajectory.label}
            name="typicalShotHeight"
            defaultValue={sevenIronTrajectory.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={sevenIronTrajectory.helpText}
            options={sevenIronCarryOptions}
            view="stretched"
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

SetLayoutForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
  forceUnregisterOnUnmount: true,
})(SetLayoutForm);

SetLayoutForm = connect(mapStateToProps)(SetLayoutForm);

export default SetLayoutForm;
