import styles from './IronShaft.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import graphite from '../../../../../Assets/Illustrations/Shaft_Graphite@2x-100.jpg';
import steel from '../../../../../Assets/Illustrations/Shaft_Steel@2x-100.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  questions: any;
  labels: any;
  pingNationProfile: any;
}
const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    questions: (state.nFlight.localizedJson!.irons as any).questions,
    initialValues: fullProfileSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    pingNationProfile: state.nFlight.pingNationProfile,
  };
};

let IronShaft: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
  } = props;

  const {shaftPreference} = questions;

  const shaftPreferenceOptions = shaftPreference.options.map(opt => {
    return {
      ...opt,
      imgSrc: opt.value === 'steel' ? steel : graphite,
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="ironProfile">
        <fieldset>
          <FormHeader
            isComplete={isComplete}
            header={labels.forms.shaftPreference}
          />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={shaftPreference.label}
            name="shaftMaterialPreference"
            defaultValue={shaftPreference.defaultValue}
            defaultValueLabel={labels.dontKnow}
            view="tiled"
            defaultValueTooltipText={shaftPreference.helpText}
            options={shaftPreferenceOptions}
            hideBottomMargin={true}
          />
          <div className={`${styles.helpTexts} ${layout.vr_6}`}>
            {shaftPreference.options.map(opt => (
              <p key={opt.value}>{opt.description}</p>
            ))}
          </div>
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

IronShaft = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(IronShaft);

IronShaft = connect(mapStateToProps)(IronShaft);

export default IronShaft;
