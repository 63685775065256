import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import {destroy, change} from 'redux-form';
import LocalizedJsonResponseModel from './Models/LocalizedJsonResponseModel';
import CountriesJsonResponseModel from './Models/CountriesJsonResponseModel';
import RecommendationModel from './Models/RecommendationModel';
import PingNationProfileModel from './Models/PingNationProfileModel';
import * as msal from '@azure/msal-browser';

import DevConfig from '../../config/development.json';
import StageConfig from '../../config/stage.json';
import ProdConfig from '../../config/production.json';

import NflightEffects from './NflightEffects';
import {
  authB2CSelector,
  pingNationSelector,
  environmentDataSelector,
  fullProfileSelector,
  profileDataSelector,
  previousFullProfileSelector,
} from './Selectors/sharedSelectors';
import {modelPreferenceValueSelector} from './Selectors/puttersSelectors';
import IAction from '../../SharedRedux/Models/IAction';
import HttpErrorResponseModel from '../../SharedRedux/Models/HttpErrorResponseModel';
import WebfitSupportedUnitsEnum from './Models/WebfitSupportedUnits';
import SegmentAnalytics from '../../util/segmentAnalytics';
import AuthB2CModel from './Models/AuthB2CModel';
import EnvironmentDataModel from './Models/EnvironmentDataModel';
import ProfileModel from './Models/ProfileModel';
import ProfilesModel from './Models/ProfilesModel';

type DataObject = {[key: string]: any};
export type UnitTypeUpdate = {
  profile: ProfileModel;
  unitType: WebfitSupportedUnitsEnum;
};

export type NflightActionUnion =
  | void
  | boolean
  | string
  | LocalizedJsonResponseModel
  | CountriesJsonResponseModel
  | RecommendationModel
  | PingNationProfileModel
  | EnvironmentDataModel
  | UnitTypeUpdate
  | DataObject;

export default class NflightAction {
  public static readonly LOCAL_STORAGE_KEY = 'webfit';
  public static readonly LOCAL_AUTH_KEY = 'auth';
  public static readonly LOAD_DATA: string = 'NflightAction.LOAD_DATA';
  public static readonly LOAD_DATA_FINISHED: string =
    'NflightAction.LOAD_DATA_FINISHED';
  public static readonly LOAD_DATA_ERROR: string =
    'Nflightaction.LOAD_DATA_ERROR';

  public static readonly LOAD_COUNTRIES: string =
    'NflightAction.LOAD_COUNTRIES';
  public static readonly LOAD_COUNTRIES_FINISHED: string =
    'NflightAction.LOAD_COUNTRIES_FINISHED';
  public static readonly LOAD_COUNTRIES_ERROR: string =
    'Nflightaction.LOAD_COUNTRIES_ERROR';

  public static readonly LOAD_AUTH: string = 'NflightAction.LOAD_AUTH';

  public static readonly ACCEPT_PRIVACY_POLICY: string =
    'NflightAction.ACCEPT_PRIVACY_POLICY';
  public static readonly UNACCEPT_PRIVACY_POLICY: string =
    'NflightAction.UNACCEPT_PRIVACY_POLICY';
  public static readonly PREFER_UNIT_TYPE: string =
    'NflightAction.PREFER_UNIT_TYPE';
  public static readonly CLEAR_AUTH_ERROR: string =
    'NflightAction.CLEAR_AUTH_ERROR';
  public static readonly SET_PREVIOUS_REQUEST_PAYLOAD: string =
    'NflightAction.SET_PREVIOUS_REQUEST_PAYLOAD';
  public static readonly LOGOUT: string = 'NflightAction.LOGOUT';

  public static readonly REQUESTING_ENVIRONMENT: string =
    'NflightAction.REQUESTING_ENVIRONMENT';

  public static readonly REQUEST_LOGIN: string = 'NflightAction.REQEUST_LOGIN';
  public static readonly REQUEST_REGISTER: string =
    'NflightAction.REQUEST_REGISTER';

  public static readonly REQUESTING_LOGOUT: string =
    'NflightAction.REQUESTING_LOGOUT';
  public static readonly REQUEST_LOGOUT_FINISHED: string =
    'NflightAction.REQUEST_LOGOUT_FINISHED';
  public static readonly REQUEST_LOGOUT_ERROR: string =
    'NflightAction.REQUEST_LOGOUT_ERROR';

  public static readonly REQUESTING_AUTH: string = 'NflightAction.REQEUST_AUTH';
  public static readonly REQUEST_AUTH_FINISHED: string =
    'NflightAction.REQUEST_AUTH_FINISHED';
  public static readonly REQUEST_AUTH_ERROR: string =
    'NflightAction.REQUEST_AUTH_ERROR';

  public static readonly REQUEST_RECOMMENDATION: string =
    'NflightAction.REQUEST_RECOMMENDATION';
  public static readonly REQUESTING_RECOMMENDATION: string =
    'NflightAction.REQUESTING_RECOMMENDATION';
  public static readonly REQUEST_RECOMMENDATION_FINISHED: string =
    'NflightAction.REQUEST_RECOMMENDATION_FINISHED';
  public static readonly REQUEST_RECOMMENDATION_ERROR: string =
    'NflightAction.REQUEST_RECOMMENDATION_ERROR';
  public static readonly REQUEST_RESET_FITTING: string =
    'NflightAction.REQUEST_RESET_FITTING';

  public static readonly REQUEST_PROFILE: string =
    'NflightAction.REQUEST_PROFILE';
  public static readonly REQUEST_PROFILE_FINISHED: string =
    'NflightAction.REQUEST_PROFILE_FINISHED';
  public static readonly REQUEST_PROFILE_ERROR: string =
    'NflightAction.REQUEST_PROFILE';
  public static readonly REQUEST_USER_PROFILE_FINISHED: string =
    'NflightAction.REQUEST_USER_PROFILE_FINISHED';

  public static readonly REQUEST_SAVE_PROFILE: string =
    'NflightAction.REQUEST_SAVE_PROFILE';
  public static readonly REQUEST_SAVE_PROFILE_FINISHED: string =
    'NflightAction.REQUEST_SAVE_PROFILE_FINISHED';
  public static readonly REQUEST_SAVE_PROFILE_ERROR: string =
    'NflightAction.REQUEST_SAVE_PROFILE_ERROR';
  public static readonly REQUEST_SET_IS_RECOMMENDATION_PAGE: string =
    'NflightAction.REQUEST_SET_IS_RECOMMENDATION_PAGE';
  public static readonly REQUESTING_PUTTERS =
    'NflightAction.REQUESTING_PUTTERS';
  public static readonly REQUEST_PUTTERS_FINISHED =
    'NflightAction.REQUEST_PUTTERS_FINISHED';
  public static readonly REQUEST_PUTTERS_ERROR =
    'NflightAction.REQUEST_PUTTERS_ERROR';

  public static loadEnvironment(): any {
    return async (dispatch, getState) => {
      const configs = {
        development: DevConfig,
        stage: StageConfig,
        production: ProdConfig,
      };
      const environment =
        process.env.REACT_APP_ENV != undefined
          ? process.env.REACT_APP_ENV
          : 'development';

      const configFile =
        configs[environment] !== undefined
          ? configs[environment]
          : configs.development;

      dispatch(NflightAction.loadEnvironmentFinished(configFile));
    };
  }

  public static loadEnvironmentFinished(
    payload
  ): IAction<EnvironmentDataModel> {
    return {
      type: NflightAction.REQUESTING_ENVIRONMENT,
      payload,
      error: false,
    };
  }

  /* LOCALIZED JSON */
  public static loadData(): any {
    return async (dispatch, getState) => {
      const {language} = getState().nFlight;

      const payload = await NflightEffects.fetchData(language);

      if (payload instanceof HttpErrorResponseModel) {
        dispatch(NflightAction.loadDataError(payload));
        return;
      }

      dispatch(NflightAction.loadDataFinished(payload));
    };
  }

  public static loadDataFinished(payload): IAction<LocalizedJsonResponseModel> {
    return {
      type: NflightAction.LOAD_DATA_FINISHED,
      payload,
      error: false,
    };
  }

  public static loadDataError(payload): IAction<HttpErrorResponseModel> {
    return {
      type: NflightAction.LOAD_DATA_ERROR,
      payload,
      error: true,
    };
  }

  public static loadCountries(): any {
    return async (dispatch, getState) => {
      const payload = await NflightEffects.fetchCountries();

      if (payload instanceof HttpErrorResponseModel) {
        dispatch({
          type: NflightAction.LOAD_COUNTRIES_ERROR,
          payload,
          error: true,
        });
        return;
      }

      dispatch({
        type: NflightAction.LOAD_COUNTRIES_FINISHED,
        payload,
        error: false,
      });
    };
  }

  /* UI AND APPLICATION STATE */
  public static acceptPrivacyPolicy(): IAction<boolean> {
    return {
      type: NflightAction.ACCEPT_PRIVACY_POLICY,
      payload: true,
      error: false,
    };
  }

  public static unacceptPrivacyPolicy(): IAction<boolean> {
    return {
      type: NflightAction.UNACCEPT_PRIVACY_POLICY,
      payload: false,
      error: false,
    };
  }

  public static setUnitType(unitType: WebfitSupportedUnitsEnum): any {
    return async (dispatch, getState) => {
      const state = getState();

      const newProfileModel = cloneDeep(state.nFlight.profile);
      newProfileModel.setUnitType(unitType);

      const payload: UnitTypeUpdate = {
        profile: newProfileModel,
        unitType,
      };

      dispatch(change('profile', 'preferredUnitType', unitType));
      dispatch(NflightAction.preferUnitType(payload));
      dispatch(NflightAction.requestSaveProfile());
    };
  }

  public static preferUnitType(
    payload: UnitTypeUpdate
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.PREFER_UNIT_TYPE,
      payload,
      error: false,
    };
  }

  public static clearAuthError(): IAction<boolean> {
    return {
      type: NflightAction.CLEAR_AUTH_ERROR,
      payload: false,
      error: false,
    };
  }

  public static setPreviousRequestPayload(payload: any): IAction<DataObject> {
    return {
      type: NflightAction.SET_PREVIOUS_REQUEST_PAYLOAD,
      payload,
      error: false,
    };
  }

  public static isRecommendationPage(
    payload: boolean
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.REQUEST_SET_IS_RECOMMENDATION_PAGE,
      payload,
      error: false,
    };
  }

  public static onProfileChange(): any {
    return async (dispatch, getState) => {
      const state = getState();

      const profilePayload = fullProfileSelector(state);
      const previousRequestPayload = previousFullProfileSelector(state);

      const profileHasNotChanged = isEqual(
        profilePayload,
        previousRequestPayload
      );

      if (profileHasNotChanged) {
        return;
      }

      dispatch(NflightAction.requestSaveProfile());
    };
  }

  public static logout(): any {
    return async (dispatch, getState) => {
      const state = getState();
      const environmentData = await environmentDataSelector(state);
      const pingNation = pingNationSelector(state);

      dispatch(NflightAction.requestingLogout());

      const msalConfig = {
        auth: environmentData.authData,
      };

      const msalInstance = new msal.PublicClientApplication(msalConfig);
      const msalAccount = msalInstance.getAccountByUsername(pingNation.Email);

      await msalInstance
        .logoutPopup({
          account: msalAccount ? msalAccount : undefined,
        })
        .then(e => {
          console.log('Successfully logged out user', e);
          dispatch(NflightAction.requestLogoutFinished());
        })
        .catch(e => {
          console.log('Error with logging users out', e);
          dispatch(NflightAction.requestLoginError());

          window.location.href =
            environmentData.authData.logoutUrl != ''
              ? `${environmentData.authData.logoutUrl}${window.location.origin}`
              : window.location.origin;
        });
    };
  }

  public static requestingLogout(): IAction<true> {
    return {
      type: NflightAction.REQUESTING_LOGOUT,
      payload: true,
      error: false,
    };
  }

  public static requestLogoutFinished(): IAction<true> {
    return {
      type: NflightAction.REQUEST_LOGOUT_FINISHED,
      payload: true,
      error: false,
    };
  }

  public static requestLoginError(): IAction<true> {
    return {
      type: NflightAction.REQUEST_LOGOUT_ERROR,
      payload: true,
      error: true,
    };
  }

  public static resetFitting(): any {
    return async (dispatch, getState) => {
      const state = getState();
      dispatch(destroy('profile'));

      const authB2C = authB2CSelector(state);
      const profileData = profileDataSelector(state);
      const environmentData = environmentDataSelector(state);

      dispatch(NflightAction.requestingResetFitting());

      const response:
        | ProfileModel
        | HttpErrorResponseModel = await NflightEffects.fetchSaveProfile(
        authB2C,
        environmentData,
        profileData,
        {}
      );

      if (response instanceof HttpErrorResponseModel) {
        dispatch(NflightAction.requestSaveProfileError(response));
        dispatch(NflightAction.logout());
        return;
      }

      const {BagRecommendation} = response;

      dispatch(NflightAction.requestSaveProfileFinished(response));

      dispatch(
        NflightAction.clearChosenPutterModelOnNewSuggestions(BagRecommendation)
      );
      dispatch(NflightAction.requestRecommendationFinished(BagRecommendation));
    };
  }

  public static requestingResetFitting(): IAction<true> {
    return {
      type: NflightAction.REQUEST_RESET_FITTING,
      payload: true,
      error: false,
    };
  }

  /* REQUEST LOGIN */
  public static requestLogin(loginResponse: any): any {
    return async dispatch => {
      dispatch(NflightAction.requestingAuth());
      dispatch(
        NflightAction.requestAuthFinished({
          authorizeToken: loginResponse.idToken,
        })
      );
      const {uniqueId, idTokenClaims} = loginResponse;
      const {email} = idTokenClaims;

      SegmentAnalytics.loggedIn(uniqueId, email);

      dispatch(NflightAction.requestProfile());
    };
  }

  public static requestingAuth(): IAction<true> {
    return {
      type: NflightAction.REQUESTING_AUTH,
      payload: true,
      error: false,
    };
  }

  public static requestAuthFinished(
    payload: AuthB2CModel
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.REQUEST_AUTH_FINISHED,
      payload,
      error: false,
    };
  }

  public static requestAuthError(payload): IAction<HttpErrorResponseModel> {
    return {
      type: NflightAction.REQUEST_AUTH_ERROR,
      payload,
      error: true,
    };
  }

  /***** REQUEST PROFILE ********/
  public static requestProfile(): any {
    return async (dispatch, getState) => {
      const state = getState();
      const authB2C = authB2CSelector(state);
      const environmentData = environmentDataSelector(state);

      dispatch(NflightAction.requestingProfile());
      const response:
        | ProfilesModel
        | HttpErrorResponseModel = ({} = await NflightEffects.fetchProfile(
        authB2C,
        environmentData
      ));

      if (response instanceof HttpErrorResponseModel) {
        dispatch(NflightAction.requestProfileError(response));
        return;
      }

      const {UserProfile, Profiles} = response;
      const Profile = Profiles.length > 0 ? Profiles[0] : new ProfileModel({});

      dispatch(NflightAction.requestUserProfileFinished(UserProfile));
      dispatch(NflightAction.requestProfileFinished(new ProfileModel(Profile)));
      dispatch(NflightAction.requestRecommendation());
    };
  }

  public static requestingProfile(): IAction<true> {
    return {
      type: NflightAction.REQUEST_PROFILE,
      payload: true,
      error: false,
    };
  }

  public static requestProfileFinished(
    payload: ProfileModel
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.REQUEST_PROFILE_FINISHED,
      payload,
      error: false,
    };
  }

  public static requestUserProfileFinished(
    payload: PingNationProfileModel
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.REQUEST_USER_PROFILE_FINISHED,
      payload,
      error: false,
    };
  }

  public static requestProfileError(payload): IAction<HttpErrorResponseModel> {
    return {
      type: NflightAction.REQUEST_PROFILE_ERROR,
      payload,
      error: true,
    };
  }

  /* REQUEST SELECTED PROFILE */
  public static requestRecommendation(): any {
    return async (dispatch, getState) => {
      const state = getState();
      const authB2C = authB2CSelector(state);
      const profileData = profileDataSelector(state);
      const environmentData = environmentDataSelector(state);

      dispatch(NflightAction.requestingRecommendation());

      const payload = await NflightEffects.fetchProfileRecomendation(
        authB2C,
        environmentData,
        profileData
      );

      if (payload instanceof HttpErrorResponseModel) {
        dispatch(NflightAction.requestRecommendationError(payload));
        dispatch(NflightAction.logout());
        return;
      }

      const {BagRecommendation} = payload;

      dispatch(
        NflightAction.clearChosenPutterModelOnNewSuggestions(BagRecommendation)
      );
      dispatch(NflightAction.requestRecommendationFinished(BagRecommendation));
    };
  }

  public static clearChosenPutterModelOnNewSuggestions(payload) {
    return async (dispatch, getState) => {
      /*  Upon requesting new putter suggestions, the system might recommend
          new putters which the user hasn't seen yet,
          meaning any old selection of putter model is now invalid.
          To check this we should get any chosen model of putter,
          and check if the new suggestions contain that ID.
          If so, we do nothing. If not, then we need to reset the modelPreference
          question and make it unanswered-- we do that by deleting the value in the redux-form
          store. We wrap this attempt in a try in case the API changes down the line.
      */
      try {
        const state = getState();
        const modelPreference = modelPreferenceValueSelector(state);
        const {putters} = payload;

        if (modelPreference && !!putters && putters.length > 0) {
          const newSuggestionsDoNotHaveOldPreference =
            putters.filter(putter => putter.id === modelPreference).length ===
            0;
          if (newSuggestionsDoNotHaveOldPreference) {
            // dispatch redux-form action to clear the question.
            dispatch(change('profile', 'putterProfile.modelPreference', null));
          }
        }
      } catch (e) {}
    };
  }

  public static requestingRecommendation(): IAction<true> {
    return {
      type: NflightAction.REQUESTING_RECOMMENDATION,
      payload: true,
      error: false,
    };
  }

  public static requestRecommendationFinished(
    payload
  ): IAction<RecommendationModel> {
    return {
      type: NflightAction.REQUEST_RECOMMENDATION_FINISHED,
      payload,
      error: false,
    };
  }

  public static requestRecommendationError(
    payload
  ): IAction<HttpErrorResponseModel> {
    return {
      type: NflightAction.REQUEST_RECOMMENDATION_ERROR,
      payload,
      error: true,
    };
  }

  /* SAVING PROFILES */
  public static requestSaveProfile(): any {
    return async (dispatch, getState) => {
      const state = getState();
      const authB2C = authB2CSelector(state);
      const profileData = profileDataSelector(state);
      const profilePayload = fullProfileSelector(state);
      const environmentData = environmentDataSelector(state);

      dispatch(NflightAction.requestingSaveProfile());

      const response:
        | ProfileModel
        | HttpErrorResponseModel = await NflightEffects.fetchSaveProfile(
        authB2C,
        environmentData,
        profileData,
        profilePayload
      );

      if (response instanceof HttpErrorResponseModel) {
        dispatch(NflightAction.requestSaveProfileError(response));
        dispatch(NflightAction.logout());
        return;
      }
      const {BagRecommendation} = response;

      dispatch(
        NflightAction.clearChosenPutterModelOnNewSuggestions(BagRecommendation)
      );
      dispatch(NflightAction.requestRecommendationFinished(BagRecommendation));

      dispatch(NflightAction.requestSaveProfileFinished(response));
    };
  }

  public static requestingSaveProfile(): IAction<true> {
    return {
      type: NflightAction.REQUEST_SAVE_PROFILE,
      payload: true,
      error: false,
    };
  }

  public static requestSaveProfileFinished(
    payload: ProfileModel
  ): IAction<NflightActionUnion> {
    return {
      type: NflightAction.REQUEST_SAVE_PROFILE_FINISHED,
      payload,
      error: false,
    };
  }

  public static requestSaveProfileError(
    payload
  ): IAction<HttpErrorResponseModel> {
    return {
      type: NflightAction.REQUEST_SAVE_PROFILE_ERROR,
      payload,
      error: true,
    };
  }
}
