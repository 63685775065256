import get from 'lodash.get';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  generalInformationSelector,
  gripSizeSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
} from './sharedSelectors';
import {
  desiredFlightSelector,
  currentFlightSelector,
  shotShapeSelector,
} from './driverSelectors';
import {setLayoutSelector, setLayoutProgress} from './setLayoutSelectors';

const desiredFairwayUseCaseFormValue = (state: IStore) =>
  get(state, `${FORM_ROOT}.fairwayProfile.desiredUseCase`);

const desiredFairwayUseCaseProfileValue = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.fairwayProfile.desiredUseCase`);

export const desiredFairwayUseCaseSelector = createSelector(
  desiredFairwayUseCaseFormValue,
  desiredFairwayUseCaseProfileValue,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const fairwayRecommendationCompleteSelector = createSelector(
  generalInformationSelector,
  gripSizeSelector,
  setLayoutSelector,
  shotShapeSelector,
  desiredFairwayUseCaseSelector,
  (general, grip, sL, shot, desired) => general && grip && sL && shot && desired
);

const onlyFairwayProgressSelector = createSelector(
  desiredFairwayUseCaseSelector,
  desiredFlightSelector,
  currentFlightSelector,
  (
    desiredFairwayUseCaseSelector,
    desiredFlightSelector,
    currentFlightSelector
  ) =>
    [
      desiredFairwayUseCaseSelector,
      desiredFlightSelector,
      currentFlightSelector,
    ].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);

export const fairwayProgressSelector = createSelector(
  onlyFairwayProgressSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
  setLayoutProgress,
  (
    onlyFairwayProgressSelector,
    generalInformationProgress,
    gripSizeSelectorProgress,
    setLayoutProgress
  ) => {
    return [
      onlyFairwayProgressSelector,
      generalInformationProgress,
      gripSizeSelectorProgress,
      setLayoutProgress,
    ].reduce(
      (acc, selector) => {
        acc.questions += selector.questions;
        acc.complete += selector.complete;
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
