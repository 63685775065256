/*
    // Returned Api Data Sample
    {
      languageCode: 'en-us',
      misc: {...},
      units: {...},
      profile: {...},
      driver: {...},
      fairways: {...},
      irons: {...},
      hybrids: {...},
      crossovers: {...},
      wedges: {...},
      putter: {...},
      fullset: {...},
      cta: {...},
    }
 */

type LocalizationBlob =
  | {
      [key: string]: any;
    }
  | undefined;

export default class LocalizedJsonResponseModel {
  public readonly languageCode: string | undefined = '';
  public readonly auth: LocalizationBlob = {};
  public readonly misc: LocalizationBlob = {
    intro: {},
    labels: {},
    print: {},
    defaultImageAltText: '',
  };
  public readonly units: LocalizationBlob = {};
  public readonly profile: LocalizationBlob = {};
  public readonly driver: LocalizationBlob = {};
  public readonly fairways: LocalizationBlob = {};
  public readonly irons: LocalizationBlob = {};
  public readonly hybrids: LocalizationBlob = {};
  public readonly crossovers: LocalizationBlob = {};
  public readonly wedges: LocalizationBlob = {};
  public readonly putter: LocalizationBlob = {};
  public readonly myBag: LocalizationBlob = {};
  public readonly recommendations: LocalizationBlob = {};
  public readonly fullset: LocalizationBlob = {};
  public readonly cta: LocalizationBlob = {};

  constructor(data: Partial<LocalizedJsonResponseModel>) {
    this.languageCode = data.languageCode;
    this.misc = data.misc;
    this.auth = data.auth;
    this.units = data.units;
    this.profile = data.profile;
    this.driver = data.driver;
    this.fairways = data.fairways;
    this.irons = data.irons;
    this.hybrids = data.hybrids;
    this.crossovers = data.crossovers;
    this.wedges = data.wedges;
    this.putter = data.putter;
    this.myBag = data.myBag;
    this.recommendations = data.recommendations;
    this.fullset = data.fullset;
    this.cta = data.cta;
  }
}
