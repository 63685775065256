import styles from './RecommendationLongCards.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import get from 'lodash.get';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';

interface IProps {
  clubType: string;
  sections: {
    [key: string]: {
      data: {
        labelPath: string;
        dataPath: string;
        transformer?: (val: any) => any;
      }[];
      imgSrc: string;
    };
  };
  noRecsMessage?: string;
}
interface IState {
  firstActiveSectionName: string;
  secondActiveSectionName: string;
}
interface IStateToProps {
  localizedJson: any;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  recommendations: state.nFlight.recommendations,
});

class RecommendationLongCard extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public state: IState = {
    firstActiveSectionName: Object.keys(this.props.sections)[0] || '',
    secondActiveSectionName: Object.keys(this.props.sections)[0] || '',
  };

  public setFirstActiveSectionName = (firstActiveSectionName: string) => {
    this.setState({firstActiveSectionName});
  };

  public setSecondActiveSectionName = (secondActiveSectionName: string) => {
    this.setState({secondActiveSectionName});
  };

  public render(): null | JSX.Element {
    const {
      clubType,
      localizedJson,
      recommendations,
      noRecsMessage,
      sections,
    } = this.props;
    const {firstActiveSectionName, secondActiveSectionName} = this.state;
    const shouldNotRender =
      !recommendations || !Array.isArray(recommendations[clubType]);
    if (shouldNotRender && !noRecsMessage) {
      return null;
    }

    if (
      (shouldNotRender || recommendations[clubType].length === 0) &&
      noRecsMessage
    ) {
      return <p className={styles.centered}>{noRecsMessage}</p>;
    }

    const {recommendations: recommendationsJSON} = localizedJson;
    const {cardLabels} = recommendationsJSON;
    const recs = recommendations[clubType];
    const tableStyles = 'recommendationTable';

    return (
      <div className={styles.recommendationCards}>
        {recs.map((rec, index) => {
          if (!rec) {
            return null;
          }
          const activeName =
            index === 0 ? firstActiveSectionName : secondActiveSectionName;
          const clickHandler =
            index === 0
              ? this.setFirstActiveSectionName
              : this.setSecondActiveSectionName;
          const activeSection = sections[activeName];

          const imgSrc = get(rec, activeSection.imgSrc);
          const title = rec.title || (rec.model && rec.model.title);
          const isPrimary = index === 0;
          const key = rec.id + index.toString();
          const url = rec.url;
          const urlTitle = rec.title;
          let descriptionHtml;
          return (
            <div key={key} className={styles.recommendationCard}>
              {imgSrc && (
                <div className={styles.picture}>
                  <img src={imgSrc} alt={title} />
                </div>
              )}

              <nav className={styles.tabs} role="tablist">
                {Object.keys(sections).map((name, ind) => {
                  const isActive = name === activeName;
                  const activeClass = isActive ? styles.active : '';
                  return (
                    <button
                      type="button"
                      role="tab"
                      aria-selected={isActive}
                      key={`${name}-${ind}`}
                      className={`${styles.link} ${activeClass}`}
                      onClick={() => clickHandler(name)}
                    >
                      <span>{name}</span>
                    </button>
                  );
                })}
              </nav>

              <div className={styles.text}>
                {title && (
                  <p className={`${styles.rec} ${styles.vr_half}`}>
                    <span className={styles.bold}>
                      {recommendationsJSON.cardLabels.pingRecommends}
                    </span>
                  </p>
                )}

                {title && <p className={styles.heading}>{title}</p>}
                <div className={styles.vr_1} />

                <table className={tableStyles}>
                  <tbody>
                    {activeSection.data.map((datum, datumIndex) => {
                      const label = get(
                        recommendationsJSON,
                        datum.labelPath,
                        undefined
                      );
                      let value = get(rec, datum.dataPath, undefined);
                      if (datum.transformer) {
                        value = datum.transformer(value);
                      }

                      const hasLabel = !!label;
                      const hasData = !!value;
                      const hasUrl = url != 'MISSING';
                      const hasUrlTitle = !!urlTitle;
                      const isDescription =
                        label === cardLabels.description ? true : false;
                      const markup = {__html: value};

                      // TODO: Eventually remove this dangerous setting. Right now it's necessary because the data has html entities in it.
                      if (
                        hasLabel &&
                        hasData &&
                        isDescription &&
                        hasUrl &&
                        hasUrlTitle
                      ) {
                        descriptionHtml = (
                          <>
                            <br />
                            <p
                              className={styles.tabularText}
                              key={datum.dataPath}
                            >
                              <span className={styles.datum}>{label}: </span>
                            </p>
                            <p className={styles.tabularText}>
                              <span
                                className={styles.value}
                                dangerouslySetInnerHTML={markup}
                              />
                            </p>
                            <br />
                            <a
                              href={url}
                              type="button"
                              className={'btn btn_primary'}
                              target="_blank"
                            >
                              {urlTitle}
                            </a>
                          </>
                        );
                      } else if (hasLabel && hasData && isDescription) {
                        descriptionHtml = (
                          <>
                            <br />
                            <p
                              className={styles.tabularText}
                              key={datum.dataPath}
                            >
                              <span className={styles.datum}>{label}: </span>
                            </p>
                            <p className={styles.tabularText}>
                              <span
                                className={styles.value}
                                dangerouslySetInnerHTML={markup}
                              />
                            </p>
                          </>
                        );
                      } else if (hasLabel && hasData) {
                        return (
                          <tr key={datumIndex}>
                            <td className={styles.tabularText}>
                              <span className={styles.datum}>{label}: </span>
                            </td>
                            <td>
                              <span
                                className={styles.value}
                                dangerouslySetInnerHTML={markup}
                              />
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
                {descriptionHtml}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export {RecommendationLongCard as Unconnected};
export default connect(mapStateToProps)(RecommendationLongCard);
