import styles from './IronPreference.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadioInput from '../../../../../SharedComponents/Forms/TextRadio';
import {handicapValue} from '../../../../Redux/Selectors/sharedSelectors';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  questions: any;
  labels: any;
  handicap: string;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    questions: (state.nFlight.localizedJson!.irons as any).questions,
    initialValues: fullProfileSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    handicap: handicapValue(state),
  };
};

let IronPreference: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
  } = props;

  const {designPreference} = questions;

  const handicap = parseInt(props.handicap, 10);
  const defaultValue = handicap && handicap < 20 ? 'precision' : 'distance';

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="ironProfile">
        <fieldset>
          <FormHeader
            isComplete={isComplete}
            header={labels.forms.modelPreference}
          />
          <div className={layout.vr_4} />
          <TextRadioInput
            label={designPreference.label}
            name="designPreference"
            options={designPreference.options}
            defaultValue={defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={labels.helpText}
            hideBottomMargin={true}
          />
          <div className={`${styles.helpTexts} ${layout.vr_6}`}>
            {designPreference.options.map(opt => (
              <p key={opt.value}>{opt.description}</p>
            ))}
          </div>
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

IronPreference = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(IronPreference);

IronPreference = connect(mapStateToProps)(IronPreference);

export default IronPreference;
