import get from 'lodash.get';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  generalInformationSelector,
  gripSizeSelector,
  wristToFloorInInchesSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
} from './sharedSelectors';
import {
  sevenIronCarryDistanceInYardsSelector,
  setLayoutProgress,
} from './setLayoutSelectors';

const ironPreferenceFormValue = (state: IStore) =>
  get(state, `${FORM_ROOT}.ironProfile.designPreference`);

const ironPreferenceProfileValue = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.ironProfile.designPreference`);

export const ironPreferenceSelector = createSelector(
  ironPreferenceFormValue,
  ironPreferenceProfileValue,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const ironShaftPreferenceFormValue = (state: IStore) =>
  get(state, `${FORM_ROOT}.ironProfile.shaftMaterialPreference`);

export const ironShaftPreferenceProfileValue = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.ironProfile.shaftMaterialPreference`);

export const ironShaftSelector = createSelector(
  ironShaftPreferenceFormValue,
  ironShaftPreferenceProfileValue,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const ironShaftFormCompleteSelector = createSelector(
  ironShaftSelector,
  sevenIronCarryDistanceInYardsSelector,
  (shaft, sic) => shaft && sic
);

export const ironsRecommendationCompleteSelector = createSelector(
  ironPreferenceSelector,
  wristToFloorInInchesSelector,
  sevenIronCarryDistanceInYardsSelector,
  ironShaftSelector,
  generalInformationSelector,
  gripSizeSelector,
  (preference, wrist, sic, shaft, general, grip) =>
    preference && wrist && sic && shaft && general && grip
);

const onlyIronsProgressSelector = createSelector(
  ironPreferenceSelector,
  ironShaftSelector,
  wristToFloorInInchesSelector,
  (ironPreferenceSelector, ironShaftSelector, wristToFloorInInchesSelector) =>
    [
      ironPreferenceSelector,
      ironShaftSelector,
      wristToFloorInInchesSelector,
    ].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);

export const ironsProgressSelector = createSelector(
  onlyIronsProgressSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
  setLayoutProgress,
  (
    onlyIronsProgressSelector,
    generalInformationProgress,
    gripSizeSelectorProgress,
    setLayoutProgress
  ) => {
    return [
      onlyIronsProgressSelector,
      generalInformationProgress,
      gripSizeSelectorProgress,
      setLayoutProgress,
    ].reduce(
      (acc, selector) => {
        acc.questions += selector.questions;
        acc.complete += selector.complete;
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
