export default {
  male: {
    fingerLength: {
      small: 3,
      medium: 3.25,
      mediumLarge: 3.5,
      large: 3.5,
      xl: 3.75,
      xxl: 4,
    },
    handLength: {
      small: 7,
      medium: 7.25,
      mediumLarge: 7.5,
      large: 7.5,
      xl: 7.75,
      xxl: 8.25,
    },
  },
  female: {
    fingerLength: {
      small: 2.75,
      medium: 3,
      mediumLarge: 3.25,
      large: 3.25,
      xl: 3.5,
      xxl: 3.75,
    },
    handLength: {
      small: 6.25,
      medium: 6.5,
      mediumLarge: 6.75,
      large: 6.75,
      xl: 7.25,
      xxl: 7.5,
    },
  },
  other: {
    fingerLength: {
      small: 3,
      medium: 3.25,
      mediumLarge: 3.5,
      large: 3.5,
      xl: 3.75,
      xxl: 4,
    },
    handLength: {
      small: 7,
      medium: 7.25,
      mediumLarge: 7.5,
      large: 7.5,
      xl: 7.75,
      xxl: 8.25,
    },
  },
};
