import WebfitSupportedUnitsEnum from './WebfitSupportedUnits';

/*
    // Returned Api Data Sample
    {
      ProfileId: '',
      FullBagJson: {},
      BagRecommendation: {
        drivers: [
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
        fairways: [
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
        hybrids: [
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
        irons: [
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
        wedges: []
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
        putters: [
          { ...primaryRecommendation },
          { ...atlernativeRecommednation },
        ],
      },
      CreatedAt: '',
      UpdatedAt: '',
    },
 */

import RecommendationModel from './RecommendationModel';

type DataObject = {
  [key: string]: any;
};

export default class ProfileModel {
  public readonly ProfileId: string;
  public readonly BagRecommendation: RecommendationModel;
  public readonly FullBagJson: DataObject;
  public readonly CreatedAt: string;
  public readonly UpdatedAt: string;

  constructor(data) {
    this.ProfileId = data.ProfileId || '';
    this.BagRecommendation = data.BagRecommendation || {};
    this.FullBagJson = data.FullBagJson || {};
    this.CreatedAt = data.CreatedAt || '';
    this.UpdatedAt = data.UpdatedAt || '';
  }

  setUnitType(unitType: WebfitSupportedUnitsEnum) {
    this.FullBagJson.preferredUnitType = unitType;
  }
}
