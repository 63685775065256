import get from 'lodash.get';
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import WebfitSupportedUnits from '../Models/WebfitSupportedUnits';

export const FORM_ROOT = 'form.profile.values';
export const PROFILE_ROOT = 'nFlight.profile.FullBagJson';

/* THE FULL PROFILE */
const formValues = (state: IStore) => get(state, `${FORM_ROOT}`, {});

export const savedProfileSelector = (state: IStore) =>
  get(state, `${PROFILE_ROOT}`, {});

export const fullProfileSelector = createSelector(
  savedProfileSelector,
  formValues,
  /* NOTE: formValues must merge onto savedProfile,
     if its reversed, then updated form values will never get sent to API.
  */
  (savedProfile, formValues) => merge(cloneDeep(savedProfile), formValues)
);

export const previousFullProfileSelector = (state: IStore) =>
  get(state, 'nFlight.previousRequestPayload', '');

export const profileDataSelector = (state: IStore) =>
  get(state, 'nFlight.profile', '');

/* BUSINESS REGION */
export const environmentDataSelector = (state: IStore) =>
  get(state, 'nFlight.environmentData', '');

export const msalInstanceCall = (state: IStore) =>
  get(state, 'nFlight.msalInstance', null);

/* PING NATION MODEL SELECTORS */
export const authB2CSelector = (state: IStore) =>
  get(state, 'nFlight.authB2C', {});

export const pingNationSelector = (state: IStore) =>
  get(state, 'nFlight.pingNationProfile', {});

/* UNIT TYPE */
const unitType = (state: IStore) =>
  get(state, 'nFlight.unitType', WebfitSupportedUnits.Imperial);

const preferredUnitType = (state: IStore) =>
  get(
    state,
    `${PROFILE_ROOT}.preferredUnitType`,
    WebfitSupportedUnits.Imperial
  );

export const unitTypeSelector = createSelector(
  preferredUnitType,
  unitType,
  // should return preferred type first
  (preferredUnitType, unitType) => preferredUnitType || unitType
);

/* GENERAL INFORMATION */

/* Data Model in Redux Store */
// golferProfile: {
//    gender: 'male',
//    dexterity: 'right',
//    handicap: 15,
//    heightInInches: 75,
//    ...(other values in gripSizeSelector),
//    ...(other values in setLayoutSelectors.ts),
// }

const genderForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.gender`);

const genderProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.gender`);

export const genderSelector = createSelector(
  genderForm,
  genderProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const genderSelectorValue = createSelector(
  genderForm,
  genderProfile,
  (formValue, profileValue) => formValue || profileValue
);

const dexterityForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.dexterity`);

const dexterityProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.dexterity`);

export const dexteritySelector = createSelector(
  dexterityForm,
  dexterityProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const dexterityValue = createSelector(
  dexterityForm,
  dexterityProfile,
  (formValue, profileValue) => formValue || profileValue
);

const handicapForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.handicap`);

const handicapProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.handicap`);

const handicapSelector = createSelector(
  handicapForm,
  handicapProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const handicapValue = createSelector(
  handicapForm,
  handicapProfile,
  (formValue, profileValue) => formValue || profileValue
);

const averageScoreForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.averageScore`);

const averageScoreProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.averageScore`);

const averageScoreSelector = createSelector(
  averageScoreForm,
  averageScoreProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const averageScoreValue = createSelector(
  averageScoreForm,
  averageScoreProfile,
  (formValue, profileValue) => formValue || profileValue
);

const heightInInchesForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.heightInInches`);

const heightInInchesProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.heightInInches`);

export const heightInInchesSelector = createSelector(
  heightInInchesForm,
  heightInInchesProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const generalInformationSelector = createSelector(
  genderSelector,
  dexteritySelector,
  handicapSelector,
  averageScoreSelector,
  heightInInchesSelector,
  (gender, dexterity, handicap, avgScore, heightInInches) =>
    gender && dexterity && (handicap || avgScore) && heightInInches
);

export const generalInformationProgress = createSelector(
  genderSelector,
  dexteritySelector,
  handicapSelector,
  averageScoreSelector,
  heightInInchesSelector,
  (gender, dexterity, handicap, averageScore, height) => {
    const handicapOrScore = handicap || averageScore;
    return [gender, dexterity, handicapOrScore, height].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);

/* GRIP SIZING */

/* Data Model in Redux Store */
// golferProfile: {
//    handLengthInInches: 7.5,
//    fingerLengthInInches: 4.0,
//    handSize: 'medium',
//    wristToFloorInInches: 33,
//    ...(other values in generalInformationSelector),
//    ...(other values in setLayoutSelectors.ts),
// }

const fingerLengthInInchesForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.fingerLengthInInches`);

const fingerLengthInInchesProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.fingerLengthInInches`);

const fingerLengthInInchesSelector = createSelector(
  fingerLengthInInchesForm,
  fingerLengthInInchesProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const handLengthInInchesForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.handLengthInInches`);

const handLengthInInchesProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.handLengthInInches`);

const handLengthInInchesSelector = createSelector(
  handLengthInInchesForm,
  handLengthInInchesProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const gloveSizeForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.handSize`);

const gloveSizeProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.handSize`);

const gloveSizeSelector = createSelector(
  gloveSizeForm,
  gloveSizeProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const wristToFloorInInchesForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.wristToFloorInInches`);

const wristToFloorInInchesProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.wristToFloorInInches`);

export const wristToFloorInInchesSelector = createSelector(
  wristToFloorInInchesForm,
  wristToFloorInInchesProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const gripSizeSelector = createSelector(
  fingerLengthInInchesSelector,
  handLengthInInchesSelector,
  gloveSizeSelector,
  (longestFinger, handLength, handSize) => {
    const measurements = longestFinger && handLength;
    const measurementsOrGloveSize = measurements || handSize;
    return !!measurementsOrGloveSize;
  }
);

export const gripSizeSelectorProgress = createSelector(
  fingerLengthInInchesSelector,
  handLengthInInchesSelector,
  gloveSizeSelector,
  (longestFinger, handLength, handSize) => {
    const measurements = longestFinger && handLength;
    const measurementsOrGloveSize = measurements || handSize;
    return [measurementsOrGloveSize].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);

export const puttersGeneralInformationSelector = createSelector(
  genderSelector,
  dexteritySelector,
  heightInInchesSelector,
  wristToFloorInInchesSelector,
  (gender, dexterity, height, wrist) => gender && dexterity && height && wrist
);

export const puttersGeneralInformationProgress = createSelector(
  genderSelector,
  dexteritySelector,
  heightInInchesSelector,
  handicapSelector,
  averageScoreSelector,
  wristToFloorInInchesSelector,
  (gender, dexterity, height, handicap, averageScore, wrist) => {
    const handicapOrScore = handicap || averageScore;
    return [gender, dexterity, height, handicapOrScore, wrist].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
