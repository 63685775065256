import * as React from 'react';

const MyBagRecommendationPDF = props => {
  const {
    recommendations,
  } = props;

  const [recObjToArray, setRecObjToArray] = React.useState<any>();

  React.useEffect(() => {
    if (recommendations.setLayout) {
      const recToArray = Object.entries(recommendations.setLayout);
      setRecObjToArray(recToArray);
    }
  }, [recommendations]);
  return null;
};

export default MyBagRecommendationPDF;
