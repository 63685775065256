import styles from './ModelPreference.module.scss';

import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
  units: any;
  isRequestingPutters: boolean;
  isRequestingPuttersError: boolean;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.putter as any).questions,
  units: state.nFlight.localizedJson!.units,
  isRequestingPutters: state.nFlight.isRequestingPutters,
  isRequestingPuttersError: state.nFlight.isRequestingPuttersError,
  recommendations: state.nFlight.recommendations,
});

let ModelPreference: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
    recommendations,
  } = props;

  const {model} = questions;
  let modelOptions;

  const {putters} = recommendations;

  modelOptions =
    putters &&
    putters.map(suggestion => ({
      altText: suggestion.title,
      imgSrc: suggestion.pdfImage,
      text: suggestion.modelName,
      value: suggestion.id,
    }));

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="putterProfile">
        <FormHeader isComplete={isComplete} header={model.header} />
        <div className={styles.vr_4} />
        <PictorialRadioInput
          label={model.label}
          name="modelPreference"
          defaultValueLabel={labels.whyThese}
          defaultValueTooltipText={model.helpText}
          options={modelOptions}
          view="tiled"
          defaultHelpTextVisibility={model.defaultHelpTextVisibility}
        />
        <StepNavigation
          nextBtnText={nextBtnText}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          formHandlesNext={true}
        />
      </FormSection>
    </form>
  );
};

ModelPreference = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(ModelPreference);

ModelPreference = connect(mapStateToProps)(ModelPreference);

export default ModelPreference;
