import styles from './GripSizingForm.module.scss';
import sharedInputStyles from '../FormInputs.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import handFigure from '../../../../../Assets/Illustrations/Hand_Figure.svg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection, change} from 'redux-form';
import GLOVE_SIZE_MAP from './gloveSizeMap';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import FormHeader from '../FormHeader';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {inchesToCm} from '../../../../../util/unitConversion';
import {
  fullProfileSelector,
  unitTypeSelector,
  genderSelectorValue,
} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: () => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  unitType: WebfitSupportedUnitsEnum;
  questions: any;
  initialValues: any;
  labels: any;
  units: any;
  profileGender: string;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  unitType: unitTypeSelector(state),
  questions: (state.nFlight.localizedJson!.profile as any).questions,
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  units: state.nFlight.localizedJson!.units,
  profileGender: genderSelectorValue(state),
});

let GripSizingForm: any = (props: IProps & IStateToProps & IDispatchProp) => {
  const {
    dispatch,
    handleSubmit,
    isComplete,
    unitType,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
    units,
    profileGender,
  } = props;
  const {
    fingerLength,
    handSize,
    handLength,
    handMeasurementOrGloveSize,
  } = questions;

  const [showGloveSize, setShowGloveSize] = React.useState(true);

  const genderForDefaultValues = profileGender || 'male';

  const gloveSizeOnChange = (event, handSize: number) => {
    const handLengthInInches =
      GLOVE_SIZE_MAP[genderForDefaultValues].handLength[handSize];
    const fingerLengthInInches =
      GLOVE_SIZE_MAP[genderForDefaultValues].fingerLength[handSize];
    dispatch(
      change('profile', 'golferProfile.handLengthInInches', handLengthInInches)
    );
    dispatch(
      change(
        'profile',
        'golferProfile.fingerLengthInInches',
        fingerLengthInInches
      )
    );
  };

  const inchesValueLabel =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? inches => `${inches}"`
      : inches => `${inchesToCm(inches)} ${units.centimeters.abbrev}`;

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="golferProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header="Grip" />
          <div className={layout.vr_4} />
          <div className={styles.heading}>
            <img src={handFigure} />
          </div>
          <div className={layout.vr_4} />
          <h3 className={styles.heading}>{handMeasurementOrGloveSize.label}</h3>
          <div className={layout.vr_2} />
          <div className={styles.smallText}>
            {handMeasurementOrGloveSize.helpText}
          </div>
          <div className={styles.vr_2} />
          <div className={styles.inputWrapper}>
            <button
              className={styles.input}
              type="button"
              onClick={() => setShowGloveSize(false)}
            >
              <div
                className={`${styles.label} ${styles.first} ${
                  !showGloveSize ? styles.selected : ''
                }`}
              >
                {handMeasurementOrGloveSize.btn1}
              </div>
            </button>
            <button
              className={styles.input}
              type="button"
              onClick={() => setShowGloveSize(true)}
            >
              <div
                className={`${styles.label} ${styles.last} ${
                  showGloveSize ? styles.selected : ''
                }`}
              >
                {handMeasurementOrGloveSize.btn2}
              </div>
            </button>
          </div>
          <div className={styles.vr_2} />
          {!showGloveSize && (
            <>
              <Range
                name="handLengthInInches"
                label={handLength.label}
                min={5.5}
                max={9}
                step={0.25}
                getValueLabel={inchesValueLabel}
                defaultValue={
                  handLength.defaultValue[WebfitSupportedUnitsEnum.Imperial]
                }
                defaultValueLabel={labels.whyThisMatters}
                defaultValueTooltipText={handLength.helpText}
                screenReaderMaximumLabel={
                  handLength.screenReaderMaximumLabel[unitType]
                }
                screenReaderMinimumLabel={
                  handLength.screenReaderMinimumLabel[unitType]
                }
                screenReaderUnitLabel={
                  unitType === WebfitSupportedUnitsEnum.Imperial
                    ? 'inches'
                    : 'centimeters'
                }
              />
              <div className={styles.vr_1}></div>
              <Range
                name="fingerLengthInInches"
                label={fingerLength.label}
                min={2}
                max={4.5}
                step={0.25}
                getValueLabel={inchesValueLabel}
                defaultValue={
                  fingerLength.defaultValue[WebfitSupportedUnitsEnum.Imperial]
                }
                defaultValueLabel={labels.dontKnow}
                defaultValueTooltipText={fingerLength.tooltip}
                screenReaderMaximumLabel={
                  fingerLength.screenReaderMaximumLabel[unitType]
                }
                screenReaderMinimumLabel={
                  fingerLength.screenReaderMinimumLabel[unitType]
                }
                screenReaderUnitLabel={
                  unitType === WebfitSupportedUnitsEnum.Imperial
                    ? 'inches'
                    : 'centimeters'
                }
              />
            </>
          )}
          {showGloveSize && (
            <>
              <TextRadio
                label={handSize.label}
                name="handSize"
                defaultValue={handSize.defaultValue}
                defaultValueLabel={labels.dontKnow}
                defaultValueTooltipText={handSize.helpText}
                options={handSize.options}
                onChange={gloveSizeOnChange}
              />
            </>
          )}
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

GripSizingForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(GripSizingForm);

GripSizingForm = connect(mapStateToProps)(GripSizingForm);

export default GripSizingForm;
