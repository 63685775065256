import styles from './TextRadio.module.scss';
import sharedInputStyles from './FormInputs.module.scss';
import * as React from 'react';
import {Field} from 'redux-form';
import FieldErrorDisplay from './FieldErrorDisplay';
import FieldHelpDisplay from './FieldHelpDisplay';

interface IOption {
  text: string;
  value: string;
  description?: string;
}

interface IProps {
  defaultValue?: string | number;
  defaultValueLabel?: string;
  defaultValueTooltipText?: string;
  label: string;
  name: string;
  options: IOption[];
  hideBottomMargin?: boolean;
  onChange?: any;
}

const required = value => (value ? undefined : 'Required');

export const TextRadioInput = (props: IProps): JSX.Element => {
  const {
    label,
    name,
    options,
    defaultValue,
    defaultValueLabel,
    defaultValueTooltipText,
    hideBottomMargin,
    onChange,
  } = props;

  return (
    <>
      <div className="input form-group">
        <fieldset>
          <legend className={sharedInputStyles.label}>{label}</legend>
          <div className={styles.vr_1} />

          {/* This is an instance of the field only to render errors.
            This works best separate from other inputs to ensure it always
            displays if there is an error. */}
          <Field
            className={sharedInputStyles.vr_1}
            component={FieldErrorDisplay}
            label={label}
            name={name}
          />

          {(defaultValue || defaultValueTooltipText) && (
            <Field
              className={sharedInputStyles.vr_2}
              component={FieldHelpDisplay}
              defaultValue={defaultValue as any}
              defaultValueLabel={defaultValueLabel}
              defaultValueTooltipText={defaultValueTooltipText}
              name={name}
              id={`${name}-fieldHelpDisplay`}
            />
          )}

          <div className={sharedInputStyles.vr_2} />
          <div className={styles.inputWrapper}>
            {options.map((option, index) => {
              let labelClass = styles.label;
              if (index === 0) {
                labelClass = `${styles.label} ${styles.first}`;
              }
              if (index === options.length - 1) {
                labelClass = `${styles.label} ${styles.last}`;
              }
              return (
                <label key={option.value} className={styles.input}>
                  <Field
                    name={name}
                    component="input"
                    type="radio"
                    className={sharedInputStyles.srOnly}
                    value={option.value}
                    validate={[required]}
                    onChange={() => onChange && onChange(option.value)}
                  />
                  <p className={labelClass}>{option.text}</p>
                </label>
              );
            })}
          </div>
          {!hideBottomMargin && <div className={sharedInputStyles.vr_6} />}
        </fieldset>
      </div>
    </>
  );
};

export default TextRadioInput;
