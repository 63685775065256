import sharedInputStyles from '../FormInputs.module.scss';
import caretDown from '../../../Assets/Icons/Caret Down.svg';
import caretUp from '../../../Assets/Icons/Caret Up.svg';

import * as React from 'react';

interface IProps {
  meta: {[key: string]: any};
  input: {[key: string]: any};
  label: string;
  defaultValue: string | number;
  defaultValueLabel: string;
  defaultValueTooltipText: string;
  id: string;
  defaultHelpTextVisibility: boolean;
}

export const FieldHelpDisplay = (props: IProps): JSX.Element => {
  const {
    defaultValue,
    defaultValueLabel,
    defaultValueTooltipText,
    id,
    input,
    defaultHelpTextVisibility,
  } = props;

  const [showHelp, setShowHelp] = React.useState(defaultHelpTextVisibility);
  const displayClass = showHelp ? sharedInputStyles.showing : '';
  const chevron = showHelp ? caretUp : caretDown;

  return (
    <div className={sharedInputStyles.helpTextArea}>
      <button
        onClick={() => setShowHelp(!showHelp)}
        className={sharedInputStyles.link}
        type="button"
        aria-controls={id}
        aria-expanded={showHelp}
      >
        <span>{defaultValueLabel}</span>
        <img className={sharedInputStyles.chevron} src={chevron} alt="" />
      </button>
      <div className={sharedInputStyles.vr_1} />
      <div
        id={id}
        role="region"
        className={`${sharedInputStyles.help} ${displayClass}`}
      >
        <p className={sharedInputStyles.smallText}>{defaultValueTooltipText}</p>
        <div className={sharedInputStyles.vr_2} />
        {/* must be type="button" or form will submit*/}
        {defaultValue && (
          <button
            type="button"
            className="btn"
            onClick={() => input.onChange(defaultValue)}
          >
            Set value for me
          </button>
        )}
        <div className={sharedInputStyles.vr_1} />
      </div>
    </div>
  );
};

export default FieldHelpDisplay;
