/* istanbul ignore file */
export default class HttpErrorResponseModel {
  public status: number | undefined;
  public responseBody: any | undefined;

  constructor(payload) {
    this.status = payload.status;
    this.responseBody = payload.responseBody;
  }
}
