import styles from './SectionSteps.module.scss';

import * as React from 'react';
import noop from 'lodash.noop';
import {ISectionStep} from '../../SectionNavigator';

interface IProps {
  activeSectionIndex: number;
  activeSectionProgressCalculator?: (logicState: any) => any;
  activeStepIndex: number;
  logicState: {
    [key: string]: any;
  };
  onNavigationClick: (navigationUpdate: any) => void;
  steps: ISectionStep[];
  StepSide: any;
  showStepSide?: boolean;
}

export default class SectionSteps extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    activeStepIndex: 0,
  };

  public render(): JSX.Element {
    const {
      activeSectionIndex,
      activeSectionProgressCalculator,
      activeStepIndex,
      logicState,
      onNavigationClick,
      steps,
      StepSide,
      showStepSide,
    } = this.props;

    const stepsByCompletion = steps.map(step => step.isComplete(logicState));

    const firstIncompleteStep = stepsByCompletion.findIndex(
      completed => completed === false
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <ol className={styles.linkList}>
            {steps.map((step, index) => {
              let onClick = noop;
              let Klass = styles.inactive;
              const isComplete = stepsByCompletion[index];
              const isFirstStep = index === 0;
              const isFirstIncompleteStep = index === firstIncompleteStep;
              const isNavigable =
                isFirstStep || isFirstIncompleteStep || isComplete;
              const isActive = activeStepIndex === index;

              if (isNavigable) {
                Klass = styles.navigable;
                const navObject = {
                  activeSectionIndex,
                  activeStepIndex: index,
                };
                onClick = () => onNavigationClick(navObject);
              }
              if (isActive) {
                Klass = styles.active;
              }

              return (
                <li className={`${Klass}`} key={step.name}>
                  <button
                    type="button"
                    onClick={onClick}
                    disabled={!isNavigable}
                  >
                    <span>{(index + 1).toString()}</span>
                    {step.name}
                  </button>
                </li>
              );
            })}
          </ol>
          {StepSide && showStepSide !== false && (
            <div className={styles.stepSide}>
              <StepSide completionSelector={activeSectionProgressCalculator} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
