import styles from './RecommendationCards.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import isEqual from 'lodash.isequal';
import get from 'lodash.get';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';

interface IProps {
  clubType: string;
  singularclubType: string;
  tabularKeys: {
    labelPath: string;
    dataPath: string;
    transformer?: (val: any) => any;
  }[];
  id?: string;
  forceUpdate?: boolean;
}
interface IState {
  prevPropsRecommendation: any;
  recommendationChanged: boolean;
}
interface IStateToProps {
  localizedJson: any;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  recommendations: state.nFlight.recommendations,
});

class RecommendationCards extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  state = {
    prevPropsRecommendation: null,
    recommendationChanged: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {clubType, recommendations} = props;
    const {prevPropsRecommendation} = state;

    if (!prevPropsRecommendation) {
      return {
        prevPropsRecommendation: recommendations[clubType],
        recommendationChanged: false,
      };
    }

    const recommendationChanged = !isEqual(
      recommendations[clubType],
      prevPropsRecommendation
    );

    return {
      prevPropsRecommendation: recommendations[clubType],
      recommendationChanged,
    };
  }

  public render(): null | JSX.Element {
    const {
      clubType,
      singularclubType,
      forceUpdate,
      id,
      localizedJson,
      recommendations,
      tabularKeys,
    } = this.props;
    const {recommendationChanged} = this.state;

    if (
      !recommendations ||
      !recommendations[clubType] ||
      !recommendations[clubType][0]
    ) {
      return null;
    }

    const {recommendations: recommendationsJSON} = localizedJson;
    let recs = recommendations[clubType];
    const updatedClass =
      forceUpdate || recommendationChanged ? styles.updated : styles.notUpdated;
    return (
      <div id={id} className={styles.recommendationCards}>
        {recs.map((rec, index) => {
          if (!rec) {
            return null;
          }

          const imgSrc = rec.pdfImage || (rec.model && rec.model.pdfImage);
          const title = rec.title || (rec.model && rec.model.title);
          const isPrimary = index === 0;
          const cardStyle = isPrimary ? styles.primary : styles.alt;

          return (
            <div
              key={rec.id}
              className={`${styles.recommendationCard} ${cardStyle} ${updatedClass}`}
            >
              <div className={styles.header}>
                {imgSrc && (
                  <div className={`${styles.picture} ${styles.vr_1}`}>
                    <img src={imgSrc} alt={title} />
                  </div>
                )}
                <div>
                  {title && (
                    <p className={styles.vr_1}>
                      {/* NOTE: The spaces between span tags are important here */}
                      <span className={styles.rec}>
                        <span className={styles.bold}>
                          {isPrimary && recommendationsJSON.cardLabels.top}
                          {!isPrimary &&
                            recommendationsJSON.cardLabels.alternate}
                        </span>{' '}
                        <span className={styles.bold}>
                          {clubType.charAt(0).toUpperCase()}
                          {clubType === 'chipr'
                            ? clubType
                                .slice(1, clubType.length - 1)
                                .concat(
                                  clubType
                                    .slice(clubType.length - 1)
                                    .toUpperCase()
                                )
                            : singularclubType === 'driver' ||
                              singularclubType === 'putter'
                            ? singularclubType.slice(1)
                            : clubType.slice(1)}
                        </span>{' '}
                        <span>
                          {recommendationsJSON.cardLabels.recommendation}
                        </span>
                      </span>
                    </p>
                  )}
                  {title && <p className={styles.textHeading}>{title}</p>}
                </div>
              </div>
              <div className={styles.table}>
                {tabularKeys.map(datum => {
                  const label = get(
                    recommendationsJSON,
                    datum.labelPath,
                    undefined
                  );
                  let value = get(rec, datum.dataPath, undefined);
                  if (datum.transformer) {
                    value = datum.transformer(value);
                  }
                  if (value && typeof value === 'number') {
                    value = Math.floor(value);
                  }
                  if (value && typeof value !== 'string') {
                    value = value.toString();
                  }
                  const hasLabel = !!label;
                  const hasData = !!value;
                  if (hasLabel && hasData) {
                    return (
                      <div className={styles.tableEntry} key={datum.dataPath}>
                        <p className={styles.datum}>{label}</p>
                        <p className={styles.value}>{value}</p>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export {RecommendationCards as Unconnected};
export default connect(mapStateToProps)(RecommendationCards);
