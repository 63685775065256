import styles from './StepNavigation.module.scss';

import * as React from 'react';
import noop from 'lodash.noop';

interface IProps {
  nextBtnText?: string;
  onNextClick?: (arg: any) => void;
  onPreviousClick?: () => void;
  previousBtnText?: string;
  formHandlesNext: boolean;
}

export default class StepNavigation extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      nextBtnText,
      onNextClick,
      onPreviousClick,
      previousBtnText,
      formHandlesNext,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <hr />
        <div className={styles.buttons}>
          {previousBtnText && (
            <button
              type="button"
              className={`btn btn_primary ${styles.btnWidth} ${styles.btnBack}`}
              onClick={onPreviousClick}
            >
              {previousBtnText}
            </button>
          )}
          {nextBtnText && (
            <button
              type="submit"
              className={`btn btn_primary ${styles.btnWidth}`}
              onClick={formHandlesNext ? noop : onNextClick}
            >
              {nextBtnText}
            </button>
          )}
        </div>
      </div>
    );
  }
}
