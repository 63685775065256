import get from 'lodash.get';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  generalInformationSelector,
} from './sharedSelectors';

/* Data Model in Redux Store */
// golferProfile: {
//   ...(other values in shared selectors),
//   sevenIronCarryDistanceInYards: 160,
//   typicalShotHeight: 'medium',
// }

export const sevenIronCarryDistanceInYardsFormValue = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.sevenIronCarryDistanceInYards`);

export const sevenIronCarryDistanceInYardsProfileValue = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.sevenIronCarryDistanceInYards`);

export const sevenIronCarryDistanceInYardsSelector = createSelector(
  sevenIronCarryDistanceInYardsFormValue,
  sevenIronCarryDistanceInYardsProfileValue,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const typicalShotHeightFormValue = (state: IStore) =>
  get(state, `${FORM_ROOT}.golferProfile.typicalShotHeight`);

const typicalShotHeightProfileValue = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.golferProfile.typicalShotHeight`);

const typicalShotHeightSelector = createSelector(
  typicalShotHeightFormValue,
  typicalShotHeightProfileValue,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const setLayoutSelector = createSelector(
  sevenIronCarryDistanceInYardsSelector,
  typicalShotHeightSelector,
  (carryDistance, shotHeight) => carryDistance && shotHeight
);

export const setLayoutRecommendationCompleteSelector = createSelector(
  setLayoutSelector,
  setLayout => setLayout
);

export const setLayoutProgress = createSelector(
  sevenIronCarryDistanceInYardsSelector,
  typicalShotHeightSelector,
  (sevenIronCarryDistanceInYardsSelector, typicalShotHeightSelector) =>
    [sevenIronCarryDistanceInYardsSelector, typicalShotHeightSelector].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);
