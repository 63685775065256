import styles from './GeneralInformationForm.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import wristToFloorImg from '../../../../../Assets/Illustrations/Lie_Angle@2x.png';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection, change} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {inchesToFeet, inchesToCm} from '../../../../../util/unitConversion';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {
  handicapValue,
  averageScoreValue,
  genderSelectorValue,
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: () => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  labels: any;
  unitType: WebfitSupportedUnitsEnum;
  questions: any;
  initialValues: any;
  handicapValue: string;
  averageScoreValue: string;
  units: any;
  profileGender: string;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    unitType: unitTypeSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    questions: (state.nFlight.localizedJson!.profile as any).questions,
    initialValues: fullProfileSelector(state),
    handicapValue: handicapValue(state),
    averageScoreValue: averageScoreValue(state),
    units: state.nFlight.localizedJson!.units,
    profileGender: genderSelectorValue(state),
  };
};

let GeneralInformationForm: any = (
  props: IProps & IStateToProps & IDispatchProp
) => {
  const {
    dispatch,
    handleSubmit,
    handicapValue,
    averageScoreValue,
    isComplete,
    labels,
    unitType,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    units,
    profileGender,
  } = props;

  const {dexterity, gender, height, wristToFloor} = questions;

  const onGenderChange = gender => {
    if (gender.toLowerCase() === 'female') {
      dispatch(
        change('profile', 'ironProfile.shaftMaterialPreference', 'graphite')
      );
    }
  };

  const heightValueLabel =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? inchesToFeet
      : inches => `${inchesToCm(inches)} ${units.centimeters.abbrev}`;

  const inchesValueLabel =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? inches => `${inches}"`
      : inches => `${inchesToCm(inches)} ${units.centimeters.abbrev}`;

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="golferProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.profile} />
          <div className={layout.vr_4} />
          <TextRadio
            label={gender.label}
            name="gender"
            defaultValue={gender.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={gender.helpText}
            options={gender.options}
            onChange={onGenderChange}
          />
          <TextRadio
            label={dexterity.label}
            name="dexterity"
            defaultValue={dexterity.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={dexterity.helpText}
            options={dexterity.options}
          />
          <Range
            name="heightInInches"
            label={height.label}
            min={height.range[WebfitSupportedUnitsEnum.Imperial].min}
            max={height.range[WebfitSupportedUnitsEnum.Imperial].max}
            step={height.range[WebfitSupportedUnitsEnum.Imperial].step}
            getValueLabel={heightValueLabel}
            defaultValue={
              height.defaultValue[WebfitSupportedUnitsEnum.Imperial]
            }
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={height.helpText}
            screenReaderMaximumLabel={height.screenReaderMaximumLabel[unitType]}
            screenReaderMinimumLabel={height.screenReaderMinimumLabel[unitType]}
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'feet and inches'
                : 'centimeters'
            }
          />
          <div className={layout.vr_1} />
          <div className={styles.wrapper}>
            <img
              className={styles.img}
              src={wristToFloorImg}
              alt={wristToFloor.imageAltText.male}
            />
          </div>
          <div className={layout.vr_4} />
          {/* Note: step of 0.5 can't be in JSON as JSON doesn't support real numbers */}
          <Range
            name="wristToFloorInInches"
            label={wristToFloor.label}
            min={wristToFloor.range.min}
            max={wristToFloor.range.max}
            step={0.5}
            getValueLabel={inchesValueLabel}
            defaultValue={wristToFloor.defaultValue.imperial}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={wristToFloor.helpText}
            screenReaderMaximumLabel={
              wristToFloor.screenReaderMaximumLabel[unitType]
            }
            screenReaderMinimumLabel={
              wristToFloor.screenReaderMinimumLabel[unitType]
            }
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'feet and inches'
                : 'centimeters'
            }
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

GeneralInformationForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
  forceUnregisterOnUnmount: true,
})(GeneralInformationForm);

GeneralInformationForm = connect(mapStateToProps)(GeneralInformationForm);

export default GeneralInformationForm;
