import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import quick from '../../../../../Assets/Illustrations/Transition_Abrupt@2x-100.gif';
import smooth from '../../../../../Assets/Illustrations/Transition_Smooth@2x-100.gif';
import standard from '../../../../../Assets/Illustrations/Transition_Neutral@2x-100.gif';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import Range from '../../../../../SharedComponents/Forms/Range';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import WebfitSupportedUnitsEnum from '../../../../Redux/Models/WebfitSupportedUnits';
import {yardsToMeters} from '../../../../../util/unitConversion';
import {
  fullProfileSelector,
  unitTypeSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  quick,
  smooth,
  standard,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
  labels: any;
}
interface IStateToProps {
  unitType: WebfitSupportedUnitsEnum;
  initialValues: any;
  questions: any;
  labels: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  unitType: unitTypeSelector(state),
  questions: (state.nFlight.localizedJson!.driver as any).questions,
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  units: state.nFlight.localizedJson!.units,
});

let DriverDistanceAndTransitionForm: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    unitType,
    nextBtnText,
    labels,
    units,
  } = props;
  const {averageDistance, transition} = questions;

  const distanceLabelFunction =
    unitType === WebfitSupportedUnitsEnum.Imperial
      ? yds => `${yds}${units.yards.abbrev}`
      : yds => `${yardsToMeters(yds)}${units.meters.abbrev}`;

  const transitionOptions = transition.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="driverProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.shaft} />
          <div className={layout.vr_4} />
          <Range
            name="driverDistanceInYards"
            label={averageDistance.label}
            getValueLabel={distanceLabelFunction}
            min={averageDistance.range[WebfitSupportedUnitsEnum.Imperial].min}
            max={averageDistance.range[WebfitSupportedUnitsEnum.Imperial].max}
            plus={true}
            step={averageDistance.range[WebfitSupportedUnitsEnum.Imperial].step}
            defaultValue={
              averageDistance.defaultValue[WebfitSupportedUnitsEnum.Imperial]
            }
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={averageDistance.helpText}
            screenReaderMaximumLabel={
              averageDistance.screenReaderMaximumLabel[unitType]
            }
            screenReaderMinimumLabel={
              averageDistance.screenReaderMinimumLabel[unitType]
            }
            screenReaderUnitLabel={
              unitType === WebfitSupportedUnitsEnum.Imperial
                ? 'yards'
                : 'meters'
            }
          />
          <PictorialRadioInput
            label={transition.label}
            name="transition"
            defaultValue={transition.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={transition.helpText}
            options={transitionOptions}
            view="stretched"
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

DriverDistanceAndTransitionForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(DriverDistanceAndTransitionForm);

DriverDistanceAndTransitionForm = connect(mapStateToProps)(
  DriverDistanceAndTransitionForm
);

export default DriverDistanceAndTransitionForm;
