import styles from './ShotShapeForm.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import draw from '../../../../../Assets/Illustrations/ShotShape_Draw_1@2x-100.jpg';
import fade from '../../../../../Assets/Illustrations/ShotShape_Fade_1@2x-100.jpg';
import hook from '../../../../../Assets/Illustrations/ShotShape_Hook_1@2x-100.jpg';
import slice from '../../../../../Assets/Illustrations/ShotShape_Slice_1@2x-100.jpg';
import straight from '../../../../../Assets/Illustrations/ShotShape_Straight_1@2x-100.jpg';
import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {
  dexterityValue,
  fullProfileSelector,
} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  draw,
  fade,
  hook,
  slice,
  straight,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  questions: any;
  labels: any;
  dexterity: string;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => {
  return {
    questions: (state.nFlight.localizedJson!.driver as any).questions,
    initialValues: fullProfileSelector(state),
    labels: (state.nFlight.localizedJson!.misc as any).labels,
    dexterity: dexterityValue(state),
  };
};

let CurrentShotShapeForm: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    questions,
    nextBtnText,
    labels,
    dexterity,
  } = props;

  const {currentFlight, desiredFlight} = questions;

  const currentOptions = currentFlight.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  const desiredOptions = desiredFlight.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });
  if (dexterity && dexterity === 'right') {
    desiredOptions[0].description = desiredFlight.rightHandedDrawDescription;
    desiredOptions[2].description = desiredFlight.rightHandedFadeDescription;
  }
  if (dexterity && dexterity === 'left') {
    desiredOptions[0].description = desiredFlight.leftHandedDrawDescription;
    desiredOptions[2].description = desiredFlight.leftHandedFadeDescription;
  }

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="driverProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.shotShape} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={currentFlight.label}
            name="currentFlight"
            options={currentOptions}
            view="scrunched"
          />
          <PictorialRadioInput
            label={desiredFlight.label}
            name="desiredFlight"
            options={desiredOptions}
            view="stretched"
            defaultValueLabel={labels.learnMore}
            defaultValueTooltipText={desiredFlight.helpText}
            defaultHelpTextVisibility={desiredFlight.defaultHelpTextVisibility}
            hideBottomMargin={true}
          />
          <div className={`${styles.helpTexts} ${layout.vr_6}`}>
            {desiredOptions.map(opt => (
              <p key={opt.value}>{opt.description}</p>
            ))}
          </div>
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

CurrentShotShapeForm = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(CurrentShotShapeForm);

CurrentShotShapeForm = connect(mapStateToProps)(CurrentShotShapeForm);

export default CurrentShotShapeForm;
