import get from 'lodash.get';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  generalInformationSelector,
  gripSizeSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
} from './sharedSelectors';
import {setLayoutSelector, setLayoutProgress} from './setLayoutSelectors';

/* Data Model in Redux Store */
// driverProfile: {
//   driverDistanceInYards: 225,
//   currentFlight: 'straight',
//   desiredFlight: 'fade',
//   currentLoft: 7,
//   currentTrajectory: 'high',
//   transition: 'unknown',
// }

const currentFlightForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.currentFlight`);

const currentFlightProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.currentFlight`);

export const currentFlightSelector = createSelector(
  currentFlightForm,
  currentFlightProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const desiredFlightForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.desiredFlight`);

const desiredFlightProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.desiredFlight`);

export const desiredFlightSelector = createSelector(
  desiredFlightForm,
  desiredFlightProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const shotShapeSelector = createSelector(
  currentFlightSelector,
  desiredFlightSelector,
  (current, desired) => !!current && !!desired
);

const currentLoftForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.currentLoft`);

const currentLoftProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.currentLoft`);

const currentLoftSelector = createSelector(
  currentLoftForm,
  currentLoftProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const currentTrajectoryForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.currentTrajectory`);

const currentTrajectoryProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.currentTrajectory`);

const currentTrajectorySelector = createSelector(
  currentTrajectoryForm,
  currentTrajectoryProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const loftAndTrajectorySelector = createSelector(
  currentLoftSelector,
  currentTrajectorySelector,
  (loft, traj) => loft && traj
);

const driverDistanceInYardsForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.driverDistanceInYards`);

const driverDistanceInYardsProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.driverDistanceInYards`);

const driverDistanceInYardsSelector = createSelector(
  driverDistanceInYardsForm,
  driverDistanceInYardsProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const transitionForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.driverProfile.transition`);

const transitionProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.driverProfile.transition`);

const transitionSelector = createSelector(
  transitionForm,
  transitionProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const driverDistanceAndTransitionSelector = createSelector(
  driverDistanceInYardsSelector,
  transitionSelector,
  (carryDist, transition) => carryDist && transition
);

export const driversCompleteSelector = createSelector(
  generalInformationSelector,
  setLayoutSelector,
  shotShapeSelector,
  loftAndTrajectorySelector,
  driverDistanceAndTransitionSelector,
  gripSizeSelector,
  (general, setLayout, shotShape, LandT, DandT, gripSize) =>
    general && setLayout && shotShape && LandT && DandT && gripSize
);

const onlyDriversProgressSelector = createSelector(
  currentFlightSelector,
  desiredFlightSelector,
  currentLoftSelector,
  currentTrajectorySelector,
  driverDistanceInYardsSelector,
  transitionSelector,
  (
    currentFlightSelector,
    desiredFlightSelector,
    currentLoftSelector,
    currentTrajectorySelector,
    driverDistanceInYardsSelector,
    transitionSelector
  ) =>
    [
      currentFlightSelector,
      desiredFlightSelector,
      currentLoftSelector,
      currentTrajectorySelector,
      driverDistanceInYardsSelector,
      transitionSelector,
    ].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);

export const driversProgressSelector = createSelector(
  onlyDriversProgressSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
  setLayoutProgress,
  (
    onlyDriversProgressSelector,
    generalInformationProgress,
    gripSizeSelectorProgress,
    setLayoutProgress
  ) => {
    return [
      onlyDriversProgressSelector,
      generalInformationProgress,
      gripSizeSelectorProgress,
      setLayoutProgress,
    ].reduce(
      (acc, selector) => {
        acc.questions += selector.questions;
        acc.complete += selector.complete;
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
