/*
    // Returned Api Data Sample
    {
      pingUrl: '',
      api: {},
      authData: {},
    },
 */

type ApiObjectModel = {
  url: string;
  getProfiles: string;
};

type AuthorizationDataModel = {
  authority: string;
  clientId: string;
  knownAuthorities: string[];
  status: string;
  type: string;
  loginPath: string;
  logoutUrl: string;
};

export default class EnvironmentDataModel {
  public readonly pingUrl: string = '';
  public readonly api: ApiObjectModel = {
    url: '',
    getProfiles: '',
  };
  public readonly authData: AuthorizationDataModel = {
    authority: '',
    clientId: '',
    knownAuthorities: [],
    status: '',
    type: '',
    loginPath: '',
    logoutUrl: '',
  };

  constructor(data) {
    this.pingUrl = data.pingUrl || {};
    this.api = data.api || {};
    this.authData = data.authData || {};
  }
}
