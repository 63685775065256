import styles from './DivotDepth.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import deep from '../../../../../Assets/Illustrations/Divot_Deep@2x.jpg';
import medium from '../../../../../Assets/Illustrations/Divot_Medium@2x.jpg';
import shallow from '../../../../../Assets/Illustrations/Divot_Shallow@2x.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const divotDepthImgMap = {
  deep,
  medium,
  shallow,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.wedges as any).questions,
});

let DivotDepth: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
  } = props;

  const {divotDepth, mostLoftedWedge, turfConditions} = questions;

  const divotDepthOptions = divotDepth.options.map(option => {
    return {
      ...option,
      imgSrc: divotDepthImgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="wedgeProfile">
        <fieldset>
          <FormHeader
            isComplete={isComplete}
            header={labels.forms.wedgeFitting}
          />
          <div className={layout.vr_4} />
          <TextRadio
            label={mostLoftedWedge.label}
            name="mostLoftedWedge"
            defaultValue={mostLoftedWedge.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={mostLoftedWedge.helpText}
            options={mostLoftedWedge.options}
          />
          <TextRadio
            label={turfConditions.label}
            name="turfConditions"
            defaultValue={turfConditions.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={turfConditions.helpText}
            options={turfConditions.options}
          />
          <PictorialRadioInput
            label={divotDepth.label}
            name="divotDepth"
            defaultValue={divotDepth.defaultValue}
            defaultValueLabel={labels.whyThisMatters}
            defaultValueTooltipText={divotDepth.helpText}
            options={divotDepthOptions}
            hideBottomMargin={true}
            view="stretched"
          />
          <div className={`${styles.helpTexts} ${layout.vr_6}`}>
            {divotDepthOptions.map(opt => (
              <p key={opt.value}>{opt.description}</p>
            ))}
          </div>
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

DivotDepth = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(DivotDepth);

DivotDepth = connect(mapStateToProps)(DivotDepth);

export default DivotDepth;
