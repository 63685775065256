import {AxiosResponse} from 'axios';
import LocalizedJsonResponseModel from './Models/LocalizedJsonResponseModel';
import CountriesJsonResponseModel from './Models/CountriesJsonResponseModel';
import HttpErrorResponseModel from '../../SharedRedux/Models/HttpErrorResponseModel';
import HttpUtility from '../../SharedRedux/HttpUtility';
import AuthB2CModel from './Models/AuthB2CModel';
import ProfileModel from './Models/ProfileModel';
import ProfilesModel from './Models/ProfilesModel';
import EnvironmentDataModel from './Models/EnvironmentDataModel';

export default class NflightEffects {
  private static _http = new HttpUtility();

  public static async fetchData(language: string) {
    try {
      const localizedJson = await import(
        `../../Data/Localization/${language}.json`
      );
      return new LocalizedJsonResponseModel(localizedJson);
    } catch (e) {
      return new HttpErrorResponseModel(e);
    }
  }

  public static async fetchCountries() {
    try {
      const countriesJson = await import(`../../Data/countries.json`);
      return new CountriesJsonResponseModel(countriesJson);
    } catch (e) {
      return new HttpErrorResponseModel(e);
    }
  }

  public static async fetchProfileRecomendation(
    authB2CData: AuthB2CModel,
    environmentData: EnvironmentDataModel,
    profileData: ProfileModel
  ): Promise<ProfileModel | HttpErrorResponseModel> {
    try {
      const {api} = environmentData;
      const {url, getProfiles} = api;
      const endpoint: string = `${url}${getProfiles}/${profileData.ProfileId}`;

      const response:
        | AxiosResponse<any>
        | HttpErrorResponseModel = await NflightEffects._http.get(
        {
          Authorization: `Bearer ${authB2CData.authorizeToken}`,
        },
        endpoint
      );

      return new ProfileModel((response as AxiosResponse<ProfileModel>).data);
    } catch (e) {
      return new HttpErrorResponseModel(e);
    }
  }

  public static async fetchProfile(
    authB2CData: AuthB2CModel,
    environmentData: EnvironmentDataModel
  ): Promise<ProfilesModel | HttpErrorResponseModel> {
    try {
      const {api} = environmentData;
      const {url, getProfiles} = api;
      const endpoint: string = `${url}${getProfiles}`;

      const response:
        | AxiosResponse<any>
        | HttpErrorResponseModel = await NflightEffects._http.get(
        {
          Authorization: `Bearer ${authB2CData.authorizeToken}`,
        },
        endpoint
      );
      return (response as AxiosResponse<any>).data;
    } catch (e) {
      return new HttpErrorResponseModel(e);
    }
  }

  public static async fetchSaveProfile(
    authB2CData: AuthB2CModel,
    environmentData: EnvironmentDataModel,
    profileData: ProfileModel,
    profilePayload: any
  ): Promise<ProfileModel | HttpErrorResponseModel> {
    try {
      const {api} = environmentData;
      const {url, getProfiles} = api;
      const endpoint: string = `${url}${getProfiles}/${profileData.ProfileId}`;

      const response:
        | AxiosResponse<any>
        | HttpErrorResponseModel = await NflightEffects._http.patch(
        {
          Authorization: `Bearer ${authB2CData.authorizeToken}`,
        },
        endpoint,
        profilePayload
      );

      return new ProfileModel((response as AxiosResponse<ProfileModel>).data);
    } catch (e) {
      return new HttpErrorResponseModel(e);
    }
  }
}
