import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import firm from '../../../../../Assets/Illustrations/Putter_Firm_Feel.jpg';
import softv1 from '../../../../../Assets/Illustrations/Putter_Soft_Feel.jpg';
import softv2 from '../../../../../Assets/Illustrations/Putter_Soft_Feel_v2.jpg';
import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const imgMapFeel = {
  firm,
  soft:
    new Date(
      new Date().toLocaleString('en-US', {timeZone: 'America/Phoenix'})
    ) >
    new Date(
      new Date('2021-04-12 07:00:00').toLocaleString('en-US', {
        timeZone: 'America/Phoenix',
      })
    )
      ? softv2
      : softv1,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.putter as any).questions,
  units: state.nFlight.localizedJson!.units,
});

let Feel: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
  } = props;

  const {feel} = questions;

  const feelOptions = feel.options.map(option => {
    return {
      ...option,
      imgSrc: imgMapFeel[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="putterProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={feel.header} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={feel.label}
            name="feelPreference"
            defaultValueLabel={labels.whyThis}
            defaultValueTooltipText={feel.helpText}
            options={feelOptions}
            view="tiled"
            defaultHelpTextVisibility={feel.defaultHelpTextVisibility}
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

Feel = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(Feel);

Feel = connect(mapStateToProps)(Feel);

export default Feel;
