import styles from './Range.module.scss';
import sharedInputStyles from './FormInputs.module.scss';
import * as React from 'react';
import {Field} from 'redux-form';
import FieldErrorDisplay from './FieldErrorDisplay';
import FieldHelpDisplay from './FieldHelpDisplay';

interface IProps {
  label: string;
  getValueLabel?: (value: any) => any;
  defaultValue?: string | number;
  defaultValueLabel?: string;
  defaultValueTooltipText?: string;
  defaultHelpTextVisibility?: boolean;
  max: number;
  min: number;
  name: string;
  step: number;
  onChange?: any;
  notRequired?: boolean;
  hideLabel?: boolean;
  plus?: boolean;
  screenReaderMinimumLabel?: string;
  screenReaderMaximumLabel?: string;
  screenReaderUnitLabel?: string;
}

interface IRangeInputProps {
  input: any;
  max: number;
  min: number;
  name: string;
  step: number;
  screenReaderUnitLabel?: string;
  getValueLabel?: (value: any) => any;
}

const required = value => (value ? undefined : 'Required');

const RangeInput = (props: IRangeInputProps): JSX.Element => {
  const value = props.input.value;
  return (
    <div className={styles.inputField}>
      <input
        type="range"
        className={styles.customRange}
        {...props.input}
        min={props.min}
        max={props.max}
        step={props.step}
        id={props.input.name}
      />
      {value && (
        <output htmlFor={props.input.name}>
          {(props.getValueLabel && props.getValueLabel(value)) || value}
          <span className={sharedInputStyles.srOnly}>
            {props.screenReaderUnitLabel}
          </span>
        </output>
      )}
    </div>
  );
};

const Range = (props: IProps): JSX.Element => {
  const {
    defaultValue,
    defaultValueLabel,
    defaultValueTooltipText,
    defaultHelpTextVisibility,
    label,
    max,
    min,
    name,
    step,
    onChange,
    getValueLabel,
    notRequired,
    hideLabel,
    plus,
    screenReaderMaximumLabel,
    screenReaderMinimumLabel,
    screenReaderUnitLabel,
  } = props;

  const validation = notRequired ? null : [required];

  return (
    <>
      <div className="input form-group">
        {!hideLabel && (
          <>
            <h3 className={sharedInputStyles.label}>{label}</h3>
            <div className={styles.vr_1} />
          </>
        )}

        {/* This is an instance of the field only to render errors.
          This works best separate from other inputs to ensure it always
          displays if there is an error. */}
        <Field
          className={sharedInputStyles.vr_1}
          component={FieldErrorDisplay}
          label={label}
          name={name}
        />

        {(defaultValue || defaultValueTooltipText) && (
          <Field
            className={sharedInputStyles.vr_2}
            component={FieldHelpDisplay}
            defaultValue={defaultValue as any}
            defaultValueLabel={defaultValueLabel}
            defaultValueTooltipText={defaultValueTooltipText}
            defaultHelpTextVisibility={defaultHelpTextVisibility}
            name={name}
            id={`${name}-fieldHelpDisplay`}
          />
        )}

        <div className={sharedInputStyles.vr_4} />
        <Field
          name={name}
          onChange={onChange}
          validate={validation}
          min={min}
          max={max}
          step={step}
          getValueLabel={getValueLabel}
          component={RangeInput}
          screenReaderUnitLabel={screenReaderUnitLabel}
        />
        <div className={styles.ticks}>
          <span className={styles.min}>
            <span className={sharedInputStyles.srOnly}>
              {screenReaderMinimumLabel}
            </span>
            <span aria-hidden="true">
              {getValueLabel ? getValueLabel(min) : min}
            </span>
          </span>
          <div role="presentation" className={styles.line} />
          <span className={styles.max}>
            <span className={sharedInputStyles.srOnly}>
              {screenReaderMaximumLabel}
            </span>
            <span aria-hidden="true">
              {getValueLabel ? getValueLabel(max) : max}{' '}
              {plus === true ? '+' : ''}
            </span>
          </span>
        </div>
        <div className={sharedInputStyles.vr_6} />
      </div>
    </>
  );
};

export default Range;
