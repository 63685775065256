/*
    // Returned Api Data Sample
    {
      countries: [...]
    }
 */

type CountriesBlob =
  | {
      [key: string]: any;
    }
  | undefined;

export default class CountriesJsonResponseModel {
  public readonly countries: CountriesBlob = {};

  constructor(data: Partial<CountriesJsonResponseModel>) {
    this.countries = data.countries;
  }
}
