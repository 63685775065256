import sharedInputStyles from '../FormInputs.module.scss';
import error from '../../../Assets/Icons/Error.svg';

import * as React from 'react';

interface IProps {
  meta: {[key: string]: any};
  input: {[key: string]: any};
  label: string;
}

export const FieldErrorDisplay = (props: IProps): null | JSX.Element => {
  const {meta, label} = props;
  const isError = meta.touched && meta.error;

  if (!isError) {
    return null;
  }

  return (
    <div
      className={`${sharedInputStyles.helpTextArea} ${sharedInputStyles.vr_1}`}
    >
      <p className={sharedInputStyles.errorText}>
        <img src={error} alt="" /> Please specify {label}
      </p>
    </div>
  );
};

export default FieldErrorDisplay;
