import React from 'react';
import styles from './LoadingWheel.module.scss';
import loader from '../../../Assets/Loaders/loader.gif';

class LoadingWheel extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.blur} />
        <div className={styles.view}>
          <div className={styles.loader}>
            <img src={loader} alt="Loading Wheel" />
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingWheel;
