/*
    // Returned Api Data Sample
    {
		  AuthorizeToken: 'authToken'
    }
 */

export default class AuthB2CModel {
  public readonly authorizeToken: string = '';

  constructor(data) {
    this.authorizeToken = data.authorizeToken || '';
  }
}
