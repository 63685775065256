import styles from './WedgesRecommendation.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import NflightActions from '../../../../Redux/NflightAction';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import RecommendationLongCards from '../../Shared/RecommendationLongCards';

interface IProps {
  isComplete?: boolean;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IState {}
interface IStateToProps {
  labels: any;
  recommendations: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  recommendations: state.nFlight.localizedJson!.recommendations,
});

class FairwayRecommendation extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public componentDidMount(): void {
    const {dispatch} = this.props;
    dispatch(NflightActions.isRecommendationPage(true));
  }

  public render(): JSX.Element {
    const {
      isComplete,
      nextBtnText,
      onPreviousClick,
      onNextClick,
      previousBtnText,
      labels,
      recommendations,
    } = this.props;

    const clubLengthTransformer = (lengthDifferential): string => {
      if (lengthDifferential === 0) {
        return recommendations.cardValues.clubLength.zero;
      }
      const sign = lengthDifferential > 0 ? '+' : '';
      return `${sign}${lengthDifferential}"`;
    };

    const colorCodeTransformer = (colorCode): string => {
      const colorCodeMap = {
        2: recommendations.cardValues.colorCodes['2'],
        3: recommendations.cardValues.colorCodes['3'],
        4: recommendations.cardValues.colorCodes['4'],
        5: recommendations.cardValues.colorCodes['5'],
        6: recommendations.cardValues.colorCodes['6'],
        7: recommendations.cardValues.colorCodes['7'],
        8: recommendations.cardValues.colorCodes['8'],
        9: recommendations.cardValues.colorCodes['9'],
        10: recommendations.cardValues.colorCodes['10'],
        11: recommendations.cardValues.colorCodes['11'],
      };
      return colorCodeMap[colorCode] || colorCode.toString();
    };

    const loftsTransformer = (lofts): string => {
      return lofts.map(loft => loft.name.abbreviated).join(', ');
    };

    return (
      <div className={styles.wrapper}>
        <FormHeader
          isComplete={isComplete}
          header={labels.forms.wedgesResults}
        />
        <div className={layout.vr_4} />
        <RecommendationLongCards
          clubType="wedges"
          noRecsMessage={labels.forms.noWedgeResults}
          sections={{
            club: {
              data: [
                {
                  labelPath: 'cardLabels.lofts',
                  dataPath: 'lofts',
                  transformer: loftsTransformer,
                },
                {
                  labelPath: 'cardLabels.colorCode',
                  dataPath: 'colorCode',
                  transformer: colorCodeTransformer,
                },
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: clubLengthTransformer,
                },
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.model', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {
                  labelPath: 'cardLabels.material',
                  dataPath: 'shaft.material',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <RecommendationLongCards
          clubType="chipr"
          noRecsMessage=""
          sections={{
            club: {
              data: [
                {
                  labelPath: 'cardLabels.lofts',
                  dataPath: 'lofts',
                  transformer: loftsTransformer,
                },
                {
                  labelPath: 'cardLabels.colorCode',
                  dataPath: 'colorCode',
                  transformer: colorCodeTransformer,
                },
                {labelPath: 'cardLabels.technology', dataPath: 'technology'},
                {
                  labelPath: 'cardLabels.length',
                  dataPath: 'lengthInMeters',
                  transformer: clubLengthTransformer,
                },
                {labelPath: 'cardLabels.description', dataPath: 'description'},
              ],
              imgSrc: 'heroImage',
            },
            shaft: {
              data: [
                {labelPath: 'cardLabels.model', dataPath: 'shaft.title'},
                {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
                {labelPath: 'cardLabels.weight', dataPath: 'shaft.weight'},
                {
                  labelPath: 'cardLabels.launchAngle',
                  dataPath: 'shaft.launchAngle',
                },
                {
                  labelPath: 'cardLabels.material',
                  dataPath: 'shaft.material',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'shaft.description',
                },
              ],
              imgSrc: 'shaft.heroImage',
            },
            grip: {
              data: [
                {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
                {
                  labelPath: 'cardLabels.gripColorCode',
                  dataPath: 'grip.gripColorCode',
                },
                {
                  labelPath: 'cardLabels.description',
                  dataPath: 'grip.description',
                },
              ],
              imgSrc: 'grip.heroImage',
            },
          }}
        />
        <div className={layout.vr_4} />
        <StepNavigation
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          onNextClick={onNextClick}
          nextBtnText={nextBtnText}
          formHandlesNext={false}
        />
      </div>
    );
  }
}

export {FairwayRecommendation as Unconnected};
export default connect(mapStateToProps)(FairwayRecommendation);
