import styles from './SectionNavigator.module.scss';
import * as React from 'react';
import Navigator from './Components/Navigator';
import SectionSteps from './Components/SectionSteps';
import NotificationModal from '../NotificationModal';
import IDispatchProp from '../../SharedRedux/Models/IDispatchProp';
import IStore from '../../SharedRedux/Models/IStore';
import {connect} from 'react-redux';
import NflightAction from '../../NflightWeb/Redux/NflightAction';
import SegmentAnalytics from '../../util/segmentAnalytics';

export interface ISectionStep {
  name: string;
  contents: any;
  isComplete: (logicState: any) => boolean;
  nextLabel?: string;
  previousLabel?: string;
}

export interface IResponsiveIcons {
  completeAndActive: string;
  completeAndInactive: string;
  incompleteAndActive: string;
  incompleteAndInactive: string;
}

export interface ISection {
  name: string;
  steps: ISectionStep[];
  canRender: (logicState: any) => boolean;
  isComplete: (logicState: any) => boolean;
  showStepSide?: boolean;
  progressCalculator: (logicState: any) => any;
  defaultPreviousLabel: string;
  defaultNextLabel: string;
  canNotRenderMessage?: string | JSX.Element;
  url?: string;
  responsiveIcons: IResponsiveIcons;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
}

interface IProps {
  animateStepChange: boolean;
  Header: any;
  Side?: any;
  StepSide?: any;
  initialSection: number;
  initialStep: number;
  logicState: any;
  scrollToTopOnNavigate: boolean;
  sections: ISection[];
}

interface IState {
  activeSectionIndex: number;
  activeStepIndex: number;
  showModal: boolean;
}

interface IStateToProps {
  localizedJson: any;
  pingNationProfile: any;
}

const mapStateToProps = (state: IStore): IStateToProps => ({
  localizedJson: state.nFlight.localizedJson,
  pingNationProfile: state.nFlight.pingNationProfile,
});

class SectionNavigator extends React.Component<
  IProps & IDispatchProp & IStateToProps,
  IState
> {
  public static defaultProps: Partial<IProps> = {
    sections: [],
    initialSection: 0,
    initialStep: 0,
  };

  public state: IState = {
    activeSectionIndex: this.props.initialSection,
    activeStepIndex: this.props.initialStep,
    showModal: false,
  };

  public render(): JSX.Element {
    const {
      animateStepChange,
      Header,
      logicState,
      Side,
      StepSide,
      sections,
    } = this.props;

    const {activeSectionIndex, activeStepIndex} = this.state;

    const activeSection = sections[activeSectionIndex];
    const activeSectionProgressCalculator = activeSection.progressCalculator;
    const activeStep = activeSection.steps[activeStepIndex] || 0;
    const activeStepComplete = activeStep.isComplete(logicState);
    const Contents = activeStep.contents;
    const {localizedJson} = this.props;
    const label = (localizedJson as any).misc.labels.notificationModal
      .resetFitting;

    let body: null | JSX.Element = null;
    if (Contents) {
      body = (
        <Contents
          isComplete={activeStepComplete}
          nextBtnText={activeStep.nextLabel || activeSection.defaultNextLabel}
          onNextClick={this.onNextClick}
          onPreviousClick={this.onPreviousClick}
          previousBtnText={
            activeStep.previousLabel || activeSection.defaultPreviousLabel
          }
          startingTabIndex={sections.length}
        />
      );
    }

    const viewClass = animateStepChange
      ? `${styles.viewContainer} ${styles.animate}`
      : styles.viewContainer;

    let side: JSX.Element | null = Side ? <Side /> : null;

    return (
      <>
        <div className={styles.header}>
          <Navigator
            Header={Header}
            logicState={logicState}
            sections={sections}
            activeSectionIndex={activeSectionIndex}
            onNavigationClick={this.onNavigationClick}
            toggleModal={this.toggleModal}
          />
          <SectionSteps
            activeSectionIndex={activeSectionIndex}
            activeSectionProgressCalculator={activeSectionProgressCalculator}
            activeStepIndex={activeStepIndex}
            logicState={logicState}
            steps={activeSection.steps}
            onNavigationClick={this.onNavigationClick}
            StepSide={StepSide}
            showStepSide={activeSection.showStepSide}
          />
        </div>
        <div className={styles.body}>
          <div className={styles.center}>
            <div className={viewClass}>
              <div className={styles.viewContainerChild}>{body}</div>
            </div>
            <div className={styles.sideContainer}>
              <div>{side}</div>
            </div>
          </div>
        </div>
        <NotificationModal
          actionOnOk={this.onResetFittingClick}
          cancelText={label.cancelText}
          okText={label.okText}
          handleClose={this.toggleModal}
          isOpen={this.state.showModal}
          title={label.title}
          message={label.message}
        />
      </>
    );
  }

  private onNavigationClick = (navObject): void => {
    const {sections, scrollToTopOnNavigate} = this.props;
    const activeStepToBeginAt = Number.isInteger(navObject.activeStepIndex)
      ? navObject.activeStepIndex
      : this.getActiveStepToBeginAt(navObject.activeSectionIndex);

    this.setState({
      ...this.state,
      activeSectionIndex: navObject.activeSectionIndex,
      activeStepIndex: activeStepToBeginAt,
    });

    if (
      sections[navObject.activeSectionIndex] &&
      sections[navObject.activeSectionIndex].steps[activeStepToBeginAt]
    ) {
      SegmentAnalytics.innerSectionVisit(
        sections[navObject.activeSectionIndex].name,
        sections[navObject.activeSectionIndex].steps[activeStepToBeginAt].name
      );
    }
  };

  private scrollToTopOnNavigate = (): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  private onNextClick = (): void => {
    const {sections, scrollToTopOnNavigate} = this.props;
    const {activeSectionIndex, activeStepIndex} = this.state;
    const activeSection = sections[activeSectionIndex];

    if (activeSection.onNextClick) {
      activeSection.onNextClick();
    }
    if (scrollToTopOnNavigate) {
      this.scrollToTopOnNavigate();
    }

    const onLastSection = activeSectionIndex === sections.length - 1;
    const onLastStep = activeStepIndex === activeSection.steps.length - 1;

    if (onLastSection && onLastStep) {
      this.setState({
        activeSectionIndex: 0,
        activeStepIndex: 0,
      });
      return;
    }

    if (onLastStep) {
      return this.onNavigationClick({
        activeSectionIndex: activeSectionIndex + 1,
        activeStepIndex: this.getActiveStepToBeginAt(activeSectionIndex + 1),
      });
    }

    this.setState({
      activeSectionIndex,
      activeStepIndex: activeStepIndex + 1,
    });
    if (
      sections[activeSectionIndex] &&
      sections[activeSectionIndex].steps[activeStepIndex + 1]
    ) {
      SegmentAnalytics.innerSectionVisit(
        sections[activeSectionIndex].name,
        sections[activeSectionIndex].steps[activeStepIndex + 1].name
      );
    }
  };

  private onPreviousClick = (): void => {
    const {sections, scrollToTopOnNavigate} = this.props;
    const {activeSectionIndex, activeStepIndex} = this.state;

    const activeSection = sections[activeSectionIndex];

    if (activeSection.onPreviousClick) {
      activeSection.onPreviousClick();
    }
    if (scrollToTopOnNavigate) {
      this.scrollToTopOnNavigate();
    }

    const onFirstSection = activeSectionIndex === 0;
    const onFirstStep = activeStepIndex === 0;

    if (onFirstSection && onFirstStep) {
      const lastSection = sections[sections.length - 1];
      this.setState({
        activeSectionIndex: sections.length - 1,
        activeStepIndex: lastSection.steps.length - 1,
      });
      return;
    }

    if (onFirstStep) {
      const nextSectionIndex =
        activeSectionIndex - 1 < 0 ? 0 : activeSectionIndex - 1;
      const priorSection = sections[nextSectionIndex];
      const nextStepIndex =
        priorSection.steps.length - 1 < 0 ? 0 : priorSection.steps.length - 1;

      return this.onNavigationClick({
        activeSectionIndex: nextSectionIndex,
        activeStepIndex: nextStepIndex,
      });
    }

    this.setState({
      activeSectionIndex,
      activeStepIndex: activeStepIndex - 1,
    });
    if (
      sections[activeSectionIndex] &&
      sections[activeSectionIndex].steps[activeStepIndex - 1]
    ) {
      SegmentAnalytics.innerSectionVisit(
        sections[activeSectionIndex].name,
        sections[activeSectionIndex].steps[activeStepIndex - 1].name
      );
    }
  };

  private getActiveStepToBeginAt = (sectionIndexNavigatedTo): number => {
    const {sections, logicState} = this.props;

    const newSection = sections[sectionIndexNavigatedTo];
    const stepToBeginAt = newSection.steps
      .map(step => step.isComplete(logicState))
      .findIndex(completed => completed === false);

    return stepToBeginAt === -1 ? newSection.steps.length - 1 : stepToBeginAt;
  };

  private onResetFittingClick = () => {
    this.setState({
      showModal: false,
    });
    this.props.dispatch(NflightAction.resetFitting()).then(() => {
      this.setState({
        activeSectionIndex: 0,
        activeStepIndex: 0,
      });
    });
  };

  private toggleModal = () => {
    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
    });
  };
}

export {SectionNavigator as Unconnected};
export default connect(mapStateToProps)(SectionNavigator);
