import styles from './PuttersRecommendation.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import get from 'lodash.get';
import IStore from '../../../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../../../SharedRedux/Models/IDispatchProp';
import NflightActions from '../../../../Redux/NflightAction';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {modelPreferenceValueSelector} from '../../../../Redux/Selectors/puttersSelectors';
import {strokeTypeTransformer} from '../PuttersRecommendationCard/PuttersRecommendationCard';

interface IProps {
  isComplete?: boolean;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IState {}
interface IStateToProps {
  labels: any;
  localizedJson: any;
  recommendations: any;
  selectedPutter: string | undefined;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  recommendations: state.nFlight.recommendations as any,
  localizedJson: state.nFlight.localizedJson,
  selectedPutter: modelPreferenceValueSelector(state),
});

class FairwayRecommendation extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public componentDidMount(): void {
    const {dispatch} = this.props;
    dispatch(NflightActions.isRecommendationPage(true));
  }

  public render(): JSX.Element {
    const {
      isComplete,
      labels,
      localizedJson,
      onPreviousClick,
      previousBtnText,
      recommendations,
      selectedPutter,
    } = this.props;

    const {recommendations: recommendationsJSON} = localizedJson;
    const {cardLabels} = recommendationsJSON;
    const tableStyles = 'recommendationTable';

    // supplying the default [] value to ensure iteration doesn't error out
    const putterSuggestion = (recommendations.putters || []).filter(
      suggestion => {
        return suggestion.id === selectedPutter;
      }
    );

    return (
      <div className={styles.wrapper}>
        <FormHeader
          isComplete={isComplete}
          header={labels.forms.puttersResults}
        />
        <div className={layout.vr_4} />

        <div className={styles.recommendationCards}>
          {putterSuggestion.map((rec, index) => {
            if (!rec) {
              return null;
            }

            const imgSrc = get(rec, 'pdfImage');
            const title = rec.title || (rec.model && rec.model.title);
            const key = rec.id + index.toString();
            return (
              <div key={key} className={styles.recommendationCard}>
                {imgSrc && (
                  <div className={styles.picture}>
                    <img src={imgSrc} alt={title} />
                  </div>
                )}
                <div className={styles.tabsSpacing} />
                <div className={styles.text}>
                  {title && (
                    <p className={`${styles.rec} ${styles.vr_half}`}>
                      <span className={styles.bold}>
                        {cardLabels.pingRecommends}
                      </span>
                    </p>
                  )}
                  {title && <p className={styles.heading}>{title}</p>}
                  <div className={styles.vr_1} />
                  <table className={tableStyles}>
                    <tbody>
                      <tr>
                        <td className={styles.tabularText}>
                          <span className={styles.datum}>
                            {cardLabels.length}:
                          </span>
                        </td>
                        <td>
                          <span className={styles.value}>{rec.Length}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.tabularText}>
                          <span className={styles.datum}>
                            {cardLabels.stroke}:
                          </span>
                        </td>
                        <td>
                          <span className={styles.value}>
                            {strokeTypeTransformer(rec.strokeType[0])}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.tabularText}>
                          <span className={styles.datum}>
                            {cardLabels.headWeight}:
                          </span>
                        </td>
                        <td>
                          <span className={styles.value}>{rec.headweight}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p className={styles.tabularText}>
                    <span className={styles.datum}>
                      {cardLabels.description}:
                    </span>
                  </p>
                  <p className={styles.tabularText}>
                    <span className={styles.value}>{rec.description}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={layout.vr_4} />
        <StepNavigation
          onPreviousClick={onPreviousClick}
          previousBtnText={previousBtnText}
          formHandlesNext={false}
        />
      </div>
    );
  }
}

export {FairwayRecommendation as Unconnected};
export default connect(mapStateToProps)(FairwayRecommendation);
