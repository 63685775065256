import get from 'lodash.get';
import IStore from '../../../SharedRedux/Models/IStore';
import {createSelector} from 'reselect';
import {
  PROFILE_ROOT,
  FORM_ROOT,
  generalInformationSelector,
  gripSizeSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
  wristToFloorInInchesSelector,
} from './sharedSelectors';
import {setLayoutSelector, setLayoutProgress} from './setLayoutSelectors';
import {ironShaftSelector} from './ironsSelectors';

const mostLoftedWedgeForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.mostLoftedWedge`);

const mostLoftedWedgeProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.mostLoftedWedge`);

const mostLoftedWedgeSelector = createSelector(
  mostLoftedWedgeForm,
  mostLoftedWedgeProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const turfConditionsForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.turfConditions`);

const turfConditionsProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.turfConditions`);

const turfConditionsSelector = createSelector(
  turfConditionsForm,
  turfConditionsProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const divotDepthForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.divotDepth`);

const divotDepthProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.divotDepth`);

export const divotDepthSelector = createSelector(
  divotDepthForm,
  divotDepthProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const fullSwingFormSelector = createSelector(
  mostLoftedWedgeSelector,
  turfConditionsSelector,
  divotDepthSelector,
  (lofted, turf, divot) => !!lofted && !!turf && !!divot
);

const sandConditionsForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.sandConditions`);

const sandConditionsProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.sandConditions`);

const sandConditionsSelector = createSelector(
  sandConditionsForm,
  sandConditionsProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const openFaceForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.openFace`);

const openFaceProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.openFace`);

const openFaceSelector = createSelector(
  openFaceForm,
  openFaceProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const bunkerAssistanceForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.bunkerAssistance`);

const bunkerAssistanceProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.bunkerAssistance`);

const bunkerAssistanceSelector = createSelector(
  bunkerAssistanceForm,
  bunkerAssistanceProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

const chipShotAssistanceForm = (state: IStore) =>
  get(state, `${FORM_ROOT}.wedgeProfile.useChipR`);

const chipShotAssistanceProfile = (state: IStore) =>
  get(state, `${PROFILE_ROOT}.wedgeProfile.useChipR`);

const chipShotAssistanceSelector = createSelector(
  chipShotAssistanceForm,
  chipShotAssistanceProfile,
  (formValue, profileValue) => !!formValue || !!profileValue
);

export const bunkersSelector = createSelector(
  openFaceSelector,
  sandConditionsSelector,
  bunkerAssistanceSelector,
  chipShotAssistanceSelector,
  (openFace, sand, bunker, chipShot) =>
    !!openFace && !!sand && !!bunker && !!chipShot
);

export const wedgesCompleteSelector = createSelector(
  generalInformationSelector,
  setLayoutSelector,
  fullSwingFormSelector,
  bunkersSelector,
  gripSizeSelector,
  wristToFloorInInchesSelector,
  ironShaftSelector,
  (general, setLayout, loft, bunkers, grip, wrist, ironShaft) =>
    general && setLayout && loft && bunkers && grip && wrist && ironShaft
);

const onlyWedgesProgressSelector = createSelector(
  mostLoftedWedgeSelector,
  turfConditionsSelector,
  divotDepthSelector,
  sandConditionsSelector,
  openFaceSelector,
  bunkerAssistanceSelector,
  wristToFloorInInchesSelector,
  ironShaftSelector,
  (
    mostLoftedWedgeSelector,
    turfConditionsSelector,
    divotDepthSelector,
    sandConditionsSelector,
    openFaceSelector,
    bunkerAssistanceSelector,
    wristToFloorInInchesSelector,
    ironShaftSelector
  ) =>
    [
      mostLoftedWedgeSelector,
      turfConditionsSelector,
      divotDepthSelector,
      sandConditionsSelector,
      openFaceSelector,
      bunkerAssistanceSelector,
      wristToFloorInInchesSelector,
      ironShaftSelector,
    ].reduce(
      (acc, selector) => {
        acc.questions += 1;
        if (!!selector) {
          acc.complete += 1;
        }
        return acc;
      },
      {complete: 0, questions: 0}
    )
);

export const wedgesProgressSelector = createSelector(
  onlyWedgesProgressSelector,
  generalInformationProgress,
  gripSizeSelectorProgress,
  setLayoutProgress,
  (
    onlyWedgesProgressSelector,
    generalInformationProgress,
    gripSizeSelectorProgress,
    setLayoutProgress
  ) => {
    return [
      onlyWedgesProgressSelector,
      generalInformationProgress,
      gripSizeSelectorProgress,
      setLayoutProgress,
    ].reduce(
      (acc, selector) => {
        acc.questions += selector.questions;
        acc.complete += selector.complete;
        return acc;
      },
      {complete: 0, questions: 0}
    );
  }
);
