import * as illustrations from '../Assets/Illustrations';
import * as icons from '../Assets/Icons';

const images = {
  ...illustrations,
  ...icons,
};

export default function preloadImages() {
  Object.keys(images).forEach(image => {
    const img = new Image();
    img.src = illustrations[image];
  });
}
