/* istanbul ignore file */
import {combineReducers, Reducer, ReducersMapObject} from 'redux';
import {reducer as formReducer} from 'redux-form';
import IStore from './Models/IStore';
import NFlightReducer from '../NflightWeb/Redux/NflightReducer';

export default (): Reducer<IStore> => {
  const reducerMap: ReducersMapObject = {
    form: formReducer,
    nFlight: NFlightReducer.reducer,
  };

  return combineReducers(reducerMap);
};
