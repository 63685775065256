import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import minimal from '../../../../../Assets/Illustrations/Putter_Type_1@2x-100.jpg';
import standard from '../../../../../Assets/Illustrations/Putter_Type_2@2x-100.jpg';
import prominent from '../../../../../Assets/Illustrations/Putter_Type_3@2x-100.jpg';

import blade from '../../../../../Assets/Illustrations/Putter_Type_1@2x-100.jpg';
import midMall from '../../../../../Assets/Illustrations/Putter_Type_2@2x-100.jpg';
import mallet from '../../../../../Assets/Illustrations/Putter_Type_3@2x-100.jpg';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  minimal,
  standard,
  prominent,
  blade,
  midMall,
  mallet,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.putter as any).questions,
  units: state.nFlight.localizedJson!.units,
});

let Feel: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
  } = props;

  const {alignment} = questions;

  const alignmentOptions = alignment.options.map(option => {
    return {
      ...option,
      imgSrc: imgMap[option.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="putterProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={alignment.header} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={alignment.label}
            name="alignmentAids"
            options={alignmentOptions}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={alignment.helpText}
            view="stretched"
            defaultHelpTextVisibility={alignment.defaultHelpTextVisibility}
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

Feel = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(Feel);

Feel = connect(mapStateToProps)(Feel);

export default Feel;
