import styles from './Navigator.module.scss';
import tabCompletedCheck from '../../../../Assets/Icons/checkmark-tab-completed.svg';

import * as React from 'react';
import {ISection} from '../../SectionNavigator';
import ResponsiveIcon from './Components/ResponsiveIcon';

interface IProps {
  Header: any;
  logicState: {
    [key: string]: any;
  };
  sections: ISection[];
  activeSectionIndex: number;
  onNavigationClick: (navigationUpdate: any) => void;
  toggleModal: () => void;
}

export default class Navigator extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    activeSectionIndex: 0,
    onNavigationClick: /* istanbul ignore next */ ({}) => ({}),
    sections: [],
  };

  public render(): JSX.Element {
    const {
      activeSectionIndex,
      Header,
      logicState,
      onNavigationClick,
      sections,
      toggleModal,
    } = this.props;

    return (
      <header className={styles.header}>
        <div className={styles.headerLogo}>
          {Header && <Header toggleModal={toggleModal} />}
        </div>
        <div className={styles.headerNav}>
          <nav className={styles.tabs} role="tablist">
            {sections.map((section, index) => {
              const isActive = activeSectionIndex === index;
              const isComplete = section.isComplete(logicState);

              const activeClass =
                activeSectionIndex === index ? styles.active : '';
              const ariaSelected = activeSectionIndex === index ? true : false;

              const navObject = {
                activeSectionIndex: index,
                activeStepIndex: null,
              };

              const icons = section.responsiveIcons;

              return (
                <button
                  type="button"
                  role="tab"
                  aria-selected={ariaSelected}
                  key={section.name}
                  onClick={() => onNavigationClick(navObject)}
                >
                  <p className={styles.srOnly}>{section.name}</p>
                  <div className={`${styles.desktopTab} ${activeClass}`}>
                    <p aria-hidden={true}>
                      {isComplete && <img src={tabCompletedCheck} alt="" />}
                      {section.name}
                    </p>
                  </div>
                  <div className={`${styles.responsiveTab} ${activeClass}`}>
                    <ResponsiveIcon
                      isComplete={isComplete}
                      isActive={isActive}
                      icons={icons}
                    />
                  </div>
                </button>
              );
            })}
          </nav>
        </div>
      </header>
    );
  }
}
