import INflightState from './Models/INflightState';
import IAction from '../../SharedRedux/Models/IAction';
import NflightAction, {NflightActionUnion} from './NflightAction';
import PingNationProfileModel from './Models/PingNationProfileModel';
import getBrowserLanguage from '../../util/getLanguage';
import WebfitSupportedUnitsEnum from './Models/WebfitSupportedUnits';
import {
  WebfitSupportedLanguages,
  WebfitSupportedLanguagesEnum,
} from './Models/WebfitSupportedLanguages';
import RecommendationModel from './Models/RecommendationModel';
import AuthB2CModel from './Models/AuthB2CModel';
import EnvironmentDataModel from './Models/EnvironmentDataModel';
import ProfileModel from './Models/ProfileModel';

const language = ((): WebfitSupportedLanguagesEnum => {
  const browserLanguage = getBrowserLanguage();

  if (WebfitSupportedLanguages[browserLanguage]) {
    return WebfitSupportedLanguages[browserLanguage];
  }
  return WebfitSupportedLanguagesEnum.US;
})();

const unitType = ((
  language: WebfitSupportedLanguagesEnum
): WebfitSupportedUnitsEnum => {
  if (language === WebfitSupportedLanguagesEnum.US) {
    return WebfitSupportedUnitsEnum.Imperial;
  } else {
    return WebfitSupportedUnitsEnum.Metric;
  }
})(language);

const authB2C = ((): AuthB2CModel => {
  return new AuthB2CModel({});
})();

const pingNationProfile = ((): PingNationProfileModel => {
  return new PingNationProfileModel({});
})();

const environmentData = ((): EnvironmentDataModel => {
  return new EnvironmentDataModel({});
})();

const profile = ((): ProfileModel => {
  return new ProfileModel({});
})();

export default class NflightReducer {
  public static readonly initialState: INflightState = {
    acceptedPrivacyPolicy: false,
    isAuthError: false,
    isRequestProfileError: false,
    isRequestingAuth: false,
    isRequestingPutters: false,
    isRequestingPuttersError: false,
    isRequestingRecommendations: false,
    isRecommendationPage: false,
    isSaveProfileError: false,
    isRequestingProfileUpdate: false,
    language,
    localizedJson: null,
    countriesJson: null,
    previousRequestPayload: {},
    authB2C,
    environmentData,
    pingNationProfile,
    profile,
    recommendations: new RecommendationModel({}),
    unitType,
  };

  public static reducer(
    state: INflightState = NflightReducer.initialState,
    action: IAction<NflightActionUnion>
  ): INflightState {
    switch (action.type) {
      case NflightAction.LOAD_DATA_FINISHED:
        return {
          ...state,
          localizedJson: action.payload as any,
        };
      case NflightAction.LOAD_COUNTRIES_FINISHED:
        return {
          ...state,
          countriesJson: action.payload as any,
        };
      case NflightAction.ACCEPT_PRIVACY_POLICY:
        return {
          ...state,
          acceptedPrivacyPolicy: action.payload as boolean,
        };
      case NflightAction.UNACCEPT_PRIVACY_POLICY:
        return {
          ...state,
          acceptedPrivacyPolicy: action.payload as boolean,
          isAuthError: false,
          isRequestProfileError: false,
          isRequestingAuth: false,
          isRequestingRecommendations: false,
          isSaveProfileError: false,
        };
      case NflightAction.PREFER_UNIT_TYPE:
        return {
          ...state,
          unitType: (action.payload as any)
            .unitType as WebfitSupportedUnitsEnum,
          profile: (action.payload as any).profile as ProfileModel,
        };

      /* RECOMMENDATIONS */
      case NflightAction.SET_PREVIOUS_REQUEST_PAYLOAD:
        return {
          ...state,
          previousRequestPayload: action.payload as any,
        };
      case NflightAction.REQUESTING_RECOMMENDATION:
        return {
          ...state,
          isRequestingRecommendations: true,
        };
      case NflightAction.REQUEST_RECOMMENDATION_FINISHED:
        return {
          ...state,
          recommendations: action.payload as any,
          isRequestingRecommendations: false,
        };
      case NflightAction.REQUEST_RECOMMENDATION_ERROR:
        return {
          ...state,
          isRequestingRecommendations: false,
        };

      /* AUTH */
      case NflightAction.REQUESTING_ENVIRONMENT:
        return {
          ...state,
          environmentData: action.payload as EnvironmentDataModel,
        };

      /* LOGIN + AUTH */
      case NflightAction.REQUESTING_AUTH:
        return {
          ...state,
          isRequestingAuth: true,
        };
      case NflightAction.REQUEST_AUTH_FINISHED:
        return {
          ...state,
          isRequestingAuth: false,
          authB2C: action.payload as AuthB2CModel,
        };
      case NflightAction.REQUEST_AUTH_ERROR:
        return {
          ...state,
          isRequestingAuth: false,
          isAuthError: true,
        };

      case NflightAction.REQUESTING_LOGOUT:
        return {
          ...state,
          isRequestingAuth: true,
        };
      case NflightAction.REQUEST_LOGOUT_FINISHED:
        return {
          ...state,
          acceptedPrivacyPolicy: false,
          isRequestingAuth: false,
          isAuthError: false,
          isRequestProfileError: false,
          isRequestingRecommendations: false,
          isSaveProfileError: false,
          authB2C: new AuthB2CModel({}),
          pingNationProfile: new PingNationProfileModel({}),
          profile: new ProfileModel({}),
          recommendations: new RecommendationModel({}),
          previousRequestPayload: {},
        };
      case NflightAction.REQUEST_LOGOUT_ERROR:
        return {
          ...state,
          isRequestingAuth: false,
          isAuthError: true,
        };

      case NflightAction.REQUEST_PROFILE:
        return {
          ...state,
          isRequestingAuth: true,
        };
      case NflightAction.REQUEST_PROFILE_FINISHED:
        return {
          ...state,
          isRequestingAuth: false,
          profile: action.payload as ProfileModel,
        };
      case NflightAction.REQUEST_USER_PROFILE_FINISHED:
        return {
          ...state,
          isRequestingAuth: false,
          pingNationProfile: action.payload as PingNationProfileModel,
        };
      case NflightAction.REQUEST_PROFILE_ERROR:
        return {
          ...state,
          isRequestingAuth: false,
          isAuthError: true,
        };
      case NflightAction.LOGOUT:
        return {
          ...state,
          authB2C: new AuthB2CModel({}),
          pingNationProfile: new PingNationProfileModel({}),
          profile: new ProfileModel({}),
          recommendations: new RecommendationModel({}),
          previousRequestPayload: {},
        };
      case NflightAction.CLEAR_AUTH_ERROR:
        return {
          ...state,
          isAuthError: action.payload as any,
        };

      /* PROFILES */
      case NflightAction.REQUEST_SAVE_PROFILE:
        return {
          ...state,
          isRequestingProfileUpdate: true,
        };
      case NflightAction.REQUEST_SAVE_PROFILE_FINISHED:
        return {
          ...state,
          isSaveProfileError: false,
          profile: action.payload as ProfileModel,
          isRequestingProfileUpdate: false,
        };
      case NflightAction.REQUEST_SAVE_PROFILE_ERROR:
        return {
          ...state,
          isSaveProfileError: true,
          isRequestingProfileUpdate: false,
        };
      case NflightAction.REQUEST_RESET_FITTING:
        return {
          ...state,
          isRequestingRecommendations: true,
          profile: new ProfileModel({}),
          recommendations: new RecommendationModel({}),
          previousRequestPayload: {},
        };
      case NflightAction.REQUEST_SET_IS_RECOMMENDATION_PAGE:
        return {
          ...state,
          isRecommendationPage: action.payload as boolean,
        };

      default:
        return state;
    }
  }
}
