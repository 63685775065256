import styles from './Stroke.module.scss';
import layout from '../../../../../SharedComponents/Styles/layout.module.scss';
import slightarc from '../../../../../Assets/Illustrations/StrokeType_Slight@2x-100.jpg';
import straight from '../../../../../Assets/Illustrations/StrokeType_Straight@2x-100.jpg';
import strongarc from '../../../../../Assets/Illustrations/StrokeType_Strong@2x-100.jpg';
import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import PictorialRadioInput from '../../../../../SharedComponents/Forms/PictorialRadio';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

const imgMap = {
  slightarc,
  straight,
  strongarc,
};

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  labels: any;
  questions: any;
  units: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
  questions: (state.nFlight.localizedJson!.putter as any).questions,
  units: state.nFlight.localizedJson!.units,
});

let Stroke: any = (props: IProps & IStateToProps) => {
  const {
    handleSubmit,
    isComplete,
    labels,
    onNextClick,
    onPreviousClick,
    questions,
    nextBtnText,
    previousBtnText,
  } = props;

  const {strokePath, weight, performance} = questions;
  const strokePathOptions = strokePath.options.map(opt => {
    return {
      ...opt,
      imgSrc: imgMap[opt.value],
    };
  });

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="putterProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={labels.forms.stroke} />
          <div className={layout.vr_4} />
          <PictorialRadioInput
            label={strokePath.label}
            name="strokePath"
            defaultValue={strokePath.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={strokePath.helpText}
            options={strokePathOptions}
            hideBottomMargin={true}
            view="stretched"
          />
          <div className={`${styles.helpTexts} ${layout.vr_6}`}>
            {strokePathOptions.map(opt => (
              <p key={opt.value}>{opt.description}</p>
            ))}
          </div>
          <TextRadio
            label={weight.label}
            name="weight"
            options={weight.options}
          />
          <TextRadio
            label={performance.label}
            name="typicalMiss"
            defaultValue={performance.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={performance.helpText}
            options={performance.options}
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

Stroke = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(Stroke);

Stroke = connect(mapStateToProps)(Stroke);

export default Stroke;
