import styles from './MyBagRecommendation.module.scss';
import loader from '../../../../../Assets/Loaders/loader.gif';
import pin from '../../../../../Assets/Icons/Icon-Map-Pin.svg';
import print from '../../../../../Assets/Icons/Icon-Printer.svg';
import email from '../../../../../Assets/Icons/Icon-Email.svg';
import arrowLeft from '../../../../../Assets/Icons/Arrow Left.svg';
import caretDown from '../../../../../Assets/Icons/Caret Down.svg';
import webfitLogo from '../../../../../Assets/Logos/WebFit Logo Dark.svg';

import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-scroll';
import IStore from '../../../../../SharedRedux/Models/IStore';
import RecommendationCards from '../RecommendationCards';
import PuttersRecommendationCard from '../../Putters/PuttersRecommendationCard';
import MyBagRecommendationPDF from './MyBagRecommendationPDF';
import SetLayout from '../SetLayout';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';
import {metersToInches, inchesToFeet} from '../../../../../util/unitConversion';
import {capitalizeString} from '../../../../../util/stringFormatter';
import {modelPreferenceValueSelector} from '../../../../Redux/Selectors/puttersSelectors';

import RecommendationCardsPDF from './MyBagRecommendationPDF/RecommendationCardsPDF';
import NotificationModal from '../../../../../SharedComponents/NotificationModal';
import PuttersRecommendationCardPDF from '../../Putters/PuttersRecommendationCardPDF';
import SegmentAnalytics from '../../../../../util/segmentAnalytics';

interface IProps {}
interface IState {
  recommendationInView: string;
  oldRecommendationInView: string;
  viewingRecommendationResponsive: boolean;
  showPDFModal: boolean;
  isPdfCheckboxDisabled: boolean;
}
interface IStateToProps {
  isRequestingRecommendations: boolean;
  localizedJson: any;
  fullProfile: any;
  pingNationProfile: any;
  recommendations: any;
  selectedPutter: string | undefined;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  isRequestingRecommendations: state.nFlight.isRequestingRecommendations,
  localizedJson: state.nFlight.localizedJson,
  fullProfile: fullProfileSelector(state),
  pingNationProfile: state.nFlight.pingNationProfile,
  recommendations: state.nFlight.recommendations,
  selectedPutter: modelPreferenceValueSelector(state),
});

class MyBagRecommendation extends React.Component<
  IProps & IStateToProps,
  IState
> {
  state = {
    recommendationInView: 'all',
    oldRecommendationInView: 'all',
    viewingRecommendationResponsive: false,
    showPDFModal: false,
    isPdfCheckboxDisabled: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.closeResponsiveOnSizeChange, true);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.closeResponsiveOnSizeChange,
      false
    );
  }

  public closeResponsiveOnSizeChange = () => {
    const {viewingRecommendationResponsive} = this.state;

    if (viewingRecommendationResponsive && window.innerWidth >= 768) {
      this.closeResponsive();
    }
  };

  public changeRecommendationView = (e) => {
    this.setState({
      recommendationInView: e.target.value,
      oldRecommendationInView: this.state.recommendationInView,
    });
  };

  public navigateToRecommendation = (recommendation: string) => {
    this.setState({
      recommendationInView: recommendation,
      oldRecommendationInView: this.state.recommendationInView,
    });
  };

  public viewResponsive = () => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    this.setState({viewingRecommendationResponsive: true});
  };

  public clickFindRetailer = () => {
    const {localizedJson} = this.props;
    const {recommendations: recommendationsJSON} = localizedJson;
    window.open(recommendationsJSON.findRetailer.url, '_blank');
    SegmentAnalytics.findARetailer(recommendationsJSON.findRetailer.url);
  };

  public printRecommendations = () => {
    SegmentAnalytics.printRecommendations();
    window.print();
  };

  public closeResponsive = () => {
    // change to document.body
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
    this.setState({viewingRecommendationResponsive: false});
  };

  public downloadPDFForEmail = async (fullName) => {
    const pdfDownloadBtn = document.getElementById('pdfDownloadLink');
    if (pdfDownloadBtn) {
      pdfDownloadBtn.click();
      window.open(`mailto:?subject=Ping WebFit Results for ${fullName}`);
      this.setState({showPDFModal: false});
    }
  };

  public getCardSelectElement() {
    const {localizedJson} = this.props;
    const {recommendationInView} = this.state;
    const {recommendations: recommendationsJSON} = localizedJson;

    return (
      <div className="input-field input-field_select">
        <select
          className={`input-field_select-select ${styles.input}`}
          onChange={this.changeRecommendationView}
          value={recommendationInView}
          id="recommendationsClubSelector"
        >
          {recommendationsJSON.dropdown.options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </select>
        <div className={styles.inputFieldSelectArrow}>
          <img src={caretDown} alt="" />
        </div>
      </div>
    );
  }

  public getCards(isForPDF) {
    const {localizedJson, recommendations, selectedPutter} = this.props;
    const {recommendations: recommendationsJSON} = localizedJson;
    const {recommendationInView, oldRecommendationInView} = this.state;

    const viewDriverCards =
      recommendationInView === 'all' || recommendationInView === 'drivers';
    const viewIronsCards =
      recommendationInView === 'all' || recommendationInView === 'irons';
    const viewFairwaysCards =
      recommendationInView === 'all' || recommendationInView === 'fairways';
    const viewHybridsCards =
      recommendationInView === 'all' || recommendationInView === 'hybrids';
    const viewCrossoversCards =
      recommendationInView === 'all' || recommendationInView === 'crossovers';
    const viewWedgesCards =
      recommendationInView === 'all' || recommendationInView === 'wedges';
    const viewChiprCards =
      recommendationInView === 'all' || recommendationInView === 'chipr';
    const viewPuttersCards =
      recommendationInView === 'all' || recommendationInView === 'putters';

    const forceVisualUpdate =
      recommendationInView !== 'all' &&
      recommendationInView !== oldRecommendationInView;

    const hoselTransformer = (hoselPosition: string): string => {
      if (hoselPosition === 'plusplus') {
        return recommendationsJSON.cardValues.hoselPosition.bigPlus;
      }
      if (hoselPosition === 'minusminus') {
        return recommendationsJSON.cardValues.hoselPosition.bigMinus;
      }
      return recommendationsJSON.cardValues.hoselPosition.neutral;
    };

    const weightTransformer = (weightPosition: string): string => {
      if (weightPosition === 'neutral') {
        return recommendationsJSON.cardValues.weightPosition.neutral;
      }
      if (weightPosition === 'fade') {
        return recommendationsJSON.cardValues.weightPosition.fade;
      }
      if (weightPosition === 'D' || weightPosition === 'draw') {
        return recommendationsJSON.cardValues.weightPosition.draw;
      }
      return '';
    };

    const wedgeLoftsTransformer = (lofts): string => {
      return lofts.map((loft) => loft.name.abbreviated).join(', ');
    };

    const driverLengthTransformer = (lengthInMeters): string => {
      return `${metersToInches(lengthInMeters).toFixed(2)}"`;
    };

    const clubLengthTransformer = (lengthDifferential): string => {
      if (lengthDifferential === 0) {
        return recommendationsJSON.cardValues.clubLength.zero;
      }
      const sign = lengthDifferential > 0 ? '+' : '';
      return `${sign}${lengthDifferential}"`;
    };

    const colorCodeTransformer = (colorCode): string => {
      const colorCodeMap = {
        2: recommendationsJSON.cardValues.colorCodes['2'],
        3: recommendationsJSON.cardValues.colorCodes['3'],
        4: recommendationsJSON.cardValues.colorCodes['4'],
        5: recommendationsJSON.cardValues.colorCodes['5'],
        6: recommendationsJSON.cardValues.colorCodes['6'],
        7: recommendationsJSON.cardValues.colorCodes['7'],
        8: recommendationsJSON.cardValues.colorCodes['8'],
        9: recommendationsJSON.cardValues.colorCodes['9'],
        10: recommendationsJSON.cardValues.colorCodes['10'],
        11: recommendationsJSON.cardValues.colorCodes['11'],
      };
      return colorCodeMap[colorCode] || colorCode.toString();
    };

    const clubsTransformer = (clubs): string => {
      return clubs.map((club) => club.club.toUpperCase()).join(', ');
    };

    const lengthTransformer = (length: string) => `${length}"`;

    return (
      <div id="allCards">
        {viewDriverCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="drivers"
            singularclubType="driver"
            tabularKeys={[
              {labelPath: 'cardLabels.loft', dataPath: 'loft.name.full'},
              {
                labelPath: 'cardLabels.weightPosition',
                dataPath: 'weightPosition',
                transformer: weightTransformer,
              },
              {
                labelPath: 'cardLabels.hoselPosition',
                dataPath: 'hoselPosition',
                transformer: hoselTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: driverLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewDriverCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="drivers"
            singularclubType="driver"
            tabularKeys={[
              {labelPath: 'cardLabels.loft', dataPath: 'loft.name.full'},
              {
                labelPath: 'cardLabels.weightPosition',
                dataPath: 'weightPosition',
                transformer: weightTransformer,
              },
              {
                labelPath: 'cardLabels.hoselPosition',
                dataPath: 'hoselPosition',
                transformer: hoselTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: driverLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewFairwaysCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="fairways"
            singularclubType="fairway"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.hoselPosition',
                dataPath: 'hoselPosition',
                transformer: hoselTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewFairwaysCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="fairways"
            singularclubType="fairway"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.hoselPosition',
                dataPath: 'hoselPosition',
                transformer: hoselTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewHybridsCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="hybrids"
            singularclubType="hybrid"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewHybridsCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="hybrids"
            singularclubType="hybrid"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewCrossoversCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="crossovers"
            singularclubType="crossover"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewCrossoversCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="crossovers"
            singularclubType="crossover"
            tabularKeys={[
              {
                labelPath: 'cardLabels.clubs',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewIronsCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="irons"
            singularclubType="iron"
            tabularKeys={[
              {
                labelPath: 'cardLabels.setMakeup',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewIronsCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="irons"
            singularclubType="iron"
            tabularKeys={[
              {
                labelPath: 'cardLabels.setMakeup',
                dataPath: 'clubs',
                transformer: clubsTransformer,
              },
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewWedgesCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="wedges"
            singularclubType="wedge"
            tabularKeys={[
              {
                labelPath: 'cardLabels.lofts',
                dataPath: 'lofts',
                transformer: wedgeLoftsTransformer,
              },
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewWedgesCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="wedges"
            singularclubType="wedge"
            tabularKeys={[
              {
                labelPath: 'cardLabels.lofts',
                dataPath: 'lofts',
                transformer: wedgeLoftsTransformer,
              },
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewChiprCards && !isForPDF && (
          <RecommendationCards
            forceUpdate={forceVisualUpdate}
            clubType="chipr"
            singularclubType="chipr"
            tabularKeys={[
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
          />
        )}
        {viewChiprCards && isForPDF && (
          <RecommendationCardsPDF
            clubType="chipr"
            singularclubType="chipr"
            tabularKeys={[
              {
                labelPath: 'cardLabels.colorCode',
                dataPath: 'colorCode',
                transformer: colorCodeTransformer,
              },
              {
                labelPath: 'cardLabels.length',
                dataPath: 'lengthInMeters',
                transformer: clubLengthTransformer,
              },
              {labelPath: 'cardLabels.shaft', dataPath: 'shaft.title'},
              {labelPath: 'cardLabels.flex', dataPath: 'shaft.flex.flex'},
              {labelPath: 'cardLabels.grip', dataPath: 'grip.title'},
              {
                labelPath: 'cardLabels.gripColorCode',
                dataPath: 'grip.gripColorCode',
              },
            ]}
            recommendations={recommendations}
            localizedJson={localizedJson}
          />
        )}
        {viewPuttersCards && !isForPDF && (
          <PuttersRecommendationCard forceUpdate={forceVisualUpdate} />
        )}
        {viewPuttersCards && isForPDF && (
          <PuttersRecommendationCardPDF
            forceUpdate={forceVisualUpdate}
            recommendations={recommendations}
            selectedPutter={selectedPutter}
            localizedJson={localizedJson}
          />
        )}
      </div>
    );
  }

  public getPrintOnlyView() {
    const {fullProfile, pingNationProfile, recommendations, localizedJson} =
      this.props;
    const {myBag} = localizedJson;

    const {wedgeProfile, golferProfile, ironProfile, driverProfile} =
      fullProfile;

    return (
      <div className={styles.printOnly}>
        <img className={styles.vr_2} src={webfitLogo} alt="webfit logo" />
        <div>{`WebFit Results for ${pingNationProfile.FullName}`}</div>
        <div className={styles.fittingInfoColumns}>
          <div className={styles.block}>
            <div className={styles.vr_1} />
            {pingNationProfile && (
              <div>
                <h3 className={styles.title}>Player Information</h3>
                <p>Full Name: {pingNationProfile.FullName}</p>
                <p>Webfit Email: {pingNationProfile.Email}</p>
                <p>
                  Last Webfit Fitting:{' '}
                  {(pingNationProfile.webFitLastUpdate || '').split('T')[0]}
                </p>
              </div>
            )}
            {golferProfile && (
              <div>
                <h3 className={styles.title}>Player Profile</h3>
                <p>Height: {inchesToFeet(golferProfile.heightInInches)}</p>
                <p>Wrist-to-Floor: {golferProfile.wristToFloorInInches}"</p>
                <p>Handicap/Avg Score: {golferProfile.handicap}</p>
                <p>
                  Golf Dexterity: {capitalizeString(golferProfile.dexterity)}
                </p>
              </div>
            )}
          </div>
          <div className={styles.block}>
            <div className={styles.vr_1} />
            {driverProfile && (
              <div>
                <h3 className={styles.title}>Metal Woods</h3>
                <div>
                  <p>
                    Avg Driver Carry Distance:{' '}
                    {driverProfile.driverDistanceInYards} yds
                  </p>
                  <p>
                    Predominant Shot Shape:{' '}
                    {capitalizeString(driverProfile.currentFlight)}
                  </p>
                  <p>
                    Desired Shot Shape:{' '}
                    {capitalizeString(driverProfile.desiredFlight)}
                  </p>
                </div>
              </div>
            )}
            {ironProfile && (
              <div>
                <h3 className={styles.title}>Irons</h3>
                {golferProfile && (
                  <p>
                    7-Iron Carry Distance:{' '}
                    {golferProfile.sevenIronCarryDistanceInYards} yds
                  </p>
                )}
                <p>
                  Shaft Preference:{' '}
                  {capitalizeString(ironProfile.shaftMaterialPreference)}
                </p>
                {recommendations.irons && recommendations.irons[0] && (
                  <>
                    <p>
                      Static Color Code: {recommendations.irons[0].colorCode}
                    </p>
                    <p>
                      Static Length: {recommendations.irons[0].lengthInMeters}"
                    </p>
                  </>
                )}
              </div>
            )}
            {wedgeProfile && (
              <div>
                <h3 className={styles.title}>Wedges</h3>
                <p>
                  Typical Turf Conditions:{' '}
                  {capitalizeString(wedgeProfile.turfConditions)}
                </p>
              </div>
            )}
          </div>
        </div>
        <h3 className={styles.title}>{myBag.recommendedSetLabel}</h3>
      </div>
    );
  }

  public getResponsiveView() {
    const {localizedJson} = this.props;

    const {viewingRecommendationResponsive} = this.state;

    const {myBag, recommendations: recommendationsJSON, misc} = localizedJson;

    return (
      <div className={styles.responsiveWrapper}>
        {!viewingRecommendationResponsive && (
          <button
            className={styles.responsiveOpener}
            onClick={this.viewResponsive}
          >
            <span>{misc.labels.viewRecommendation}</span>
          </button>
        )}
        {viewingRecommendationResponsive && (
          <div id="responsiveMenu" className={styles.responsiveWrapperBody}>
            <div className={styles.titleBlock}>
              <button
                className={`${styles.link} ${styles.backLinkResponsive}`}
                onClick={this.closeResponsive}
              >
                <img src={arrowLeft} alt="" />
                <span>{misc.labels.back}</span>
              </button>
              <div>
                <a
                  className={styles.link}
                  target="_blank"
                  onClick={this.clickFindRetailer}
                >
                  <img src={pin} className={styles.mapIcon} alt="" />
                  <span>{recommendationsJSON.findRetailer.buttonLabel}</span>
                </a>
                <button
                  className={styles.link}
                  onClick={this.printRecommendations}
                >
                  <img src={print} className={styles.printIcon} alt="" />
                  <span>{recommendationsJSON.print.buttonLabel}</span>
                </button>
              </div>
            </div>
            <div className={styles.vr_1} />
            <Link
              onClick={() => this.navigateToRecommendation('all')}
              containerId="responsiveMenu"
              to="allCards"
              smooth={true}
              offset={-115}
              duration={500}
            >
              <button className={`${styles.link} ${styles.printLink}`}>
                <h3 className={styles.title}>{myBag.label}</h3>
              </button>
            </Link>
            <div className={styles.vr_1} />
            <SetLayout
              onClubClick={this.navigateToRecommendation}
              containerId="responsiveMenu"
            />
            <div className={styles.vr_2} />
            {this.getCardSelectElement()}
            <div className={styles.vr_2} />
            {this.getCards(false)}
            <div>
              <h3 className={styles.title}>
                {recommendationsJSON.fittingProcess.label}
              </h3>
              <div className={styles.vr_1} />
              <p className={styles.disclaimerText}>{misc.intro.copy[0]}</p>
              <div className={styles.vr_1} />
            </div>
          </div>
        )}
      </div>
    );
  }

  public getDesktopView() {
    const {
      isRequestingRecommendations,
      localizedJson,
      recommendations,
      fullProfile,
      pingNationProfile,
    } = this.props;

    const {driverProfile, ironProfile, wedgeProfile} = fullProfile;

    const {myBag, recommendations: recommendationsJSON, misc} = localizedJson;

    const showProcessCopy = () => {
      const recom = this.props.recommendations;

      if (recom.drivers === undefined) {
        return true;
      }

      if (
        Object.keys(recom.crossovers).length === 0 &&
        Object.keys(recom.drivers).length === 0 &&
        Object.keys(recom.fairways).length === 0 &&
        Object.keys(recom.hybrids).length === 0 &&
        Object.keys(recom.irons).length === 0 &&
        Object.keys(recom.putters).length === 0 &&
        Object.keys(recom.wedges).length === 0
      ) {
        return true;
      }
      return false;
    };

    const showLayout = () => {
      const recom = this.props.recommendations;
      if (
        recom.setLayout !== undefined &&
        Object.keys(recom.setLayout).length !== 0
      ) {
        return true;
      }
      return false;
    };
    const handleCheckboxClick = () => {
      this.setState({isPdfCheckboxDisabled: !this.state.isPdfCheckboxDisabled});
    };
    return (
      <div className={styles.desktopWrapper}>
        <div className={styles.block}>
          <div className={styles.loaderContainer}>
            {isRequestingRecommendations && (
              <span className={styles.loader}>
                <img src={loader} alt="" /> {myBag.updating}
              </span>
            )}
          </div>
          <Link
            onClick={() => this.navigateToRecommendation('all')}
            to="allCards"
            smooth={true}
            offset={-115}
            duration={500}
          >
            <button className={`${styles.link} ${styles.printLink}`}>
              <h3 className={styles.title}>{myBag.label}</h3>
            </button>
          </Link>
          <div className={styles.vr_2} />
          {showLayout() && (
            <SetLayout onClubClick={this.navigateToRecommendation} />
          )}
        </div>
        <div className={styles.block}>
          <div className={styles.titleBlock}>
            <h3 className={`${styles.title} ${styles.desktopLabel}`}>
              {recommendationsJSON.label}
            </h3>
            <h3 className={`${styles.title} ${styles.printLabel}`}>
              {recommendationsJSON.resultsLabel}
            </h3>
            <div>
              <a
                className={styles.link}
                target="_blank"
                onClick={this.clickFindRetailer}
              >
                <img src={pin} className={styles.mapIcon} alt="" />
                <span>{recommendationsJSON.findRetailer.buttonLabel}</span>
              </a>
              <button
                className={styles.link}
                onClick={this.printRecommendations}
              >
                <img src={print} className={styles.printIcon} alt="" />
                <span>{recommendationsJSON.print.buttonLabel}</span>
              </button>
              <button
                className={styles.link}
                onClick={() => {
                  this.setState({showPDFModal: true});
                }}
                disabled={!driverProfile && !ironProfile && !wedgeProfile}
              >
                <img src={email} className={styles.emailIcon} alt="" />
                <span>{recommendationsJSON.email.buttonLabel}</span>
              </button>
              <button id={'pdfDownloadLink'} className={styles.pdfLink} />
              <NotificationModal
                actionOnOk={() => {
                  this.downloadPDFForEmail(pingNationProfile.FullName);
                }}
                cancelText={myBag.emailPDF.modal.cancelBtn}
                okText={myBag.emailPDF.modal.okBtn}
                handleClose={() => this.setState({showPDFModal: false})}
                handleCheckboxClick={handleCheckboxClick}
                checkboxText={myBag.emailPDF.modal.checkboxText}
                isOpen={this.state.showPDFModal}
                isOkButtonDisabled={this.state.isPdfCheckboxDisabled}
                title={myBag.emailPDF.modal.title}
                message={myBag.emailPDF.modal.message}
              />
            </div>
          </div>
          <div className={styles.vr_2} />
          {this.getCardSelectElement()}
          <div className={styles.vr_2} />
          {this.getCards(false)}
        </div>
        {showProcessCopy() === true ? (
          <div className={styles.block}>
            <h3 className={styles.title}>
              {recommendationsJSON.fittingProcess.label}
            </h3>
            <div className={styles.vr_2} />
            <small className={styles.disclaimerText}>
              {misc.intro.copy[0]}
            </small>
            <div className={styles.vr_1} />
          </div>
        ) : null}
      </div>
    );
  }

  public getPDFView() {
    const {fullProfile, pingNationProfile, recommendations, localizedJson} =
      this.props;
    const {wedgeProfile, golferProfile, ironProfile, driverProfile} =
      fullProfile;
    const {recommendationInView} = this.state;
    const viewDriverCards =
      recommendationInView === 'all' || recommendationInView === 'drivers';
    const viewIronsCards =
      recommendationInView === 'all' || recommendationInView === 'irons';
    const viewFairwaysCards =
      recommendationInView === 'all' || recommendationInView === 'fairways';
    const viewHybridsCards =
      recommendationInView === 'all' || recommendationInView === 'hybrids';
    const viewCrossoversCards =
      recommendationInView === 'all' || recommendationInView === 'crossovers';
    const viewWedgesCards =
      recommendationInView === 'all' || recommendationInView === 'wedges';
    const viewChiprCards =
      recommendationInView === 'all' || recommendationInView === 'chipr';
    const viewPuttersCards =
      recommendationInView === 'all' || recommendationInView === 'putters';
    return (
      <MyBagRecommendationPDF
        pingNationProfile={pingNationProfile}
        driverProfile={driverProfile}
        ironProfile={ironProfile}
        wedgeProfile={wedgeProfile}
        golferProfile={golferProfile}
        recommendations={recommendations}
        viewCardsObj={{
          viewDriverCards,
          viewIronsCards,
          viewFairwaysCards,
          viewHybridsCards,
          viewCrossoversCards,
          viewWedgesCards,
          viewChiprCards,
          viewPuttersCards,
        }}
        getCards={this.getCards(true)}
        localizedJson={localizedJson}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.getPrintOnlyView()}
        {this.getResponsiveView()}
        {this.getDesktopView()}
      </>
    );
  }
}

export {MyBagRecommendation as Unconnected};
export default connect(mapStateToProps)(MyBagRecommendation);
