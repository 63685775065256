import styles from './NotificationModal.module.scss';

import * as React from 'react';
interface IProps {
  isOpen: boolean;
  title: string;
  message?: string;
  okText?: string;
  cancelText?: string;
  checkboxText?: string;
  isOkButtonDisabled?: boolean;
  actionOnOk: () => void;
  handleClose: () => void;
  handleCheckboxClick?: () => void;
}
export default class NotificationModal extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      actionOnOk,
      handleCheckboxClick,
      handleClose,
      cancelText,
      checkboxText,
      isOkButtonDisabled,
      okText,
      title,
      message,
    } = this.props;
    const showHideClassName = this.props.isOpen
      ? `${styles.modal} ${styles.displayBlock}`
      : `${styles.modal} ${styles.displayNone}`;
    return (
      <div className={showHideClassName}>
        <section className={styles.modalMain}>
          <div>
            <h4 className="hdg hdg_4">{title}</h4>
            <h2>{message}</h2>
          </div>
          <div className={styles.vr_3} />
          {checkboxText && (
            <div className={styles.checkbox}>
              <input
                id="checkbox"
                type="checkbox"
                onChange={handleCheckboxClick}
              />
              <label htmlFor="checkbox">{checkboxText}</label>
            </div>
          )}
          <span className={styles.btnGroup}>
            <button className="btn" onClick={() => handleClose()}>
              {cancelText ? cancelText : 'Cancel'}
            </button>
            <button
              className="btn btn_primary btn-primary"
              onClick={() => actionOnOk()}
              disabled={isOkButtonDisabled}
            >
              {okText ? okText : 'Ok'}
            </button>
          </span>
        </section>
      </div>
    );
  }
}
