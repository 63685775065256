import * as React from 'react';
import pdfCardStyles from '../../MyBag/MyBagRecommendation/MyBagRecommendationPDF/RecommendationCardsPDF/RecommendationCardsPDFStyles';

const strokeMap = {
  slightarc: 'Slight Arc',
  strongarc: 'Strong Arc',
};
export const strokeTypeTransformer = (stroke: string) =>
  strokeMap[stroke] || stroke;

const PuttersRecommendationCardPDF = props => {
  const {forceUpdate, localizedJson, recommendations, selectedPutter} = props;

  const putterSuggestion =
    recommendations.putters &&
    recommendations.putters.filter(suggestion => {
      return suggestion.id === selectedPutter;
    });

  if (!putterSuggestion || putterSuggestion.length === 0) {
    return null;
  }

  const {recommendations: recommendationsJSON} = localizedJson;
  const {cardLabels} = recommendationsJSON;

  return (
    null
    /**
    <View id={props.id}>
      {putterSuggestion.map(rec => {
        const imgSrc =
          rec.pdfImage ||
          (putterSuggestion.model && putterSuggestion.model.pdfImage);
        const title = rec.title || (rec.model && rec.model.title);
        return (
          <View
            key={`putter-card-${title}`}
            wrap={false}
            style={pdfCardStyles.recommendationCardWrapper}
          >
            <View style={pdfCardStyles.recommendationCard}>
              {imgSrc && (
                <View style={pdfCardStyles.clubImageWrapper}>
                  <Image src={imgSrc} style={pdfCardStyles.clubImage} />
                </View>
              )}
              <View style={{width: '80%'}}>
                {title && (
                  <View style={pdfCardStyles.cardHeader}>
                    // NOTE: The spaces between span tags are important here
                    <Text style={pdfCardStyles.topClubTypeLabel}>
                      {cardLabels.top}{' '}
                    </Text>
                    <Text style={pdfCardStyles.clubType}>Putter </Text>
                    <Text>{cardLabels.recommendation}</Text>
                  </View>
                )}
                {title && <Text style={pdfCardStyles.clubName}>{title}</Text>}
                <View style={pdfCardStyles.clubDetailsWrapper}>
                  <View style={pdfCardStyles.tableEntry}>
                    <Text style={pdfCardStyles.textLabel}>
                      {cardLabels.length}:
                    </Text>
                    <Text style={pdfCardStyles.textValue}>{rec.Length}</Text>
                  </View>
                  <View style={pdfCardStyles.tableEntry}>
                    <Text style={pdfCardStyles.textLabel}>
                      {cardLabels.stroke}:
                    </Text>
                    <Text style={pdfCardStyles.textValue}>
                      {strokeTypeTransformer(rec.strokeType[0])}
                    </Text>
                  </View>
                  <View style={pdfCardStyles.tableEntry}>
                    <Text style={pdfCardStyles.textLabel}>
                      {cardLabels.headWeight}:
                    </Text>
                    <Text style={pdfCardStyles.textValue}>
                      {rec.headweight}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        );
      })}
    </View>
    */
  );
};

export default PuttersRecommendationCardPDF;
