import layout from '../../../../../SharedComponents/Styles/layout.module.scss';

import * as React from 'react';
import {connect} from 'react-redux';
import {reduxForm, FormSection} from 'redux-form';
import IStore from '../../../../../SharedRedux/Models/IStore';
import TextRadio from '../../../../../SharedComponents/Forms/TextRadio';
import StepNavigation from '../../Shared/StepNavigation';
import FormHeader from '../../Shared/FormHeader';
import {fullProfileSelector} from '../../../../Redux/Selectors/sharedSelectors';

interface IProps {
  handleSubmit: (arg: any) => void;
  isComplete?: boolean;
  pristine: any;
  submitting: any;
  nextBtnText: string;
  onNextClick: (arg: any) => void;
  onPreviousClick: () => void;
  previousBtnText: string;
}
interface IStateToProps {
  initialValues: any;
  fairways: any;
  labels: any;
}

const mapStateToProps = (state: IStore, ownProps: IProps): IStateToProps => ({
  fairways: state.nFlight.localizedJson!.fairways,
  initialValues: fullProfileSelector(state),
  labels: (state.nFlight.localizedJson!.misc as any).labels,
});

let NumberOfFairwayWoods: any = (props: IProps & IStateToProps) => {
  const {
    fairways,
    labels,
    handleSubmit,
    isComplete,
    onPreviousClick,
    onNextClick,
    previousBtnText,
    nextBtnText,
  } = props;

  const {numberOfFairways, hybridType} = fairways.questions;

  return (
    <form onSubmit={handleSubmit(onNextClick) as any}>
      <FormSection name="fairwayProfile">
        <fieldset>
          <FormHeader isComplete={isComplete} header={fairways.label} />
          <div className={layout.vr_4} />
          <TextRadio
            label={numberOfFairways.label}
            name="numberOfClubsOffFairways"
            defaultValue={numberOfFairways.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText="If you don't know, we'll assume 2."
            options={numberOfFairways.options}
          />
          <TextRadio
            label={hybridType.label}
            name="hybridType"
            defaultValue={hybridType.defaultValue}
            defaultValueLabel={labels.dontKnow}
            defaultValueTooltipText={hybridType.helpText}
            options={hybridType.options}
          />
          <StepNavigation
            nextBtnText={nextBtnText}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousBtnText={previousBtnText}
            formHandlesNext={true}
          />
        </fieldset>
      </FormSection>
    </form>
  );
};

NumberOfFairwayWoods = reduxForm({
  form: 'profile',
  destroyOnUnmount: false, // The default value is true, but this application primarily uses false.
})(NumberOfFairwayWoods);

NumberOfFairwayWoods = connect(mapStateToProps)(NumberOfFairwayWoods);

export default NumberOfFairwayWoods;
