import * as React from 'react';
import get from 'lodash.get';

import pdfCardStyles from './RecommendationCardsPDFStyles';

const RecommendationCardsPDF = props => {
  const {
    clubType,
    singularclubType,
    id,
    localizedJson,
    recommendations,
    tabularKeys,
  } = props;

  if (
    !recommendations ||
    !recommendations[clubType] ||
    !recommendations[clubType][0]
  ) {
    return null;
  }

  const {recommendations: recommendationsJSON} = localizedJson;
  let recs = recommendations[clubType];
  return (
    /*
    <View id={id}>
      {recs.map((rec, index) => {
        if (!rec) {
          return null;
        }

        const imgSrc = rec.pdfImage || (rec.model && rec.model.pdfImage);
        const title = rec.title || (rec.model && rec.model.title);
        const isPrimary = index === 0;

        return (
          <View
            wrap={false}
            style={pdfCardStyles.recommendationCardWrapper}
            key={`recommendation-card-${index}`}
          >
            <View style={pdfCardStyles.recommendationCard}>
              {imgSrc && (
                <View style={pdfCardStyles.clubImageWrapper}>
                  <Image src={imgSrc} style={pdfCardStyles.clubImage} />
                </View>
              )}
              <View style={{width: '80%'}}>
                {title && (
                  // NOTE: The spaces between span tags are important here
                  <View style={pdfCardStyles.cardHeader}>
                    <Text style={pdfCardStyles.topClubTypeLabel}>
                      {isPrimary && recommendationsJSON.cardLabels.top}
                      {!isPrimary &&
                        recommendationsJSON.cardLabels.alternate}{' '}
                    </Text>
                    <Text style={pdfCardStyles.clubType}>
                      {clubType.charAt(0).toUpperCase()}
                      {clubType === 'chipr'
                        ? clubType
                            .slice(1, clubType.length - 1)
                            .concat(
                              clubType.slice(clubType.length - 1).toUpperCase()
                            )
                        : singularclubType === 'driver' ||
                          singularclubType === 'putter'
                        ? singularclubType.slice(1)
                        : clubType.slice(1)}{' '}
                    </Text>
                    <Text>{recommendationsJSON.cardLabels.recommendation}</Text>
                  </View>
                )}
                {title && <Text style={pdfCardStyles.clubName}>{title}</Text>}
                <View style={pdfCardStyles.clubDetailsWrapper}>
                  {tabularKeys.map((datum, index) => {
                    const label = get(
                      recommendationsJSON,
                      datum.labelPath,
                      undefined
                    );
                    let value = get(rec, datum.dataPath, undefined);
                    if (datum.transformer) {
                      value = datum.transformer(value);
                    }
                    if (value && typeof value === 'number') {
                      value = Math.floor(value);
                    }
                    if (value && typeof value !== 'string') {
                      value = value.toString();
                    }
                    const hasLabel = !!label;
                    const hasData = !!value;
                    if (hasLabel && hasData) {
                      return (
                        <View
                          key={`table-data-${index}`}
                          style={pdfCardStyles.tableEntry}
                        >
                          <Text style={pdfCardStyles.textLabel}>{label}</Text>
                          <Text style={pdfCardStyles.textValue}>{value}</Text>
                        </View>
                      );
                    }
                    return null;
                  })}
                </View>
              </View>
            </View>
          </View>
        );
      })}
    </View>
    */ null
  );
};

export default RecommendationCardsPDF;
