import * as React from 'react';
import * as msal from '@azure/msal-browser';
import {connect} from 'react-redux';
import IStore from '../../../SharedRedux/Models/IStore';
import IDispatchProp from '../../../SharedRedux/Models/IDispatchProp';
import NFlightActions from '../../Redux/NflightAction';
import LocalizedJsonResponseModel from '../../Redux/Models/LocalizedJsonResponseModel';
import EnvironmentDataModel from '../../Redux/Models/EnvironmentDataModel';
import PingNationProfileModel from '../../Redux/Models/PingNationProfileModel';

interface IProps {}
interface IState {
  msalInstance: msal.PublicClientApplication | null;
  msalScopes: msal.RedirectRequest;
  authData: any;
}
interface IStateToProps {
  environmentData: EnvironmentDataModel;
  pingNationProfile: PingNationProfileModel;
  localizedJson: LocalizedJsonResponseModel | null;
  authData: any;
}

const mapStateToProps = (state: IStore): IStateToProps => ({
  environmentData: state.nFlight.environmentData,
  localizedJson: state.nFlight.localizedJson,
  pingNationProfile: state.nFlight.pingNationProfile,
  authData: state.nFlight.environmentData.authData,
});

class LegalDisclaimer extends React.Component<
  IProps & IStateToProps & IDispatchProp,
  IState
> {
  public state: IState = {
    msalInstance: null,
    msalScopes: {
      scopes: [],
    },
    authData: {
      authority: '',
      clientId: '',
      knownAuthorities: [],
      status: '',
      type: '',
      loginPath: '',
      logoutUrl: '',
    },
  };

  async componentDidMount() {
    const {localizedJson, environmentData} = this.props;
    const {authData} = environmentData;
    this.setState(
      prevState => {
        return {
          ...prevState,
          authData,
        };
      },
      () => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('modal-open');

        if (localizedJson != null) {
          this.initalizeB2C();
        }
      }
    );
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal-open');
  }

  public initalizeB2C = (): void => {
    let {authData, msalInstance} = this.state;
    const msalConfig = {
      auth: {
        clientId: authData.clientId ? authData.clientId : '',
        authority: authData.authority ? authData.authority : '',
        knownAuthorities: authData.knownAuthorities
          ? authData.knownAuthorities
          : [],
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            if (process.env.NODE_ENV !== 'production') {
              switch (level) {
                case msal.LogLevel.Error:
                  console.error(message);
                  return;
                case msal.LogLevel.Info:
                  console.info(message);
                  return;
                case msal.LogLevel.Verbose:
                  console.debug(message);
                  return;
                case msal.LogLevel.Warning:
                  console.warn(message);
                  return;
                default:
                  console.log(message);
              }
            }
          },
        },
      },
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);
    this.setState(
      prevState => {
        return {
          ...prevState,
          msalInstance,
        };
      },
      () => {
        if (authData.loginPath.toLowerCase() === 'modal') {
          this.loginPopupB2C();
        } else {
          this.loginRedirectB2C();
        }
      }
    );
  };

  public loginRedirectB2C = async (): Promise<void> => {
    let {msalInstance, msalScopes} = this.state;
    const {dispatch, localizedJson} = this.props;
    const authText = (localizedJson as any).auth;

    if (msalInstance != null) {
      await msalInstance
        .handleRedirectPromise()
        .then(tokenResponse => {
          if (tokenResponse !== null && msalInstance != null) {
            dispatch(NFlightActions.requestLogin(tokenResponse));
          } else {
            try {
              if (msalInstance != null) {
                msalInstance.loginRedirect(msalScopes);
              }
            } catch (err) {
              // handle error
              console.log(err);
              alert(authText.errors.b2cModuleLoadFail);
              this.logoutB2C();
            }
          }
        })
        .catch(error => {
          // handle error, either in the library or coming back from the server
          console.log(error);
          alert(authText.errors.b2cModuleLoadFail);
          this.logoutB2C();
        });
    }
  };

  public loginPopupB2C = async (): Promise<void> => {
    let {msalInstance, msalScopes} = this.state;
    const {dispatch, localizedJson} = this.props;
    const authText = (localizedJson as any).auth;

    if (msalInstance != null) {
      await msalInstance
        .loginPopup(msalScopes)
        .then(loginResponse => {
          if (msalInstance != null && loginResponse) {
            dispatch(NFlightActions.requestLogin(loginResponse));
          }
        })
        .catch(error => {
          //login failure
          console.log(error);
          alert(authText.errors.b2cModuleLoadFail);
          this.logoutB2C();
        });
    }
  };

  public logoutB2C = async (): Promise<void> => {
    let {authData, msalInstance} = this.state;
    let {pingNationProfile} = this.props;

    if (msalInstance != null) {
      const msalAccount = msalInstance.getAccountByUsername(
        pingNationProfile.Email
      );

      await msalInstance
        .logoutPopup({
          account: msalAccount ? msalAccount : undefined,
        })
        .then(e => {
          console.log('Successfully logged out user', e);
          this.redirectToPrivacy();
        })
        .catch(e => {
          console.log('Error with logging users out', e);
          window.location.href =
            authData.logoutUrl != ''
              ? `${authData.logoutUrl}${window.location.origin}`
              : window.location.origin;
        });
    } else {
      this.redirectToPrivacy();
    }
  };

  public redirectToPrivacy = (): void => {
    const {dispatch} = this.props;
    dispatch(NFlightActions.unacceptPrivacyPolicy());
  };

  public render(): JSX.Element | null {
    return <></>;
  }
}

export {LegalDisclaimer as Unconnected};
export default connect(mapStateToProps)(LegalDisclaimer);
