import styles from './FormHeader.module.scss';
import largeCheck from '../../../../../Assets/Icons/Checkmark Large.svg';

import * as React from 'react';

interface IProps {
  header: string;
  isComplete?: boolean;
}

export const FormHeader = (props: IProps): JSX.Element => {
  const {header, isComplete} = props;

  return (
    <legend>
      <h2 className={styles.wrapper}>
        {isComplete && (
          <img
            src={largeCheck}
            alt="Checkmark indicating this section is complete."
          />
        )}
        {header}
      </h2>
    </legend>
  );
};

export default FormHeader;
