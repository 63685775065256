import React from 'react';
import NFlightWeb from './NflightWeb';
import Segment from './util/segment';

class App extends React.Component {
  public componentDidMount(): void {
    Segment.init();
  }

  render() {
    return <NFlightWeb />;
  }
}

export default App;
